<template>
  <!-- 库存-入库记录 -->
  <div class="module-wrapper stk-in-detail-box" :style="{height:height}">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu(moduleName)"
      box-class="stk-in-detail-box"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
      @dbclick="e => {$emit('dbl-click', e.rowData)}"
    ></znl-table>
    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      edit-type="edit"
      height="500px"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      edit-type="edit"
      height="500px"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false"
    ></stk-in-edit>
  </div>
</template>
<script>
const StkInEdit = () =>
  import("@c_modules/Stk/StkInEdit" /* webpackChunkName: "pages/stk" */);
const StkOutEdit = () =>
  import("@c_modules/Stk/StkOutEdit" /* webpackChunkName: "pages/stk" */);
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler, itemsHandle } from "@scripts/methods/common";

const CONFIG = {
  configURL: "StkInRecord/GetConfig",
  saveConfigURL: 'StkInRecord/SaveConfig',
  resetConfigURL: 'StkInRecord/ResetConfig',
  StkInDetailsConfigURL: "StkInDetails/GetConfig",
  searchURL: "StkInDetails/Search"
};

export default {
  name: "StkInDetails",
  mixins: [getCommonDataMixin, erpTableSetHandler, itemsHandle], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit
  },
  data() {
    return {
      stkOutEditVisible: false,
      stkInEditVisible: false,
      columns: [],
      itemSource: [],
      filterFields: {},
      resourceList: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isOperate: {
      type: Boolean,
      default: true
    },
    moduleName: {
      type: String,
      default: "PastStkIn"
    },
    height: {
      type: String,
      default: "100%"
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function
    }
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      this.loading = true;
      if (!config) {
        let cfgUrl = CONFIG.configURL;
        if (this.moduleName === "StkInDetails") {
          cfgUrl = CONFIG.StkInDetailsConfigURL;
        }
        config = await this.isSaveStorage(this.moduleName, cfgUrl);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      if (this.isOperate) {
        let tempConfig = [];
        if (this.hasRes("OnStkOut")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "出",
            btnTxt: "出",
            visible: true,
            isDeal: false,
            click: row => {
              this.onStkOutadd([row]);
            }
          });
        }
        if (this.hasRes("OnStkIn")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "入",
            btnTxt: "入",
            visible: true,
            isDeal: false,
            click: row => {
              this.onStkInadd(row);
            }
          });
        }
        columns = tempConfig.concat(columns);
      }

      if (!this.IsMainAccount) {
        this.settingColumns(columns);
      }
      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      if (this.initData) {
        await this.onBindData();
      }

      this.loading = false;
      this.$emit("page-loading", false);
    },
    // 列的初始化
    settingColumns(columns) {
      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");
      // 看采购价
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      // 不看替代型号，没有这个权限隐藏咧
      let noSeeReplaceModel = this.getSpecialResourceByCode("NoSeeReplaceModel");
      let hidePriceCols = ",Amount,Price,PriceNoTax,";

      _.remove(columns, item => {
        if (noSeeSupplier && item.BindField === "SupplierName") {
          return true;
        }
        if (item.BindField === "ReplaceModel" && noSeeReplaceModel) {
          return true;
        }
        // 看入库记录价格
        if (!this.hasRes("ViewPrice") && hidePriceCols.indexOf(item.BindField) > 0) {
          return true;
        }
      });
    },

    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let filterFields = {};
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[
            key.substring(0, 1).toUpperCase() + key.substring(1)
          ] = value;
        });
      }
      filterFields.IsAnalyzer = this.IsAnalyzer;
      let response = await this.$post(
        this.$options.config.searchURL,
        _.extend(
          {
            FieldWhereString: JSON.stringify(this.filterFields),
            PageIndex: pageIndex,
            PageSize: this.pageSize
          },
          filterFields
        )
      );
      // 处理时间
      this.removeTime(response.ResultList, ["ReceiveTime"]);
      this.pageIndex = pageIndex;
      this.itemSource = response.ResultList;
      this.totalCount = response.TotalCount;
      setTimeout(() => {
        this.loading = false;
      }, 800);
      this.$emit("page-loading", false);
      this.$emit("set-total-count", this.totalCount);
    },

    async onHeadSearch(param) {
      this.filterFields.MakeYear = param.MakeYear || "";
      this.filterFields.Model = param.Model || "";
      this.filterFields.Brand = param.Brand || "";
      this.filterFields.Packaging = param.Packaging || "";
      this.filterFields.IsZero = param.IsZero || false;
      this.filterFields.CustomerName = param.CustomerName || "";
      const { Keywords, KeywordsField } = param;
      if (Keywords && KeywordsField) {
        this.filterFields[KeywordsField] = Keywords;
      }
      if (param.CCompanyID) {
        this.filterFields.CCompanyID = param.CCompanyID;
      }
      if (param.SupplierName) {
        this.filterFields.SupplierName = param.SupplierName;
      }
      await this.onBindData(1);
    },

    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("stkInRecord");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      );
    },

    async onResetSetting() {
      localStorage.removeItem("stkInRecord");
      await this.$post(this.$options.config.resetConfigURL);
    },

    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("stkInRecord");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },

    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },

    hasRes(code) {
      return _.some(this.resourceList, page => page === code);
    },

    hasSpecial(code) {
      return _.some(
        this.selectOptionsSpecialResource,
        page => page.Code === code
      );
    },

    onStkOutadd(rows) {
      // 销售出库传值方法
      let order = { stockLine: rows };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function() {
        this.stkOutEditVisible = true; // 显示编辑页面
      });
    },

    onStkInadd(rows) {
      // 入库
      let order = { StkInRecord: [rows] };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function() {
        this.stkInEditVisible = true; // 显示编辑页面
      });
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger('StkInRecord', e.col);
    },
    onDragDone(col) {
      this.setTableColWidth('StkInRecord', col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig('StkInRecord', cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig('StkInRecord');
    }
  }
};
</script>

<style lang="scss">
</style>


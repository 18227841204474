<template>
  <znl-dialog
    v-loading="dialogLoading"
    :close-on-click-modal="true"
    :is-footer-show="false"
    :title="title"
    :visible="visible"
    class="m-customer-label-set"
    element-loading-text="数据加载中,请稍后..."
    height="270px"
    width="600px"
    @close="onClose"
  >
    <div>
      <!-- 贴标签 -->
      <el-row>
        <el-autocomplete
          v-model="labelName"
          :clearable="true"
          :fetch-suggestions="querySearch"
          class="auto-complete-input"
          placeholder="请输入标签内容"
          popper-class="label-auto-complete-list"
          value-key="LabelName"
          @select="handleSelect"
        ></el-autocomplete>

        <znl-button class="ml5" style-type="main" @click="onSave">
          <i class="iconfont icon-add_btn_ic"></i>
          <span>贴上</span>
        </znl-button>
        <span class="tips">(点击标签可删除)</span>

        <div class="fr">
          <el-checkbox
            v-model="setTop"
            size="small"
            @change="onUpdateLevelType('top')"
          >
            置顶
          </el-checkbox>
          <el-checkbox
            v-model="setBlacklist"
            size="small"
            @change="onUpdateLevelType('blacklist')"
          >
            黑名单
          </el-checkbox>
        </div>
      </el-row>

      <el-row class="cust-tag-list">
        <span
          v-for="item in labels"
          :key="item.LID"
          class="el-tag el-tag--mini"
          @click="onDelete(item)"
        >
          {{ item.LabelName }}
        </span>
      </el-row>
    </div>
  </znl-dialog>
</template>

<script>
import { mixin } from "@scripts/methods/common";

const CONFIG = {
  getLabelsUrl: "SupplierLabel/Get",
  saveUrl: "SupplierLabel/SaveLabel",
  setLevelTypeUrl: "CrmCustomerLabel/SetLevelType",
  deleteUrl: "SupplierLabel/DelLabel",
};

export default {
  name: "customer_label_set",
  mixins: [mixin],
  data() {
    return {
      dialogLoading: false,
      labels: [],
      autoCompleteLabels: [],
      initCompleted: false,
      initializing: false,
      setTop: false,
      setBlacklist: false,
      isChange: false,
      labelName: "",
      labelChange: {
        addArray: [],
        removeArray: [],
      },
    };
  },

  props: {
    companyId: String,
    companyName: String,
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  methods: {
    // 获取标签
    async getLabels() {
      const params = {
        AssignTo: this.companyName,
        AssignToID: this.companyId,
      };
      let data = await this.$post(CONFIG.getLabelsUrl, params);
      if (data && data.LabelList.length > 0) {
        this.labels = _.filter(data.LabelList, (item) => item.LevelType === 1);
        let index = _.findIndex(data.LabelList, (item) => item.LevelType === 2);
        this.setTop = index > -1;
        index = _.findIndex(data.LabelList, (item) => item.LevelType === 3);
        this.setBlacklist = index > -1;
      } else {
        this.labels = [];
        this.setTop = false;
        this.setBlacklist = false;
      }
    },

    // 保存标签
    async onSave() {
      if (!this.hasValue(this.labelName)) {
        this.$message({ message: "请输入标签名称", type: "error" });
        return false;
      }

      let param = {
        AssignToID: this.companyId,
        AssignTo: this.companyName,
        LabelName: this.labelName,
        LevelType: 1,
      };

      this.$post(CONFIG.saveUrl, param, (data, logic) => {
        if (logic.code === 200 && data) {
          this.$message({ message: "保存成功", type: "success" });
          this.calcLabelCount(this.labelName, true);
          this.labelName = "";
          this.isChange = true;
          this.getLabels();
        } else {
          let msg = logic.msg || "保存失败";
          this.$message({ message: msg, type: "error" });
        }
      });
    },

    // 设置黑名单、置顶
    async onUpdateLevelType(type) {
      let param = {
        AssignToID: this.companyId,
      };
      if (type === "top") {
        param.IsDelete = !this.setTop;
        param.LevelType = 2;
      } else if (type === "blacklist") {
        param.IsDelete = !this.setBlacklist;
        param.LevelType = 3;
      } else {
        this.$message({ message: "类型有误", type: "error" });
        return false;
      }

      this.$post(CONFIG.setLevelTypeUrl, param, (data, logic) => {
        if (logic.code === 200 && data) {
          let msg = "";
          if (type === "top") {
            msg = param.IsDelete ? "已取消置顶" : "置顶成功";
            this.calcLabelCount("置顶", !param.IsDelete);
          } else {
            msg = param.IsDelete ? "已从黑名单移除" : "加入黑名单成功";
            this.calcLabelCount("黑名单", !param.IsDelete);
          }
          this.isChange = true;
          this.$message({ message: msg, type: "success" });
        } else {
          let msg = logic.msg || "保存失败";
          this.$message({ message: msg, type: "error" });
        }
      });
    },

    // 删除标签
    async onDelete(item) {
      let param = {
        LID: item.LID,
        CompanyID: item.CompanyID,
        LabelName: item.LabelName,
        AssignTo: item.AssignTo,
        LabelType: 1, //供应商标签
      };
      this.$post(CONFIG.deleteUrl, param, (data, logic) => {
        if (logic.code === 200 && data) {
          this.isChange = true;
          this.$message({ message: "删除成功", type: "success" });
          let index = _.findIndex(
            this.labels,
            (m) => m.LabelName === item.LabelName
          );
          if (index > -1) {
            this.labels.splice(index, 1);
          }
          this.calcLabelCount(item.LabelName, false);
        } else {
          let msg = logic.msg || "删除失败";
          this.$message({ message: msg, type: "error" });
        }
      });
    },

    // 新增、修改过的label统计
    calcLabelCount(labelName, isAdd) {
      if (isAdd) {
        this.labelChange.addArray.push(labelName);
      } else {
        this.labelChange.removeArray.push(labelName);
      }
    },

    // 自动完成
    querySearch(queryString, callback) {
      let autoCompleteLabels = this.autoCompleteLabels;
      let results = queryString
        ? autoCompleteLabels.filter(this.createFilter(queryString))
        : autoCompleteLabels;
      // 调用 callback 返回建议列表的数据
      if (this.initCompleted) {
        callback(results);
      } else {
        // 初始化数据
        this.getAllLabels(callback);
      }
    },
    createFilter(queryString) {
      return (item) => {
        queryString = queryString || "";
        return (
          item.LabelName.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    async getAllLabels(callback) {
      if (this.initializing) {
        return false;
      }
      this.initializing = true;
      let data = await this.$post("SupplierLabel/SearchLabels", {});
      this.initCompleted = true;
      this.initializing = false;
      this.autoCompleteLabels = data;
      callback && callback(this.autoCompleteLabels);
    },

    onClose() {
      this.$emit("close", this.labels, this.isChange, this.labelChange);
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.isChange = false;
        this.labelChange.addArray = [];
        this.labelChange.removeArray = [];
        this.getLabels();
      } else {
        this.initCompleted = false;
        this.autoCompleteLabels = [];
      }
    },
  },

  computed: {
    title() {
      return "给供应商贴标签 - " + this.companyName;
    },
  },

  async mounted() {},
};
</script>

<style lang="scss">
.m-customer-label-set {
  .tips {
    font-size: 10px;
    color: #ccc;
  }

  .fr {
    float: right;
  }

  .el-tag--mini {
    margin-right: 8px;
  }

  .el-tag {
    cursor: pointer;
  }

  .auto-complete-input {
    width: 200px;

    .el-input__inner {
      height: 24px;
      border: 1px solid #b3cfeb;
      line-height: 24px;
    }
  }

  .el-autocomplete-suggestion__list .el-autocomplete-suggestion li {
    font-size: 12px !important;
    color: red;
  }
}

.label-auto-complete-list {
  width: 200px;

  .el-autocomplete-suggestion__list li {
    font-size: 12px;
  }

  .el-input__suffix {
    margin-top: 2px !important;
  }
}

.cust-tag-list {
  overflow: auto;
  height: 170px;
  border: 1px solid #f1ecec;
  border-radius: 3px;
  padding-left: 2px;
}
</style>



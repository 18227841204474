<template>
  <div class="container">
    <znl-dialog
      :loading-text="elementLoadingText"
      :loading.sync="loading"
      :visible.sync="visible"
      class="m-enquiry-box"
      height="480px"
      title="询价记录"
      width="570px"
      @close="()=>{$emit('close')}"
    >
      <div slot="znl-dialog">
        <!-- 供应商-->
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <znl-input
                v-model="editItem.SupplierName"
                :border="true"
                :clearable="true"
                :is-must-fill="true"
                form-type="input"
                layout="left"
                placeholder="输入供应商简称或拼音字母,并按回车键查询"
                size="mini"
                title="供应商："
                title-width="70px"
                type="text"
                width="100%"
                @keyup.enter.native="SearchCompany">
                <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
              </znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple m-el-auto-complete">
              <label class="m-el-auto-complete_title">电话:</label>
              <el-autocomplete
                v-model="editItem.Telephone"
                :fetch-suggestions="telSearchAsync"
                clearable
                placeholder="可模糊搜索"
                size="mini"
                @select="handleTelSelect"
              ></el-autocomplete>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple m-el-auto-complete">
              <el-row :gutter="5">
                <el-col :span="18">
                  <label class="m-el-auto-complete_title">QQ号:</label>
                  <el-autocomplete
                    v-model="editItem.QQ"
                    :fetch-suggestions="QQSearchAsync"
                    class="m-el-auto-complete_style"
                    clearable
                    placeholder="可模糊搜索"
                    size="mini"
                    @select="handleQQSelect"
                  ></el-autocomplete>
                </el-col>
                <el-col :span="6">
                  <znl-button :width="60" style-type="mac" @click="onOpenQQ">打开QQ</znl-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <!-- 报价，税点-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <el-row :gutter="5">
                <el-col :span="17">
                  <znl-input
                    v-model="editItem.Price"
                    :border="true"
                    :clearable="true"
                    :disabled="false"
                    :is-must-fill="true"
                    form-type="input"
                    layout="left"
                    title="*报价："
                    title-width="70px" type="text"
                    width="100%"
                    x
                  ></znl-input>
                </el-col>
                <!--下拉列表-->
                <el-col :span="7">
                  <znl-input
                    v-model="editItem.CurrencyCode"
                    :border="true"
                    :clearable="true"
                    :disabled="false"
                    :is-must-fill="true"
                    :select-options="currencyList"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    type="text"
                    width="100%"
                  ></znl-input>
                </el-col>
                <!--下拉列表end-->
              </el-row>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.TaxRate"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                :select-options="selectOptionsTaxRate"
                form-type="select"
                layout="left"
                title="税点："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 需求量，报价未税-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.Qty"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="true"
                form-type="input"
                layout="left"
                title="*需求量："
                title-width="70px"
                type="number"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="taxPrice"
                :border="true"
                :clearable="true"
                :disabled="true"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                placeholder=""
                title="报价(未税)："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 型号 品质-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.Model"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="true"
                form-type="input"
                layout="left"
                title="*型号："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.Quality"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="true"
                :select-options="qualityList"
                form-type="select"
                layout="left"
                title="*品质："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 品牌，封装-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.Brand"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="品牌："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                v-model="editItem.Packaging"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="封装："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 年份，包装-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.MakeYear"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="年份："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                v-model="editItem.MPQ"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="包装："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 库存量，货期-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.InvQty"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="库存量："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                v-model="editItem.DeliveryDate"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                :select-options="deliveryDateList"
                form-type="select"
                layout="left"
                title="货期："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 来源，联系人-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.DataFrom"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="来源："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                v-model="editItem.Contact"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                form-type="input"
                layout="left"
                title="联系人："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

        <!-- 打字-->
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.MarkingCode"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                layout="left"
                title="打字："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>

        </el-row>

        <!-- 备注-->
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <znl-input
                v-model="editItem.Remark"
                :border="true"
                :clearable="true"
                :disabled="false"
                :is-must-fill="false"
                :rows="1"
                form-type="input"
                layout="left"
                style="margin-top:5px"
                title="备注："
                title-width="70px"
                type="text"
                width="100%"
              ></znl-input>
            </div>
          </el-col>
        </el-row>

      </div>
      <el-row slot="footer" align="middle" class="dialog-footer" justify="end" type="flex">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-checkbox id="continueSave" v-model="IsContinueSave" style="margin-right:5px">保存后继续操作</el-checkbox>
          <znl-button :height="33" :width="60" style="margin-right:5px;margin-left: 5px" @click="onClear">清空
          </znl-button>
          <znl-button :height="33" :width="60" style-type="main" @click="Save">保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <d-company-contact-select
      v-if="companySelectShow"
      :CompanyName="editItem.SupplierName"
      :searchModel="SelectSearchModel"
      :visible="companySelectShow"
      companyType="2,3"
      title="选择供应商名称"
      @close="()=>{companySelectShow=false}"
      @confirm="companyChecked"
      @update:visible="val=>{companySelectShow=val}"
    ></d-company-contact-select>
  </div>
</template>
<script>
import {mixin as getCommonDataMixin} from '~assets/scripts/methods/getCommonData'
import {DatePicker} from 'element-ui'
import dCompanyContactSelect from '../Crm/DCompanyContactSelect'


const CONFIG = {
  save: 'Inquire/Add' // 保存
}

export default {
  name: 'EnquiryView',
  mixins: [getCommonDataMixin],
  components: {
    elDatePicker: DatePicker,
    dCompanyContactSelect
  },
  computed: {
    taxPrice: function () {
      const price = this.editItem.Price
      const taxRate = this.editItem.TaxRate
      let newTaxPrice = ''
      if (price && taxRate) {
        newTaxPrice = (Number(price) / (1 + Number(taxRate) / 100)).toFixed(2)
      }
      return newTaxPrice
    }
  },
  data() {
    return {
      companySelectShow: false, // 是否显示客户选择框
      InTypes: '', // 类型
      IsContinueSave: false, // 是否继续保存备货
      SelectSearchModel: {
        CompanyName: ''
      },
      editItem: {
        SupplierID: '', // 供应商ID
        SupplierName: '', // 供应商名称
        Telephone: '', // 电话
        QQ: '',  // QQ
        CurrencyCode: "RMB",
        TaxRate: null,
        Qty: null, // 需求量
        Quality: '', // 品质
        Model: '',  // 型号
        Brand: '', // 品牌
        Packaging: '', // 封装
        MakeYear: '', // 年份
        MPQ: '', // 包装
        InvQty: '', // 库存量
        DeliveryDate: '', // 货期
        DataFrom: '', // 来源
        Contact: '', // 联系人
        Remark: '', // 备注

        //  不确定字段
        MarkingCode: '',// 打字
        Price: '',// 报价含税
        taxPrice: '', // 报价未税

        SourceType: null,
        SourceGuid: null,
        SourceId: null
      },
      config: {
        stkReverseSave: 'StkStockPurchase/Save',
        SearchByGUID: 'StkStock/GetStockListByGuids',
        SearchBySRGUID: 'StkStockPurchase/SearchBySRGUID',
        DropDownURL: 'DictItem/GetItemValue',
        CurrencyCodeURL: 'SysCurrency/Search'
      },
      loading: false,
      elementLoadingText: '正在保存数据,请稍后...',
      taxRateList: [],
      currencyList: [],
      qualityList: [],
      qqList: [],
      telList: [],
      deliveryDateList: [
        {key: '现货', value: '现货'},
        {key: '1天', value: '1天'},
        {key: '2天', value: '2天'},
        {key: '3天', value: '3天'},
        {key: '7天', value: '7天'},
        {key: '15天', value: '15天'},
        {key: '30天', value: '30天'},
        {key: '超过30天', value: '超过30天'},
      ],
      visible: false,
      typeData: null
    }
  },
  methods: {
    async onOpen(data) {
      Object.assign(this.$data.editItem, this.$options.data().editItem)
      if (data && data !== {}) {
        Object.keys(this.editItem).forEach(key => this.editItem[key] = data[key])
      }



      await this.onInit()
      await this.onGetQQOrTel()
      this.visible = true
    },
    async onInit() {
      let taxRate = this.selectOptionsTaxRate
      let currencyList = this.getCurrencyCodeListAsync
      let qualityList = await this.selectOptionsQuality

      this.taxRateList = taxRate
      this.currencyList = currencyList
      this.qualityList = qualityList

    },
    onResetPage() {
      // 重置页面
      Object.assign(this.$data.editItem, this.$options.data().editItem)
    },
    async Save() {
      // 保存
      // if (!this.hasValue(this.editItem.SupplierName)) {
      //   this.$message({message: '供应商不能为空', type: 'error'})
      //   return
      // }
      if (!this.hasValue(this.editItem.Model)) {
        this.$message({message: '型号不能为空', type: 'error'})
        return
      }
      if (!this.hasValue(this.editItem.Price)) {
        this.$message({message: '报价不能为空', type: 'error'})
        return
      }
      if (!this.hasValue(this.editItem.Quality)) {
        this.$message({message: '品质不能为空', type: 'error'})
        return
      }
      if (
        this.editItem.Qty === '' ||
        this.editItem.Qty <= 0 ||
        !isFinite(this.editItem.Qty)
      ) {
        this.$message({
          message: '数量不能为空且不能小于零的数字',
          type: 'error'
        })
        return
      }
      this.loading = true

      try {
        let params = this.editItem
        if (this.typeData) {
          params = Object.assign(params, this.typeData)
        }
        const data = await this.$post(CONFIG.save, this.editItem)
        if (data) {
          this.$message({message: '保存成功', type: 'success'})
          this.$emit('onConfirm', this.editItem)
          this.onResetPage()
          this.visible = this.IsContinueSave
        } else {
          this.$message({message: '保存失败', type: 'error'})
        }
      } finally {
        this.loading = false
      }

    },

    // 供应商处理
    SearchCompany() {
      // 显示供应商选择框
      this.InTypes = '2,3'
      this.companySelectShow = true
      this.SelectSearchModel.CompanyName = this.editItem.SupplierName
    },
    async companyChecked(item) {
      // 选择后的值
      this.editItem.SupplierName = item.CompanyName
      this.editItem.SupplierID = item.CCompanyID

      await this.onGetQQOrTel()
    },
    onClear() {
      this.onResetPage()
    },

    onOpenQQ() {
      const {QQ} = this.editItem
      if (!QQ) {
        this.$message({message: '请填写QQ', type: 'error'})
      }
      // mac系统弹出无法是被url（tencent://message/?uin=${QQ}&Site=&Menu=yes）
      // window.open(`tencent://message/?uin=${QQ}&Site=&Menu=yes`)
      try {
        //平台、设备和操作系统
        var system = {
            win: false,
            mac: false,
            xll: false,
            ipad:false
        };
        //检测平台
        var p = navigator.platform;
        system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
        system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;
        //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面
        if (system.win) {
          window.open(`tencent://message/?uin=${QQ}&Site=&Menu=yes`)
        } else {
          window.open(`https://wpa.qq.com/msgrd?v=3&uin=${QQ}&site=qq&menu=yes`);
        }
      } catch {
        window.open(`https://wpa.qq.com/msgrd?v=3&uin=${QQ}&site=qq&menu=yes`);
      }
    },

    async onGetQQOrTel() {
      const {SupplierName, SupplierID} = this.editItem
      if (!SupplierID && !SupplierName) return false
      console.log(SupplierID)
      console.log(SupplierName)

      const params = {
        CCompanyGUID: SupplierID,
        CompanyName: SupplierName
      }

      const data = await this.$post('CrmCompanyContacts/GetPhoneQQ', params)
      if (data) {
        if (data.QQList.length) {
          this.qqList = data.QQList.map(item => {
            return {
              value: item
            }
          })
        }

        if (data.PhoneList.length) {
          this.telList = data.PhoneList.map(item => {
            return {
              value: item
            }
          })
        }
      }


    },


    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    QQSearchAsync(queryString, cb) {
      let restaurants = this.qqList;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 300 * Math.random());
    },
    handleQQSelect(item) {
      console.log(item);
    },

    telSearchAsync(queryString, cb) {
      let restaurants = this.telList;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    handleTelSelect(item) {
      console.log(item);
    },


  },

  created() {

  }
}
</script>

<style lang="scss">
.m-enquiry-box .form_Row_W100 {
  width: 100% !important;
  padding-top: 5px;
}

.container {
  .grid-content {
    padding: 3px;
  }
}

.m-el-auto-complete {
  .m-el-auto-complete_title {
    display: inline-block;
    width: 62px;
    text-align: right;
    color: #000;
    font-size: 12px;
    margin-right: 8px;
  }

  .el-input--mini .el-input__inner {
    height: 24px;
    line-height: 24px;
    border-radius: 2px !important;
    width: 190px;
    border: 1px solid #B3CFEB !important;
  }

  .m-el-auto-complete_style {
    .el-input--mini .el-input__inner {
      width: 124px;
    }
  }
}
</style>


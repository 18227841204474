<template>
  <div class="m-crm-customer">
    <znl-table
      gridtype="base"
      ref="table"
      :columns="columns"
      checkboxBindingKey="CAutoID"
      :item-source="itemSource"
      :show-check="true"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :on-page-changed="onBindData"
      :on-refresh="onRefresh"
      box-class="m-crm-customer"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @companyName-click="onCompanyNameClick"
      @company-tag-click="onShowLabelSet"
      @page-loading="
        (d) => {
          $emit('page-loading', d);
        }
      "
      @current-row-change="
        (d) => {
          $emit('selection-changed', d);
        }
      "
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('crmCustomers')"
      @on-show-trigger="
        (e) => {
          hideShowColTrigger('crmCustomers', e.col);
        }
      "
      :drag-done="(col) => setTableColWidth('crmCustomers', col)"
      @drag-col-position="
        (cols) => {
          (columns = cols), saveErpTableConfig('crmCustomers', cols, true);
        }
      "
      @on-config-save="
        (cols) => {
          (columns = cols), saveErpTableConfig('crmCustomers', cols, false);
        }
      "
      @on-reset-click="resetTableConfig('crmCustomers')"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            form-type="input"
            :border="true"
            placeholder="请输入客户名称"
            width="150px"
            size="mini"
            layout="left"
            inp-perc="100%"
            v-model="searchCondiction.CompanyName"
            :clearable="true"
            @keyup.enter.native="onBindData(1)"
            type="text"
          ></znl-input>
          <znl-input
            form-type="input"
            placeholder="请输入客户地址"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            v-model="searchCondiction.Address"
            :clearable="true"
            :border="true"
            @keyup.enter.native="onBindData(1)"
            type="text"
          ></znl-input>
          <znl-input
            form-type="input"
            placeholder="电话 邮箱 QQ 联系人 阿里旺旺"
            width="172px"
            size="mini"
            layout="left"
            inp-perc="100%"
            v-model="searchCondiction.ContactInfo"
            :clearable="true"
            @keyup.enter.native="onBindData(1)"
            :border="true"
            type="text"
          ></znl-input>
          <znl-input
            :border="true"
            width="80px"
            size="mini"
            v-model="searchCondiction.FollowUserName"
            form-type="select"
            :clearable="true"
            :select-options="usersOptions"
            tabindex="4"
            placeholder="跟进人"
          ></znl-input>
          <znl-input
            :border="true"
            width="100px"
            size="mini"
            v-model="searchCondiction.Industry"
            form-type="select"
            :select-options="industryOptions"
            tabindex="4"
            :clearable="true"
            placeholder="所属行业"
          ></znl-input>

          <znl-input
            :border="true"
            width="100px"
            size="mini"
            v-model="searchCondiction.CloseClass"
            form-type="select"
            :select-options="selectOptionsCloseClass"
            tabindex="4"
            :clearable="true"
            placeholder="所处阶段"
          ></znl-input>

          <znl-input
            :border="true"
            width="100px"
            size="mini"
            filterable
            v-model="searchCondiction.CustomerStatus"
            form-type="select"
            :select-options="customerStatusOptions"
            tabindex="4"
            :clearable="true"
            placeholder="客户状态"
          ></znl-input>

          <znl-button
            type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn"
          >
            <znl-button-menu
              @click="onBindData(1)"
              tip="点击右侧向下三角图标显示/隐藏表头搜索"
            >
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu
              :width="20"
              tip="高级搜索"
              @click="onShowFiedsSearch()"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <!-- <el-checkbox class="form-group" v-model="searchCondiction.IsWithLabel" @change="onSearch">仅在当前标签中搜索</el-checkbox> -->
        </el-row>

        <el-row>
          <znl-button
            style-type="mac"
            v-if="this.hasRes('Add')"
            @click="onCustomerAdd()"
          >
            <i class="iconfont icon-add_btn_ic"></i>
            <span>新增</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('Delete')"
            @click="onDeleteRows()"
          >
            <i class="iconfont icon-delete_btn_ic"></i>
            <span>删除</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('SetSupplier')"
            @click="onSetSupplier()"
            class="ml5"
          >
            <i class="iconfont icon-tag_btn_ic"></i>
            <span>同时为供应商</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('EditFollowUser')"
            @click="onSetFollowUser()"
          >
            <i class="iconfont icon-table_edit_ic"></i>
            <span>修改跟进人</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('onImport')"
            @click="onImport()"
            class="ml5"
          >
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>导入</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('onExport')"
            @click="onExport"
            tip="按搜索条件"
            :btns="printznlbtns"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('SetProtectUser')"
            @click="onSetProtectUser(1)"
            class="ml5"
          >
            <i class="iconfont icon-admin_user_ic"></i>
            <span>保护</span>
          </znl-button>

          <znl-button
            style-type="mac"
            v-if="this.hasRes('CancelProtectUser')"
            @click="onSetProtectUser(0)"
          >
            <i class="iconfont icon-backout_btn_ic"></i>
            <span>取消保护</span>
          </znl-button>

          <el-checkbox
            class="form-group"
            v-model="IsProtected"
            @change="onBindData(1)"
            >仅显示已保护</el-checkbox
          >
          <!-- <znl-float-list :lists="moreBtns">
            <znl-button :height="22" style-type="mac">
              <i class="iconfont icon-left_nav_show_btn"></i>
              <span>更多操作</span>
            </znl-button>
          </znl-float-list>-->
        </el-row>
      </div>

      <div slot="parameterConfig">
        <el-table :data="parameterConfig" :height="338">
          <el-table-column
            label="参数名称"
            header-align="center"
            prop="ParamText"
            name="ParamText"
            class-name="znl-parameterConfig"
            binding="ParamText"
          ></el-table-column>
          <el-table-column
            label="参数值"
            header-align="center"
            class-name="znl-parameterConfig"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.ParamCode === 'IsVerifyCompanyFile'">
                <el-select
                  v-model="scope.row.ParamValue"
                  placeholder="请选择"
                  :disabled="Isdisabled"
                >
                  <el-option
                    v-for="item in [
                      { key: '0', value: '否' },
                      { key: '1', value: '是' },
                    ]"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </template>
              <template v-if="scope.row.ParamCode === 'ParamType'">
                <input type="hidden" v-model="scope.row.ParamType" />
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </znl-table>

    <!-- 客户编辑 -->
    <crm-customer-edit-dialog
      ref="CrmCustomerAddDialog"
      :visible="showCustomerDialog"
      :isAdd="customerDialogIsAdd"
      :dataModel="dialogCustomerInfoModel"
      :ccompanyid="ccompanyID"
      :company-name="companyName"
      :title="customerDialogTitle"
      :IsVerifyCompanyFile="this.IsVerifyCompanyFile"
      :isRefresh="refreshCustomerDialog"
      :resources="resourceList"
      @close="onEditDialogClose"
      @confirm="onEditDialogConfirm"
    ></crm-customer-edit-dialog>

    <!-- 编辑跟进信息 -->
    <crm-follow-info-edit-dialog
      ref="CrmFollowInfoEditDialog"
      :dataModel="dialogFollowInfoModel"
      :visible="dialogFollowInfoVisible"
      title="编辑跟进信息"
      @close="
        () => {
          dialogFollowInfoVisible = false;
        }
      "
      :isRefresh="refreshFollowInfoEditDialog"
      @confirm="
        (v) => {
          $emit('addnew'),
            (dialogFollowInfoVisible = false),
            v && this.onRefresh(),
            this.$refs.table.cleanAllCheck();
        }
      "
    ></crm-follow-info-edit-dialog>

    <!-- 设置保护 -->
    <crm-set-follow-user-dialog
      ref="CrmSetFollowUserDialog"
      @close="
        () => {
          showCustomerFollowUserDialog = false;
        }
      "
      :visible="showCustomerFollowUserDialog"
      v-if="showCustomerFollowUserDialog"
      @confirm="
        (v) => {
          (showCustomerFollowUserDialog = false),
            v && this.onRefresh(),
            this.$refs.table.cleanAllCheck();
        }
      "
    ></crm-set-follow-user-dialog>

    <!-- 设置标签 -->
    <customer-label-set
      :company-id="ccompanyID"
      :company-name="companyName"
      :visible="showCustomerLabelSet"
      @close="onSetLabelClose"
    ></customer-label-set>

    <export-column
      :visible="exportInfos.setExportColumnVisible"
      :addColumns="exportInfos.addColumnsList"
      :addConfigColumns="exportInfos.setConfigColumns"
      :saveConfigURL="exportInfos.setSaveConfigURL"
      v-if="exportInfos.setExportColumnVisible"
      :title="exportInfos.title"
      @close="
        () => {
          exportInfos.setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          exportInfos.setExportColumnVisible = false;
        }
      "
    ></export-column>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";
import CrmFollowInfoEditDialog from "@/components/modules/Crm/CrmFollowInfoEditDialog";
// import CrmCustomerAddDialog from "@/components/modules/Crm/CrmCustomersAdd";
import CrmCustomerEditDialog from "@/components/modules/Crm/CrmCustomerEdit";
import CrmSetFollowUserDialog from "@/components/modules/Crm/CrmBatchSaveFollowUser";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import customerLabelSet from "@c_modules/Crm/CustomerLabelSet";
import * as urls from "~/lib/urls";

const Config = {
  PK: "CAutoID",
  configURL: "CrmCustomers/GetConfig",
  saveConfigURL: "CrmCustomers/SaveConfig",
  searchURL: "CrmCustomers/Search",
  resetConfigURL: "CrmCustomers/ResetConfig",
  resetParamConfigURL: "CrmCustomers/ResetParamConfig",
  addURL: "CrmCustomers/Add",
  updateURL: "CrmCustomers/Edit",
  deleteURL: "CrmCustomers/Del",
  multiDeleteURL: "CrmCustomers/MultiDel",
  default: null,
  companyUserURL: "CompanyAccount/GetUsableUser",
  SetSupplierURL: "CrmCustomers/SetCompanyOrSupplier",
  SetProtectUserURL: "CrmCustomers/SetProtectUser",
  contactConfigURL: "CrmCompanyContacts/GetConfig", // 联系人信息配置
  contactConfigSaveURL: "CrmCompanyContacts/SaveConfig", // 保存联系人信息配置
  apiBaseUrl: urls.getErpApiBaseUrl(),
};
export default {
  mixins: [znlMethodsMixin, getCommonDataMixin, erpTableSetHandler, exportData],
  name: "CrmCustomers",
  config: Config,
  components: {
    CrmFollowInfoEditDialog,
    // CrmCustomerAddDialog,
    CrmSetFollowUserDialog,
    CrmCustomerEditDialog,
    ExportColumn,
    customerLabelSet,
  },
  data() {
    return {
      ZnlRegex: {
        GUID: /^[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{12}$/,
        INT: /^[1-9]{1}[0-9]*$/,
      },
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      Isdisabled: !this.$store.state.accountInfo.IsMainAccount,
      columns: [],
      parameterConfig: [],
      itemSource: [],
      searchFields: {},
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      CompanyName: "",
      IsProtected: false,
      resourceList: [],
      ParamJosn: [],
      IsVerifyCompanyFile: false,
      dialogFollowInfoVisible: false,
      dialogFollowInfoModel: {},
      refreshFollowInfoEditDialog: true,
      showCustomerDialog: false,
      showCustomerFollowUserDialog: false,
      refreshCustomerDialog: true,
      dialogCustomerInfoModel: {},
      ccompanyID: "",
      companyName: "",
      customerDialogTitle: "添加客户",
      customerDialogIsAdd: true,
      companyTypeMap: [{ key: "供应商", value: 3 }],
      ExportCol: [],
      isShowFiedsSearch: false,
      searchCondiction: {
        SearchType: 1, //1:客户， 2：供应商
        CompanyName: "",
        Address: "",
        ContactInfo: "",
        FollowUserName: "",
        CustomerHeat: "",
        Industry: "",
        Label: "",
        IsWithLabel: false,
        CloseClass: "",
        CustomerStatus: "",
      },
      value: "",
      defaultSearchCondition: {
        searchCondiction: {},
        searchFields: {},
      },
      usersOptions: [],
      industryOptions: [],
      moreBtns: [], // 更多操作
      printznlbtns: [
        {
          name: "设置供应商导出列",
          click: () => {
            this.showExportColumn("customer");
          },
        },
        {
          name: "设置联系人导出列",
          click: () => {
            this.showExportColumn("contact");
          },
        },
      ],
      exportInfos: {
        title: "设置导出列",
        setExportColumnVisible: false,
        addColumnsList: [],
        setConfigColumns: {},
        setSaveConfigURL: "",
      },
      contactConfig: {
        isGet: false,
        columns: [],
        defaultConfig: [],
      },
      loading: false,
      showCustomerLabelSet: false,
      currentRow: null,
      customerStatusOptions: [
        {
          key: "NoOrder_7",
          value: "最近一周未下单",
        },
        {
          key: "NoOrder_15",
          value: "最近半个月未下单",
        },
        {
          key: "NoOrder_30",
          value: "最近一个月未下单",
        },
        {
          key: "NoOrder_90",
          value: "最近三个月未下单",
        },
        {
          key: "NoBOM_7",
          value: "最近一周未询价",
        },
        {
          key: "NoBOM_15",
          value: "最近半个月未询价",
        },
        {
          key: "NoBOM_30",
          value: "最近一个月未询价",
        },
        {
          key: "NoBOM_90",
          value: "最近三个月未询价",
        },
        {
          key: "NoContact_7",
          value: "最近一周未跟进",
        },
        {
          key: "NoContact_15",
          value: "最近半个月未跟进",
        },
        {
          key: "NoContact_30",
          value: "最近一个月未跟进",
        },
        {
          key: "NoContact_90",
          value: "最近三个月未跟进",
        },
        {
          key: "Order_7",
          value: "最近一周已下单",
        },
        {
          key: "Order_15",
          value: "最近半个月已下单",
        },
        {
          key: "Order_30",
          value: "最近一个月已下单",
        },
        {
          key: "Order_90",
          value: "最近三个月已下单",
        },
        {
          key: "Order_7",
          value: "最近一周未已下单",
        },
        {
          key: "BOM_7",
          value: "最近一周已询价",
        },
        {
          key: "BOM_15",
          value: "最近半个月已询价",
        },
        {
          key: "BOM_30",
          value: "最近一个月已询价",
        },
        {
          key: "BOM_90",
          value: "最近三个月已询价",
        },
        {
          key: "Contact_7",
          value: "最近一周已跟进",
        },
        {
          key: "Contact_15",
          value: "最近半个月已跟进",
        },
        {
          key: "Contact_30",
          value: "最近一个月已跟进",
        },
        {
          key: "Contact_90",
          value: "最近三个月已跟进",
        },
      ],
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage("CrmCustomers", Config.configURL);
      }
      this.ParamJosn = config.ParamJosn;
      // 保存时验证公司资料
      this.IsVerifyCompanyFile =
        this.getCompanySetupValue("SaveCompanyWithFullCheck") === "1";
      let userAllowUsers = _.map(
        this.selectOptionsAllUserURL,
        function (obj, index) {
          return { key: obj.value, value: obj.value };
        }
      );
      this.usersOptions = userAllowUsers;
      this.resourceList = config.ResourceList.map((item) => item.Code);
      let tempColumns = config.ColumnConfigs;
      this.moreBtns = [
        {
          name: "保护客户",
          isShow: this.hasRes("SetProtectUser"),
          click: () => {
            this.onSetProtectUser(1);
          },
        },
        {
          name: "取消保护",
          isShow: this.hasRes("CancelProtectUser"),
          click: () => {
            this.onSetProtectUser(0);
          },
        },
        {
          name: "导入客户",
          isShow: this.hasRes("onImport"),
          click: () => {
            this.onImport();
          },
        },
        // {
        //   name: '导出(按查询条件)',
        //   isShow: this.hasRes('onExport'),
        //   click: () => {
        //     this.onExport()
        //   }
        // }
      ];
      // 操作按钮
      // /添加自定义按钮
      let btnCols = [
        {
          width: 40,
          type: "button",
          title: "操作",
          btnTxt: "跟进",
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: (row) => {
            this.onSetFollowInfo(row);
          },
        },
      ];

      this.initColumn(tempColumns);

      this.parameterConfig = config.ParamJosn;
      this.defaultConfig = config;
      this.columns = btnCols.concat(tempColumns);
      this.pageSize = config.PageSize || 30;
      this.role = config.Role;
      this.$refs.table.init();
      this.initData && this.onBindData();
      this.$emit("page-loading", false);
    },

    // 初始化列
    initColumn(columns) {
      let vm = this;
      // 公司名
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CompanyName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "companyName-click",
          className: "v-cell-link",
        });
      }

      // 币种
      let currencyNames = _.map(
        this.selectOptionsCurrencyCode,
        (item, index) => {
          return { key: item.value, value: item.key };
        }
      );
      colIndex = _.findIndex(
        columns,
        (column) => column.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 55,
          options: currencyNames,
        });
      }

      // 类型
      colIndex = _.findIndex(columns, (col) => col.BindField === "CompanyType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.companyTypeMap,
        });
      }

      // 所处阶段(成交分类)
      colIndex = _.findIndex(columns, (col) => col.BindField === "CloseClass");
      let closeClassOptinos = _.map(this.selectOptionsCloseClass, (item) => {
        return { key: item.value, value: item.key };
      });
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: closeClassOptinos,
        });
      }

      // 标签
      colIndex = _.findIndex(columns, (col) => col.BindField === "Label");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "company-tag-click",
          className: "v-cell-link label-text-left",
          formatter(rowData, rowIndex, field) {
            return vm.formatLabels(rowData.Label);
          },
        });
      }
    },

    // 格式化标签
    formatLabels(labelStr) {
      let html = "";
      if (this.hasValue(labelStr)) {
        let arry = labelStr.split(",");
        _.each(arry, (label) => {
          html += `<span class="el-tag el-tag--mini mr2">${label}</span>`;
        });
        labelStr = arry.join(", ");
      } else {
        // html = '<div class="znl-button znl-btn-default mac list-set-label-btn"><div class="button"><span><i class="iconfont icon-labelling_btn"></i>设置标签</span></div></div>'
      }

      return `<div title="${labelStr}">${html}</div>`;
    },

    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },

    // 构建查询条件
    createSearchWhere(pageIndex, isSearchNoSetLabel = false) {
      let fieldsData = _.extend({}, this.searchFields);
      /* 跟进人是否是GUID */
      if (
        fieldsData.FollowUserName &&
        this.ZnlRegex.INT.test(fieldsData.FollowUserName)
      ) {
        let currObj = _.find(this.usableUser, function (user) {
          return (
            user.UserID.toString() === fieldsData.FollowUserName.toString()
          );
        });
        fieldsData.FollowUserName = currObj ? currObj.UserName : "";
      }
      /* 保护人是否是GUID */
      if (
        fieldsData.ProtectUserName &&
        this.ZnlRegex.INT.test(fieldsData.ProtectUserName)
      ) {
        let currObj = _.find(this.usableUser, function (user) {
          return (
            user.UserID.toString() === fieldsData.ProtectUserName.toString()
          );
        });
        fieldsData.ProtectUserName = currObj ? currObj.UserName : "";
      }
      // 所处阶段
      if (this.searchCondiction.CloseClass) {
        fieldsData["CloseClass"] = this.searchCondiction.CloseClass;
      }
      if (this.searchCondiction.IsWithLabel) {
        fieldsData["Label"] = this.searchCondiction.Label;
      }
      let jsonData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(fieldsData),
          CompanyTypes: "1,3",
          PageIndex: pageIndex || 1,
          PageSize: this.pageSize,
        },
        {
          CompanyName: this.searchCondiction.CompanyName,
          Address: this.searchCondiction.Address,
          ContactInfo: this.searchCondiction.ContactInfo,
          FollowUserName: this.searchCondiction.FollowUserName,
          Industry: this.searchCondiction.Industry,
          IsProtected: this.IsProtected,
          SearchType: 1, //1:客户， 2：供应商
          // 外部搜索条件
          CustomerStatus: this.searchCondiction.CustomerStatus,
        }
      );
      if (
        this.searchCondiction.Label === "未设置标签" &&
        (this.searchCondiction.IsWithLabel || isSearchNoSetLabel)
      ) {
        jsonData.IsMarkLabels = false;
      }
      return jsonData;
    },

    onSearch(params = null) {
      if (params) {
        if (this.hasValue(params.CloseClass)) {
          params.CloseClass = parseInt(params.CloseClass);
        } else {
          params.CloseClass = null;
        }
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      this.onBindData(1);
    },

    onBindData(pageIndex = 1, currentRow = null) {
      this.loading = true;
      let jsonData = this.createSearchWhere(pageIndex);
      return this.$post(Config.searchURL, jsonData, (data) => {
        if (data.ResultList === null || data.ResultList.length === 0) {
          this.itemSource = [];
        } else {
          this.itemSource = data.ResultList;
          currentRow = currentRow || this.itemSource[0];
          this.$emit("selection-changed", currentRow);
        }
        this.pageIndex = pageIndex;
        this.totalCount = data.TotalCount;
        this.loading = false;
        if (this.$refs.table) this.$refs.table.cleanClickRow();
      });
    },

    onRefresh(currentRow) {
      this.onBindData(this.pageIndex, currentRow);
    },

    onlabelClick(lid, labelText) {
      this.pageIndex = 1;
      this.searchCondiction = _.extend(
        {},
        this.defaultSearchCondition.searchCondiction
      );
      this.searchFields = _.extend(
        {},
        this.defaultSearchCondition.searchFields
      );
      if (lid === -99) {
        this.searchCondiction.IsWithLabel = false;
        this.searchCondiction.Label = "";
      } else {
        this.searchCondiction.IsWithLabel = true;
        this.searchCondiction.Label = labelText;
      }
      this.onBindData(1);
    },

    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    // 新增
    onCustomerAdd() {
      this.showCustomerDialog = !this.showCustomerDialog;
      this.openCustomerDialog(true, {
        CAutoID: 0,
        CCompanyID: "",
        CompanyName: "",
      });
    },
    // 编辑
    onCustomerEdit(row) {
      this.openCustomerDialog(false, row);
    },

    openCustomerDialog(isAdd, model) {
      this.customerDialogIsAdd = isAdd;
      this.customerDialogTitle = isAdd ? "添加客户" : "编辑客户";
      this.ccompanyID = model.CCompanyID;
      this.companyName = model.CompanyName;
      this.showCustomerDialog = true;
      this.refreshCustomerDialog = !this.refreshCustomerDialog;
      this.$nextTick(function () {
        this.$refs.table.cleanAllCheck();
        this.dialogCustomerInfoModel = model;
      });
    },

    onEditDialogClose(labelChange = null) {
      this.showCustomerDialog = false;
      if (labelChange) {
        this.$emit("label-change", labelChange);
      }
      // if (isRefresh) {
      //   this.onRefresh()
      // }
    },

    onEditDialogConfirm(labelChange = null) {
      this.onEditDialogClose(true, labelChange);
      this.$emit("confirm", true), this.$refs.table.cleanAllCheck();
      this.onRefresh();
    },

    async onSetProtectUser(vtype) {
      // 设置保护人，取消保护人
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请至少选择一条数据操作！",
            type: "error",
          });
        }
      }
      if (rows.length === 0) {
        this.$message({ message: "请至少选择一条数据操作！", type: "error" });
        return;
      }
      var msg = vtype === 1 ? "确定要设置保护?" : "确定要取消保护?";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var checkMsg = this.checkProtectUser(vtype);
        if (checkMsg !== "") {
          this.$message({ message: checkMsg, type: "error" });
        } else {
          _.each(rows, (r) => {
            // 0,取消1，设置当前用户
            if (vtype === 1) {
              r.ProtectUserID = 1;
            } else {
              r.ProtectUserID = 0;
            }
          });
          return this.$post(Config.SetProtectUserURL, rows, (data) => {
            this.$refs.table.cleanAllCheck();
            this.$message({ message: "设置成功", type: "success" });
            this.onRefresh();
          });
        }
      });
    },

    checkProtectUser(vtype) {
      let rows = this.$refs.table.getCheckedRows();
      let hasRow = 0;
      let noHasRow = 0;
      _.each(rows, (r) => {
        // 0,取消1，设置当前用户
        if (vtype === 1) {
          if (r.ProtectUserName !== undefined && r.ProtectUserName !== null) {
            if (r.ProtectUserName.length > 0) {
              hasRow++;
            }
          }
        } else {
          if (r.ProtectUserName !== undefined && r.ProtectUserName !== null) {
            if (r.ProtectUserName.length === 0) {
              noHasRow++;
            }
          } else {
            noHasRow++;
          }
        }
      });
      if (hasRow > 0) {
        return "所选客户已设置了保护，不能继续操作";
      } else if (noHasRow > 0) {
        return "所选客户未设置保护，不能继续操作";
      } else {
        return "";
      }
    },

    async onSetFollowUser() {
      let rows = this.$refs.table.getCheckedRows();
      var ids = [];
      if (!_.isUndefined(rows[0])) {
        _.each(rows, (r) => {
          ids.push(r.CCompanyID);
        });
      }
      let info = this.createSearchWhere(1);
      info.RowsCount = ids.length;
      info.CCompanyIDs = ids;
      info.WhereCount = this.totalCount;
      this.$store.commit("setParamOrder", { info: info });
      this.$nextTick(function () {
        this.showCustomerFollowUserDialog = true;
      });
    },

    onDeleteRows() {
      // 删除
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请先选中要删除的数据！",
            type: "error",
          });
        }
      }
      if (rows.length === 0) {
        this.$message({ message: "请先选中要删除的数据！", type: "error" });
        return;
      }
      this.$confirm("删除确认, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return this.$post(Config.multiDeleteURL, rows, (data) => {
          this.$refs.table.cleanAllCheck();
          this.$message({ message: "删除成功", type: "success" });
          this.onRefresh();
        });
      });
    },

    onSetSupplier() {
      // 设置为供应商
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请至少选择一条数据操作！",
            type: "error",
          });
        }
      }
      if (rows.length === 0) {
        this.$message({ message: "请至少选择一条数据操作！", type: "error" });
        return;
      }
      this.$confirm("确定将该客户设置为供应商?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _.each(rows, (r) => {
          r.CompanyType = 1;
        });
        return this.$post(Config.SetSupplierURL, rows, (data) => {
          this.$refs.table.cleanAllCheck();
          this.$message({ message: "设置成功", type: "success" });
          this.onRefresh();
        });
      });
    },

    // 设置跟进信息
    onSetFollowInfo(row) {
      this.dialogFollowInfoVisible = true;
      this.refreshFollowInfoEditDialog = !this.refreshFollowInfoEditDialog;
      this.$nextTick(function () {
        this.dialogFollowInfoModel = {
          CCompanyGUID: row.CCompanyID,
          CCompanyName: row.CompanyName,
          FollowUserID: row.FollowUserID,
          FollowUserName: row.FollowUserName,
          UserName: this.$store.state.accountInfo.UserName,
        };
      });
    },

    // 设置标签
    onShowLabelSet(e) {
      let row = e.row;
      this.currentRow = row;
      this.ccompanyID = row.CCompanyID;
      this.companyName = row.CompanyName;
      this.showCustomerLabelSet = true;
    },
    onSetLabelClose(labels, isChange, labelChange = null) {
      this.showCustomerLabelSet = false;
      if (isChange) {
        this.onRefresh(this.currentRow);
      }
      if (labelChange) {
        this.$emit("label-change", labelChange);
      }
    },

    onImport: async function () {
      // 客户资料导入
      this.$nextTick(function () {
        this.importData({ importType: "customer" });
      });
    },

    async showExportColumn(setType) {
      if (setType === "customer") {
        this.exportInfos.title = "设置客户导出列";
        this.exportInfos.setSaveConfigURL = Config.saveConfigURL;
        this.exportInfos.addColumnsList = this.columns;
        this.exportInfos.setConfigColumns = this.defaultConfig;
      } else {
        this.exportInfos.title = "设置联系人导出列";
        this.exportInfos.setSaveConfigURL = Config.contactConfigSaveURL;

        if (this.contactConfig.isGet === false) {
          let config = await this.isSaveStorage(
            "CrmCompanyContacts",
            Config.contactConfigURL
          );
          this.contactConfig.isGet = true;
          this.contactConfig.columns = config.ColumnConfigs;
          this.contactConfig.defaultConfig = config;
        }

        this.exportInfos.addColumnsList = this.contactConfig.columns;
        this.exportInfos.setConfigColumns = this.contactConfig.defaultConfig;
      }
      this.exportInfos.setExportColumnVisible = true;

      this.addColumnsList = this.columns;
      this.setConfigColumns = this.defaultConfig;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.setExportColumnVisible = true;
    },

    onExport: async function () {
      let columns = [];
      _.each(this.columns, (col) => {
        if (col.IsExprot) {
          columns.push(col);
        }
      });
      // 待改code的列(列名跟供应商信息列名重复)
      let colMapping = {
        Remark: "ContactRemark",
        DataSource: "ContactDataSource",
      };
      _.each(this.contactConfig.columns, (col) => {
        if (col.IsExprot) {
          let mapField = colMapping[col.BindField];
          if (mapField) {
            let newCol = _.extend({}, col, { BindField: mapField });
          } else {
            columns.push(col);
          }
        }
      });
      let jsonData = this.createSearchWhere(1);
      this.loading = await this.exportData(
        "CrmSuppliers",
        jsonData,
        columns,
        "客户资料"
      );
    },

    onCompanyNameClick(e) {
      if (e.field !== "CompanyName") {
        return;
      }
      this.onCustomerEdit(e.row);
    },
  },
  async mounted() {
    this.industryOptions = _.filter(
      this.selectOptionsIndustry,
      (item) => item.key !== ""
    );
    await this.onInit();
  },
  created() {
    // 默认搜索条件
    this.defaultSearchCondition.searchCondiction = _.extend(
      {},
      this.searchCondiction
    );
    this.defaultSearchCondition.searchFields = _.extend({}, this.searchFields);
  },
};
</script>

<style lang="scss">
</style>

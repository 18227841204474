<template>
  <div style="height: 100%" class="supplier-edit-bottom-tabs">
    <el-tabs
      slot="znlBottom"
      height="100%"
      v-model="bottomActiveName"
      @tab-click="onBottomTabClick"
      class="znlBottom tab-btnstyle-box tab-details"
    >
      <el-tab-pane
        :label="tabTitleContact"
        name="CrmCompanyContacts"
        class="tabContact"
      >
        <znl-gridmodule
          gridtype="action"
          ref="flexGrid"
          title=""
          layout-type="other"
          height="100%"
          :has-znl-btns="false"
          :is-show-frombtns="true"
          :columns="columns"
          :item-source="itemSource"
          :edit-row-index="editRowIndex"
          :is-fields-search="false"
          :is-multi-rows-check="true"
          :on-init="onInit"
          :on-delete-checked-rows="onDelete"
        >
          <div class="operate-btns">
            <znl-button type="default" @click="onAdd">
              <i class="iconfont icon-add_btn_ic"></i>
              <span>添加联系人</span>
            </znl-button>
            <znl-button
              type="default"
              @click="onDelete"
              v-if="hasRes('Delete')"
            >
              <i class="iconfont icon-d"></i>
              <span>删除联系人</span>
            </znl-button>
          </div>
        </znl-gridmodule>
      </el-tab-pane>
      <el-tab-pane
        :label="tabTitleInquire"
        v-if="hasRight('PastInquire')"
        name="PastInquire"
      >
        <inquire-details
          ref="inquireRecord"
          v-if="InquireTableInit"
          :is-operate="false"
          :init-data="false"
          :is-supplier-click="false"
          :is-generate-purchase-order="false"
          @set-total-count="
            (count) => {
              tabData.inquireCount = count || 0;
            }
          "
        ></inquire-details>
      </el-tab-pane>
      <el-tab-pane
        :label="tabTitleStkin"
        v-if="hasRight('PastStkIn')"
        name="PastStkIn"
      >
        <stk-in-details
          ref="inRecord"
          v-if="StkInTableInit"
          :init-data="false"
          :is-operate="false"
          module-name="StkInDetails"
          @set-total-count="
            (count) => {
              tabData.stkinCount = count || 0;
            }
          "
        ></stk-in-details>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-row class="operate-btns">
      <znl-button type="default" :height="28" @click="onAdd">
        <i class="iconfont icon-add_btn_ic"></i>
        <span>添加联系人</span>
      </znl-button>
      <znl-button type="default" :height="28" @click="onDelete">
        <i class="iconfont icon-delete_btn_ic"></i>
        <span>删除联系人</span>
      </znl-button>
    </el-row> -->
  </div>
</template>

<script>
import CompanyContacts from "@/components/modules/Crm/CompanyContacts";
import StkInDetails from "@c_modules/Stk/StkInDetails";
import InquireDetails from "@c_modules/RFQ/InquireDetails";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  configURL: "CrmCompanyContacts/GetConfig",
  getContactCountUrl: "CrmCompanyContacts/GetCount",
  getInquireCountUrl: "StkInquireRecord/GetCount",
  getStkinRecordCountUrl: "StkInDetails/GetCount",
};

export default {
  name: "VSuppliers",
  mixins: [getCommonDataMixin],
  components: {
    CompanyContacts,
    StkInDetails,
    InquireDetails,
  },
  props: {
    isAdd: Boolean,
    CCompanyID: String,
    CompanyName: String,
    contactList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    supplierResources: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      columns: [],
      dialogLoading: false,
      pageData: [],
      bottomActiveName: "CrmCompanyContacts",
      crmSuppliersSearchModel: {},
      editRowIndex: -1,
      SuppliersContacts: {},
      Inquire: {},
      ZnlStkInRecord: {},
      CurrentSupplier: {},
      pageLoading_Suppliers: true,
      pageLoading_Contacts: true,
      InquireTableInit: false,
      StkInTableInit: false,
      itemSource: [],

      tabData: {
        contactCount: 0,
        inquireCount: 0,
        stkinCount: 0,
      },
    };
  },
  computed: {
    pageLoading() {
      return this.pageLoading_Suppliers && this.pageLoading_Contacts;
    },
    tabTitleContact() {
      return "供应商联系人(" + this.tabData.contactCount + ")";
    },
    tabTitleInquire() {
      return "询价记录(" + this.tabData.inquireCount + ")";
    },
    tabTitleStkin() {
      return "入库记录(" + this.tabData.stkinCount + ")";
    },
  },
  methods: {
    async onInit() {
      _.each(this.contactList, (item) => {
        this.itemSource.push(item);
      });
      await this.initContact();
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      this.$post(pageUrl, { PageCode: "VSuppliers" }, (data) => {
        this.pageData = data;
      });

      this.tabData.contactCount = this.contactList.length;
      this.getInquireCount();
      this.getStkinCount();
    },

    async initContact() {
      let config = await this.isSaveStorage(
        "CrmCompanyContacts",
        CONFIG.configURL
      );
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);

      if (
        _.find(columns, function (column) {
          return column.binding === "GenderName";
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === "GenderName";
          }),
          {
            dataMap: [
              { key: "1", value: "男" },
              { key: "0", value: "女" },
            ],
            showDropDown: true,
          }
        );
      }
      // 数据来源
      let colDataSource = _.find(columns, (column) => {
        return column.binding === "DataSource";
      });
      if (colDataSource) {
        _.extend(colDataSource, {
          dataMap: this.selsectOptionsDemandSources,
          showDropDown: true,
        });
      }

      this.defaultConfig = config;
      this.columns = columns;
      return true;
    },

    onAdd() {
      this.$refs.flexGrid.addRow(true);
    },

    onDelete() {
      _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1);
            return;
          }
        });
      });
    },

    onLabelSelect(sdata) {
      this.$refs.Suppliers.labelClick(sdata.LID, sdata.labelText);
    },
    hasRight(code) {
      return _.some(this.pageData, (page) => page.Code === code);
    },
    onBottomTabClick() {
      this.lineSearch();
      if (!this.isAdd) {
      }
    },
    lineSearch() {
      if (this.bottomActiveName === "PastInquire") {
        if (!this.InquireTableInit) {
          this.InquireTableInit = true;
        }
        if (!this.CompanyName) {
          return;
        }
        this.Inquire = {
          CCompanyID: this.CCompanyID,
          SupplierName: this.CompanyName,
        };
        this.$nextTick(() => {
          this.$refs.inquireRecord &&
            this.$refs.inquireRecord.onHeadSearch(this.Inquire);
        });
      } else if (this.bottomActiveName === "PastStkIn") {
        if (!this.StkInTableInit) {
          this.StkInTableInit = true;
        }
        if (!this.CompanyName) {
          return;
        }
        this.ZnlStkInRecord = {
          CCompanyID: this.CCompanyID,
          SupplierName: this.CompanyName,
        };
        this.$nextTick(() => {
          this.$refs.inRecord &&
            this.$refs.inRecord.onHeadSearch(this.ZnlStkInRecord);
        });
      }
    },
    labelSeted(labelse) {
      this.$refs.supplierLabel.initLabels();
    },

    // 统计联系人总数
    getContactCount() {
      this.tabData.contactCount = this.contactList || 0;
    },
    // 统计询价记录总数
    getInquireCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.inquireCount = 0;
        return true;
      }
      let fieldWhereString = {
        CCompanyID: this.CCompanyID,
        SupplierName: this.CompanyName,
      };
      let param = {
        FieldWhereString: JSON.stringify(fieldWhereString),
      };
      this.$post(CONFIG.getInquireCountUrl, param, (data) => {
        this.tabData.inquireCount = data || 0;
      });
    },
    // 统计入库记录总数
    getStkinCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.stkinCount = 0;
        return true;
      }
      let fieldWhereString = {
        CCompanyID: this.CCompanyID,
        SupplierName: this.CompanyName,
      };
      let param = {
        FieldWhereString: JSON.stringify(fieldWhereString),
        IsReturn: false,
      };
      this.$post(CONFIG.getStkinRecordCountUrl, param, (data) => {
        this.tabData.stkinCount = data || 0;
      });
    },

    hasRes(code) {
      return _.includes(this.supplierResources, code);
    },
  },
  watch: {
    selsectOptionsDemandSources(items) {
      if (items && items.length > 0) {
        let colDataSource = _.find(this.columns, (column) => {
          return column.binding === "DataSource";
        });
        if (colDataSource) {
          _.extend(colDataSource, {
            dataMap: this.selsectOptionsDemandSources,
            showDropDown: true,
          });
        }
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang='scss'>
.supplier-edit-bottom-tabs {
  .tab-btnstyle-box > .el-tabs__header {
    background: #fff;
  }
  .operate-btns {
    padding-top: 5px;
    background-color: #f2f2f2;
    z-index: 990;
  }
}
.tabContact {
  overflow: auto !important;
}
</style>

<template>
<div>
  <znl-module type='base'
              ref="flexGrid"
              header="终端客户名录"
              :columns="columns"
              :height="height"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="isShowFiedsSearch"
              :is-multi-rows-check="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              @selection-changed="d=>{$emit('selection-changed',d)}">
    <div slot="action-buttons">
    </div>
    <div slot="action-form">
      <div class='znl-labels'>
        <el-button :class="{'label-checked':checkedIndustryId===Industry.LID, 'label':checkedIndustryId!==Industry.LID  }"
                   v-for="Industry in Industries"
                   :key="Industry.LID"
                   @click='industryClick(Industry.LID,Industry.LabelName)'>{{ Industry.LabelName+'('+Industry.AssignedCount+')' }}</el-button>
      </div>
      <el-row>
      <el-input placeholder="客户公司名称"
                v-model="CompanyName"
                size="mini"
                @keyup.enter.native="onPageSearch"></el-input>
          <znl-button type="multiple" :height='22' style-type='main' >
              <znl-button-menu
              @click="onPageSearch">
              <span><i class="iconfont icon-search_ic"></i>搜索</span>
              </znl-button-menu>
              <znl-button-menu :width='7' tip="高级搜索" @click="onShowFiedsSearch()"><i class="iconfont icon-arrow-drop-down_ic"></i></znl-button-menu>
            </znl-button>

      <el-checkbox v-model="IsShowShield"
                   @change="$refs.flexGrid.onSearch()">显示屏蔽的客户</el-checkbox>
      <!--<el-tooltip :content=" (IsShowShield?'显示':'隐藏')+'屏蔽的客户'" placement="top">
            <el-switch
              v-model="IsShowShield"
              on-color="#13ce66"
              off-color="#ff4949"
              @change = '$refs.flexGrid.onSearch()'>
            </el-switch>
          </el-tooltip>-->
          </el-row>
    </div>
  </znl-module>

  <znl-dialog title='添加到我的客户'
            :visible='dialogCopyCustomerVisible'
            @update:visible="val=>{dialogCopyCustomerVisible=val}">
    <CrmCopyCustomerEditDialog v-if="dialogCopyCustomerModel" ref="CrmFollowInfoEditDialog"
                  :dataModel="dialogCopyCustomerModel"
                  height="50%"
                  :isRefresh="refreshFollowInfoEditDialog"
                  @confirm="(v)=>{dialogCopyCustomerVisible=false,v&&this.onRefresh()}"></CrmCopyCustomerEditDialog>
  </znl-dialog>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import CrmCopyCustomerEditDialog from '@/components/modules/Crm/CrmCopyCustomerEditDialog'
const Config = {
  PK: 'CompanyID',
  configURL: 'CrmTerminalCustomerListing/GetConfig',
  saveConfigURL: 'CrmTerminalCustomerListing/SaveConfig',
  resetConfigURL: 'CrmTerminalCustomerListing/ResetConfig',
  searchURL: 'CrmTerminalCustomerListing/Search',
  default: null,
  getIndustriesURL: 'CrmTerminalCustomerListing/Industries',
  setShieldURL: 'CrmTerminalCustomerListing/SetShield',
  setCancelShieldURL: 'CrmTerminalCustomerListing/SetCancelShield'
}
export default {
  name: 'CrmTerminalCustomerListing',
  mixins: [znlMethodsMixin],
  config: Config,
  components: {
    CrmCopyCustomerEditDialog
  },
  data () {
    return {
      Industries: [],
      checkedIndustryId: -1,
      config: {
      },
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      isShowFiedsSearch: false,
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      IsShowShield: false,
      CompanyName: '',
      role: '1',
      dialogCopyCustomerVisible: false,
      dialogCopyCustomerModel: {},
      resourceList: [],
      refreshFollowInfoEditDialog: true
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  mounted () {
    this.initIndustries()
  },
  methods: {
    initIndustries () {
      let vm = this
      vm.$post(Config.getIndustriesURL, {}, (data) => {
        vm.Industries = data
      })
    },
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('crmTerminalCustomerListing', Config.configURL)
        // config = await this.$post(Config.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      let tempColumns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 操作按钮
      // /添加复制按钮
      let vm = this
      tempColumns.unshift({
        'name': 'copyOperate',
        'dataType': 5,
        'binding': 'copyOperate',
        'width': 105,
        'header': '添加到我的客户',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isSystem': true,
        'editable': false,
        'buttons': [{
          'content': '添加到我的客户',
          'click': function (rowData) {
            vm.dialogCopyCustomerVisible = true
            vm.refreshFollowInfoEditDialog = !vm.refreshFollowInfoEditDialog
            vm.$nextTick(function () {
              vm.dialogCopyCustomerModel = rowData
            })
          },
          'when': (rowData) => { return !_.isUndefined(rowData[Config.PK]) && !_.isNull(rowData[Config.PK]) && rowData.ProStatus === '0' } // (item) => { return item.BillType && (item.BillType === 1 || item.BillType === 2) }
        }]
      })

      // /添加自定义按钮
      let _this = this
      tempColumns.unshift({
        'name': 'operate',
        'dataType': 5,
        'binding': 'operate',
        'width': 90,
        'header': '操作',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isSystem': true,
        'editable': false,
        'buttons': [{
          'content': '屏蔽',
          'click': function (rowData, setData) {
            // console.log(rowData)
            vm.$confirm('屏蔽确认, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              vm.$post(Config.setShieldURL, rowData, (data) => {
                // setData(data)
                if (data.IsShield) {
                  _this.IsShowShield = true
                  _this.$message({ message: '屏蔽成功', type: 'success' })
                  _this.onRefresh()
                }
              })
            })
          },
          'when': (rowData) => {
            return !_.isUndefined(rowData[Config.PK]) && !_.isNull(rowData[Config.PK]) && !rowData.IsShield
          } // (item) => { return item.BillType && (item.BillType === 1 || item.BillType === 2) }
        }, {
          'content': '取消屏蔽',
          'click': function (rowData, setData) {
            vm.$confirm('取消屏蔽确认, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              vm.$post(Config.setCancelShieldURL, rowData, (data) => {
                // rowData.setData(data)
                // console.log(data)
                if (!data.IsShield) {
                  _this.$message({ message: '取消屏蔽成功', type: 'info' })
                  _this.onRefresh()
                }
              })
            })
          },
          'when': (rowData) => {
            return !_.isUndefined(rowData[Config.PK]) && !_.isNull(rowData[Config.PK]) && rowData.IsShield
          } // (item) => { return item.BillType && (item.BillType === 1 || item.BillType === 2) }
        }]
      })
      this.defaultConfig = config
      this.columns = tempColumns
      this.pageSize = config.PageSize
      this.role = config.Role
      this.initData && this.onBindData()
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onBindData (pageIndex) {
      let fieldsData = _.extend({}, this.fieldsFilter)
      let jsonData = _.extend({}, {
        FieldWhereString: JSON.stringify(fieldsData),
        CompanyTypes: '1,3',
        PageIndex: pageIndex || 1,
        PageSize: this.pageSize,
        IsShowShield: this.IsShowShield
      })
      return this.$post(Config.searchURL, jsonData, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    onSearch (params = {}) {
      let IndustryText = params.Industry
      _.extend(this.searchModel, params)
      this.checkedIndustryId = _.extend({ LID: -1 }, _.find(this.Industries, function (item) { return item.LabelName === (_.isNull(IndustryText) ? '未分类' : IndustryText) })).LID
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('crmTerminalCustomerListing')
      return this.$post(Config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, Config.default))
    },
    onResetSetting () {
      localStorage.removeItem('crmTerminalCustomerListing')
      return this.$post(Config.resetConfigURL)
    },

    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('crmTerminalCustomerListing')
      return this.$post(Config.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, Config.default))
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onPageSearch () {
      this.checkedIndustryId = -1
      this.$set(this.searchModel, 'CompanyName', this.CompanyName)
    },
    industryClick (id, labelName) {
      if (this.checkedIndustryId !== id) {
        this.checkedIndustryId = Number(id)
        // this.$refs.flexGrid.search({Industry: labelName})
        if (labelName === '未分类') {
          this.$set(this.searchModel, 'Industry', null)
        } else {
          this.$set(this.searchModel, 'Industry', labelName)
        }
      } else {
        this.checkedIndustryId = -1
        this.$set(this.searchModel, 'Industry', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.znl-labels {
  padding: 5px 8px 5px 0;
  min-height: 30px;
  .label {
    margin: 5px;
    padding: 4px 5px;
    font-size: 12px;
    border: 1px solid #e8ecf5;
    background-color: #ffffff;
    color: #666666;
  }
  .label-checked {
    margin: 5px;
    padding: 4px 5px;
    font-size: 12px;
    border: 1px solid #ef8011;
    background-color: #FE9629;
    color: #FFFFFF;
  }
}
</style>


<template>
  <div style="height: 100%" class="customer-edit-bottom-tabs">
    <el-tabs
      slot="znlBottom"
      height="100%"
      v-model="activeName"
      @tab-click="onTabClick"
      class="znlBottom tab-btnstyle-box tab-details"
    >
      <!-- 联系人 -->
      <el-tab-pane
        :label="tabTitleContact"
        name="contactInfo"
        class="tabContact"
      >
        <znl-gridmodule
          gridtype="action"
          ref="flexGrid"
          title=""
          layout-type="other"
          height="100%"
          :has-znl-btns="false"
          :is-show-frombtns="true"
          :columns="columns"
          :item-source="contactList"
          :edit-row-index="editRowIndex"
          :is-fields-search="false"
          :is-multi-rows-check="true"
          :on-init="onInit"
          :on-delete-checked-rows="onDelete"
        >
          <div class="operate-btns">
            <znl-button type="default" @click="onAdd">
              <i class="iconfont icon-add_btn_ic"></i>
              <span>添加联系人</span>
            </znl-button>
            <znl-button
              type="default"
              @click="onDelete"
              v-if="hasRes('Delete')"
            >
              <i class="iconfont icon-d"></i>
              <span>删除联系人</span>
            </znl-button>
          </div>
        </znl-gridmodule>
      </el-tab-pane>

      <!-- 出库记录 -->
      <el-tab-pane
        :label="tabTitleStkout"
        name="StkoutHistory"
        v-if="hasRight('PastStkOut')"
      >
        <stk-out-details
          ref="StkoutHistory"
          :is-operate="false"
          :init-data="false"
          :searchModel="stkoutSearchModel"
          v-if="stkoutTableInit"
          @set-total-count="
            (cnt) => {
              tabData.stkoutCount = cnt;
            }
          "
        ></stk-out-details>
      </el-tab-pane>

      <!-- 询价记录 -->
      <el-tab-pane
        :label="tabTitleInquire"
        name="InquireHistory"
        v-if="hasRight('PastQuote')"
      >
        <znl-hisofferpricebymodel
          ref="InquireHistory"
          :is-fields-search="true"
          :init-data="false"
          :searchModel="Hisofferprice"
          v-if="inquireTableInit"
          @set-total-count="
            (cnt) => {
              tabData.inquireCount = cnt;
            }
          "
        ></znl-hisofferpricebymodel>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ZnlHisofferpricebymodel from "@c_modules/Ord/HisOfferPriceByModel";
import StkOutDetails from "@c_modules/Stk/StkOutDetails";

const CONFIG = {
  configUrl: "CrmCompanyContacts/GetConfig",
  getBOMCountUrl: "HisOfferPrice/GetCount",
  getStkoutCountUrl: "StkOutByModel/GetCount",
};

export default {
  name: "customer_bottom_tabs",
  mixins: [getCommonDataMixin],
  components: {
    ZnlHisofferpricebymodel,
    StkOutDetails,
  },

  props: {
    isAdd: Boolean,
    CCompanyID: String,
    CompanyName: String,
    contactList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    customerResources: {
      type: Array,
      default: () => {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isAdd: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  data() {
    return {
      columns: [],
      dialogLoading: false,
      pageData: [],
      activeName: "contactInfo",
      crmSuppliersSearchModel: {},
      editRowIndex: -1,
      itemSource: [],

      inquireTableInit: false,
      stkoutTableInit: false,

      stkoutSearchModel: {},
      Hisofferprice: {}, // 报价记录（按客户）

      tabData: {
        contactCount: 0,
        inquireCount: 0,
        stkoutCount: 0,
      },
    };
  },

  methods: {
    async onInit() {
      _.each(this.contactList, (item) => {
        this.itemSource.push(item);
      });
      await this.initContact();
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      this.$post(pageUrl, { PageCode: "VCrmCustomers" }, (data) => {
        this.pageData = data;
      });
    },

    async initContact() {
      let config = await this.isSaveStorage(
        "CrmCompanyContacts",
        CONFIG.configURL
      );
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);

      if (
        _.find(columns, function (column) {
          return column.binding === "GenderName";
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === "GenderName";
          }),
          {
            dataMap: [
              { key: "1", value: "男" },
              { key: "0", value: "女" },
            ],
            showDropDown: true,
          }
        );
      }
      // 数据来源
      let colDataSource = _.find(columns, (column) => {
        return column.binding === "DataSource";
      });
      if (colDataSource) {
        _.extend(colDataSource, {
          dataMap: this.selsectOptionsDemandSources,
          showDropDown: true,
        });
      }

      this.defaultConfig = config;
      this.columns = columns;
      return true;
    },

    onAdd() {
      this.$refs.flexGrid.addRow(true);
    },

    onDelete() {
      _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
        _.each(this.contactList, (item, i) => {
          if (_.isEqual(item, data)) {
            this.contactList.splice(i, 1);
            return;
          }
        });
      });
    },

    hasRight(code) {
      return _.some(this.pageData, (page) => page.Code === code);
    },

    onTabClick() {
      this.lineSearch();
      if (!this.isAdd) {
      }
    },

    lineSearch() {
      switch (this.activeName) {
        case "StkoutHistory":
          if (!this.stkoutTableInit) {
            this.stkoutTableInit = true;
          }
          this.stkoutSearchModel = {
            CustomerName: this.CompanyName,
            CustomerGUID: this.CCompanyID,
            MatchOperation: "Equal",
          };
          this.$nextTick(() => {
            !this.isAdd &&
              this.$refs.StkoutHistory.onHeadSearch(this.stkoutSearchModel);
          });
          break;

        case "InquireHistory":
          if (!this.inquireTableInit) {
            this.inquireTableInit = true;
          }
          this.Hisofferprice = {
            CustomerName: this.CompanyName,
            CustomerGUID: this.CompanyName,
          };
          this.$nextTick(() => {
            !this.isAdd &&
              this.$refs.InquireHistory.onHeadSearch(this.Hisofferprice);
          });
          break;
      }
    },

    // 统计联系人总数
    getContactCount() {
      this.tabData.contactCount = this.contactList || 0;
    },
    // 统计询价记录总数
    getInquireCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.inquireCount = 0;
        return true;
      }
      let fieldWhereString = JSON.stringify({
        // CustomerGUID: this.pastBomModel.CustomerNameID,
        CustomerName: this.CompanyName,
      });
      let param = { FieldWhereString: fieldWhereString };
      this.$post(CONFIG.getBOMCountUrl, param, (data) => {
        this.tabData.inquireCount = data || 0;
      });
    },
    // 统计出库记录总数
    getStkoutCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.stkoutCount = 0;
        return true;
      }
      let param = {
        CustomerGUID: this.CCompanyID,
        CustomerName: this.CompanyName,
        MatchOperation: "Equal",
      };
      this.$post(CONFIG.getStkoutCountUrl, param, (data) => {
        this.tabData.stkoutCount = data || 0;
      });
    },

    hasRes(code) {
      return _.includes(this.customerResources, code);
    },

    onShow() {
      this.activeName = "contactInfo";
      this.tabData.stkoutCount = 0;
      this.tabData.inquireCount = 0;
      this.tabData.contactCount = this.contactList.length;
      this.getInquireCount();
      this.getStkoutCount();
    },
  },

  watch: {
    visible(val) {
      if (val) {
        // 显示
        this.onShow();
      }
    },
  },

  computed: {
    tabTitleContact() {
      this.tabData.contactCount = this.contactList.length || 0;
      return "联系人记录(" + this.tabData.contactCount + ")";
    },
    tabTitleStkout() {
      return "历史销售记录(" + this.tabData.stkoutCount + ")";
    },
    tabTitleInquire() {
      return "客户询价记录(" + this.tabData.inquireCount + ")";
    },
  },
};
</script>
<style lang="scss">
.customer-edit-bottom-tabs {
  .tab-btnstyle-box > .el-tabs__header {
    background: #fff;
  }
  .operate-btns {
    padding-top: 5px;
    background-color: #f2f2f2;
    z-index: 990;
  }
}
.tabContact {
  overflow: auto !important;
}
</style>

<template>
<!-- 编辑供应商 -->
  <div>
    <znl-dialog
      :visible="visible"
      :title="dialogTitle"
      width="960px"
      height="610px"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      :close-on-click-modal="false"
      @close="onCloseDialog">
      <div class="supplier-edit-detail">
        <el-row>
          <div type="flex">
            <el-row :gutter="24" class="row-height">
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="true"
                    title="公司名称："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.CompanyName"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple" id="div-company-label">
                  <label class="znl-input-title" style="height: 30px; line-height: 30px; width: 60px; margin-top:2px;">标签：</label>
                  <div class="znl-labels">
                    <div class="company-label-box" :class="labelBoxClassName">
                  <span class="company-label-item el-tag el-tag--mini" v-for="item in SuppliersModel.LabelList" :key="item.LID">
                    {{ item.LabelName }}({{ item.AssignedCount }})
                  </span>
                      <div v-if="showMoreLabel" class="more-label-flag">
                        <!-- <i class="el-icon-more"></i> -->
                        <i class="iconfont icon-table_unfold_n1"></i>
                      </div>
                    </div>
                    <znl-button :disabled="isAdd" type="default" @click="settingLabel" class="set-label-btn">
                      <i class="iconfont icon-add_btn_ic"></i>
                      <span>设置标签</span>
                    </znl-button>
                  </div>
                  <!-- <znl-button :disabled="isAdd" @click="settingLabel" type="text">
                    <span class="znl-table-btn-success">{{SuppliersModel.Labels}}</span>
                  </znl-button> -->
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="公司编码："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.SupplierCode"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="跟进人："
                    :multiple="true"
                    :tags-clear="false"
                    :is-must-fill="false"
                    :clearable="true"
                    @change="onChangeFollowUser"
                    :select-options="selectOptionsAllUserURL"
                    v-model="BuyEmpID"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="货源渠道："
                    :is-must-fill="IsVerifyCompanyFile"
                    :clearable="true"
                    :select-options="selectOptionsGoodsSource"
                    v-model="SuppliersModel.GoodsSource"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="数据来源："
                    :is-must-fill="IsVerifyCompanyFile"
                    :clearable="true"
                    :select-options="selsectOptionsDemandSources"
                    v-model="SuppliersModel.DataSource"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="传真："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.Fax"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="公司网址："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.Website"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="所属行业："
                    :is-must-fill="IsVerifyCompanyFile"
                    :clearable="true"
                    :select-options="selectOptionsIndustry"
                    v-model="SuppliersModel.Industry"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="目标市场："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.TargetMarket"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="付款方式："
                    :is-must-fill="false"
                    :clearable="true"
                    :select-options="selectOptionsPaymentTypeOptions"
                    v-model="SuppliersModel.PaymentType"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="币种："
                    :is-must-fill="false"
                    :clearable="true"
                    :select-options="selectOptionsCurrencyCode"
                    v-model="SuppliersModel.CurrencyCode"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="税率："
                    :is-must-fill="false"
                    :clearable="true"
                    :select-options="selectOptionsTaxRate"
                    v-model="SuppliersModel.TaxRate"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    layout="left"
                    form-type="input"
                    width="100%"
                    placeholder
                    popper-class="popperClass"
                    title="税号："
                    :is-must-fill="false"
                    :clearable="true"
                    v-model="SuppliersModel.TaxID"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="银行帐号："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.BankAccount"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="银行名称："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.BankName"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="开票信息："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.BillContent"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="主营产品："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.MainProducts"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="IsVerifyCompanyFile"
                    title="公司地址："
                    :clearable="true"
                    width="100%"
                    v-model="SuppliersModel.Address"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="货运地址："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.DeliveryAddress"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="row-height">
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <znl-input
                    :border="true"
                    title-width="60px"
                    form-type="input"
                    placeholder=" "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="备注："
                    width="100%"
                    :clearable="true"
                    v-model="SuppliersModel.Remark"
                    type="text"
                  ></znl-input>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <el-row style="height:250px;">
          <bottom-tabs
            ref="contactList"
            :is-add="isAdd"
            v-if="showBottomTabs"
            :contact-list="itemSource"
            :CCompanyID="SuppliersModel.CCompanyID"
            :CompanyName="SuppliersModel.CompanyName"
            :supplier-resources="resources"
          ></bottom-tabs>
        </el-row>
      </div>
      <el-row slot="footer">
        <el-button type="default" :disabled="loading" @click="onCancel" class="znl-btn-auxiliary">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          class="znl-btn-main"
          v-if="hasRight('Add') || hasRight('Edit')"
          @click="onSubmit"
        >{{loading?'保存中':'保存'}}</el-button>
      </el-row>
    </znl-dialog>


    <supplier-label-edit
      :company-id="SuppliersModel.CompanyName"
      :company-name="SuppliersModel.CompanyName"
      :visible="showSupplierLabelEdit"
      @close="onSetLabelClose">
    </supplier-label-edit>
  </div>


</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { dateTimeFormat } from '@scripts/methods/common'
import bottomTabs from '@c_modules/Crm/CrmSupplierEditBottomTabs'

import SupplierLabelEdit from "./SupplierLabelEdit";
import {isClient} from "../../../lib/runtime";

const CONFIG = {
  addURL: 'CrmSuppliers/DoAdd',
  configURL: 'CrmCompanyContacts/GetConfig',
  updateURL: 'CrmSuppliers/DoEdit',
  getInfoUrl: 'CrmSuppliers/GetInfo',
  getLabelsUrl: 'SupplierLabel/GetSupplierLabels'
}
export default {
  name: 'CrmSuppliersAdd',
  config: CONFIG,
  mixins: [propSyncMixin, getCommonDataMixin, dateTimeFormat],
  components: {
    bottomTabs,
    SupplierLabelEdit
  },
  computed: {
    dialogTitle () {
      return this.isAdd ? '新增供应商' : '编辑供应商'
    }
  },
  data () {
    return {
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      itemSource: [],
      columns: [],
      dialogLoading: false,
      showBottomTabs: false,
      SuppliersModel: {
        CCompanyID: '',
        CompanyName: '',
        ShortName: '',
        CompanyCode: '',
        CompanyNamePYFirst: '',
        MainProducts: '',
        Industry: '',
        GoodsSource: '',
        TargetMarket: '',
        BankAccount: '',
        BankName: '',
        Fax: '',
        WebSite: '',
        Website: '',
        Address: '',
        DeliveryAddress: '',
        Address2: '',
        Address3: '',
        Remark: '',
        DataSource: '',
        FollowUserID: '',
        FollowUserName: '',
        ProtectUserID: '',
        ProtectUserName: '',
        CurrencyCode: 'RMB',
        TaxRate: '',
        PaymentType: '',
        BillContent: '',
        SupplierCode: '',
        CompanyType: 2,
        ContactList: [],
        Labels: '<设置标签>',
        LabelList: []
      },
      bottomActiveName: 'CrmCompanyContacts',
      editRowIndex: -1,
      dialogShow: false,
      companyVisible: false,
      isAdd: true,
      BuyEmpID: [],
      refreshSupplierList: false,
      refreshLabel: false,
      labelBoxClassName: 'label-item-ex',
      showMoreLabel: false,
      showSupplierLabelEdit: false
    }
  },
  props: {
    loading: Boolean,
    visible: {
      type: Boolean,
      defaule: false
    },
    IsVerifyCompanyFile: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Array,
      defaule: () => {
        return []
      }
    }
  },

  created () {
  },
  methods: {
    onInit: async function (config) {
      this.dialogLoading = true
      if (this.$store.state.paramOrder && this.$store.state.paramOrder.CCompanyID) {
        let companyGuid = this.$store.state.paramOrder.CCompanyID
        let order = {}
        this.$store.commit('setParamOrder', order)
        await this.loadEditData(companyGuid)
      }
      this.showBottomTabs = true
      this.dialogLoading = false
    },

    async loadEditData (val) {
      this.isAdd = false
      let data = await this.$post(CONFIG.getInfoUrl, { CCompanyID: val })
      _.extend(this.SuppliersModel, data)
      // 联系人生日
      if (data && data.ContactList && data.ContactList.length > 0) {
        _.each(data.ContactList, contact => {
          contact.Birthday = this.formatDate(contact.Birthday, 'yyyy-MM-dd')
        })
      }
      // 跟进人
      if (data.FollowUserID && data.FollowUserID !== '') {
        this.BuyEmpID = []
        let userids = data.FollowUserID.split(',')
        _.each(userids, uid => {
          this.BuyEmpID.push(parseInt(uid))
        })
      }
      this.itemSource = data.ContactList
      await this.getLabels()
    },
    checkData () {
      let ispass = true
      if (this.SuppliersModel.CompanyName === '') {
        ispass = false
        this.$message({ message: '请输入公司名称', type: 'error' })
      }

      let buyEmpUser = []
      _.each(this.BuyEmpID, item => {
        let info = _.find(this.selectOptionsAllUserURL, info => {
          return info.key === item
        })
        if (this.hasValue(info) && this.hasValue(info.value)) {
          buyEmpUser.push(info.value)
        }
      })
      let userIDs = this.BuyEmpID.join(',')
      let userNames = buyEmpUser.join(',')
      this.SuppliersModel.FollowUserID = userIDs
      this.SuppliersModel.FollowUserName = userNames
      if (this.IsVerifyCompanyFile) {
        if (this.SuppliersModel.GoodsSource === '') {
          ispass = false
          this.$message({ message: '请选择货源渠道', type: 'error' })
        } else if (this.SuppliersModel.DataSource === '') {
          ispass = false
          this.$message({ message: '请选择数据来源', type: 'error' })
        } else if (this.SuppliersModel.Industry === '') {
          ispass = false
          this.$message({ message: '请选择所属行业', type: 'error' })
        } else if (this.SuppliersModel.Address === '') {
          ispass = false
          this.$message({ message: '请输入公司地址', type: 'error' })
        }
      }

      return ispass
    },
    onChangeProtectUser (val) {
      if (val) {
        this.SuppliersModel.ProtectUserName = val.value
      }
    },
    onChangeFollowUser (val) {
      this.BuyEmpID = val
    },

    hasRight(code) {
      return _.includes(this.resources, code)
    },

    onSubmit () {
      this.SuppliersModel.ContactList = this.$refs.contactList.itemSource
      if (this.checkData() === false) {
        return false
      }
      let timeStr = /^(\d{4})-(\d{2})-(\d{2})$/
      let istime = true
      let isNullName = true
      _.some(this.SuppliersModel.ContactList, item => {
        if (item.Birthday && !timeStr.test(item.Birthday)) {
          istime = false
        }
        if (!item.ContactName) {
          isNullName = false
        }
      })
      if (!istime) {
        return this.$message({
          message: '联系人中出生日期格式错误',
          type: 'error'
        })
      }
      if (!isNullName) {
        return this.$message({ message: '联系人名称不能为空数据', type: 'error' })
      }
      var submitUrl = this.isAdd ? CONFIG.addURL : CONFIG.updateURL
      this.$post(submitUrl, this.SuppliersModel, (data, logic) => {
        let flag = true
        console.log(data, logic)
        if (data == null) {
          if (logic.code !== 200) {
            this.p_dialogVisible = false
            this.$message({ message: logic.msg || '保存失败', type: 'error' })
            flag = false
          }
        }
        if (flag) {
          this.p_dialogVisible = false
          this.$message({ message: '保存成功', type: 'success' })
          this.BuyEmpID = []
          this.$emit('confirm', this.refreshSupplierList, this.refreshLabel)
          this.onCloseDialog()
        }
      })
    },

    onCancel () {
      this.p_dialogVisible = false
      this.onCloseDialog()
    },
    SearchCompany () {
      this.InTypes = '1,3' // 客户和供应商客户
      this.companyVisible = true
    },
    companyChecked (item) {
      this.SuppliersModel.CCompanyID = item.CCompanyID
      this.SuppliersModel.CompanyName = item.CompanyName
      this.SuppliersModel.DeliveryAddress = item.Address
    },
    onDeletedRows () {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
      this.SuppliersModel.ContactList = this.itemSource
    },

    onCloseDialog (isRefresh) {
      if (isRefresh !== undefined) {
        this.refreshSupplierList = isRefresh
      }
      // window.removeEventListener('onLabelSet')
      this.$emit('close', this.refreshSupplierList, this.refreshLabel)
    },

    // 设置标签
    settingLabel () {
      let param = {
        CompanyName: this.SuppliersModel.CompanyName,
        CompanyGuid: this.SuppliersModel.CCompanyID,
        EventElementId: 'div-company-label'
      }

      if(isClient()) {
        this.$nextTick(() => {
          this.setLabel(param)
        })

        let ele = document.getElementById(param.EventElementId)
        ele.addEventListener('onLabelSet', (e) => {
          this.refreshSupplierList = true
          this.refreshLabel = true
          this.getLabels()
        }, false)
      } else {

        this.showSupplierLabelEdit = true;
      }

    },

    onSetLabelClose (labels, isChange, labelChange) {
      this.labelChange = labelChange
      this.showSupplierLabelEdit = false
      this.SuppliersModel.LabelList = labels
      this.$nextTick(() => {
        this.setLabelBoxWidth()
      })
    },

    // 获取标签
    async getLabels () {
      let param = { AssignTo: this.SuppliersModel.CompanyName, IsSumLabelUsed: true }
      let data = await this.$post(CONFIG.getLabelsUrl, param)
      if (data && data.length > 0) {
        this.SuppliersModel.LabelList = data
      } else {
        this.SuppliersModel.LabelList = []
      }
      this.$nextTick(function(){
        this.setLabelBoxWidth()
      })
    },

    // 计算标签总宽度，如果超出，则隐藏，并显示[更多]按钮
    setLabelBoxWidth () {
      let items = document.getElementsByClassName('company-label-item')
      let width = 0
      let maxWidth = 315
      _.each(items, item => {
        width += item.offsetWidth
      })
      if (width > maxWidth) {
        this.labelBoxClassName = 'label-item-ex'
        this.showMoreLabel = true
      } else {
        this.labelBoxClassName = ''
        this.showMoreLabel = false
      }
    }
  },

  async mounted () {
    await this.onInit()
  }
}
</script>

<style lang="scss" scoped>
.supplier-edit-detail {
  .row-height {
    padding-top: 3px;
  }

  .znl-labels{
    width: 400px;
    position: absolute;
    margin-left: 60px;

    .set-label-btn{
      margin-left: 2px;
    }

    .company-label-box{
      max-width: 315px;
      background: #fff;
      z-index: 999;
      float: left;
      position: relative;
      margin-top: -2px;
      padding: 2px 2px 2px 4px;
      &:hover{
        height: auto;
        padding: 1px 1px 1px 3px;
        border: 1px solid #e8e6d8;
        border-radius: 5px;
        box-shadow: 0px 0px 4px #888888;
        padding-bottom: 4px;
        .more-label-flag{
          display: none;
        }
      }
    }
    .label-item-ex{
      height: 30px;
      overflow: hidden;
    }
    .more-label-flag{
      float: left;
      position: absolute;
      right: 2px;
      font-size: 14px;
      top: 3px;
    }
  }
  .company-label-item {
    margin-right: 5px;
    &:hover{
      box-shadow: 0px 0px 4px #888888;
    }
  }
}

</style>

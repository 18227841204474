<template>
  <!-- 编辑客户 -->
  <znl-dialog
    :visible="visible"
    :title="title"
    width="960px"
    height="680px"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    element-loading-text="数据加载中,请稍后..."
    :is-footer-show="false"
    @close="onCancel"
  >
    <div class="customer-edit-detail">
      <el-row :gutter="24" class="row-height">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="true"
              title="公司名称："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.CompanyName"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="跟进人："
              :multiple="true"
              :tags-clear="false"
              :is-must-fill="false"
              :clearable="true"
              @change="onChangeFollowUser"
              :select-options="selectOptionsAllUserURL"
              v-model="BuyEmpID"
            ></znl-input>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="grid-content bg-purple" id="div-company-label">
            <label
              class="znl-input-title"
              style="
                height: 30px;
                line-height: 30px;
                width: 60px;
                margin-top: 2px;
              "
              >标签：</label
            >
            <div class="znl-labels">
              <div class="company-label-box" :class="labelBoxClassName">
                <span
                  class="company-label-item el-tag el-tag--mini"
                  v-for="item in CustomerModel.LabelList"
                  :key="item.LID"
                >
                  {{ item.LabelName }}
                  <!-- ({{ item.AssignedCount }}) -->
                </span>
                <div v-if="showMoreLabel" class="more-label-flag">
                  <i class="iconfont icon-table_unfold_n1"></i>
                </div>
              </div>
              <znl-button
                :disabled="isAdd"
                type="default"
                @click="onSetLabel"
                class="set-label-btn"
              >
                <i class="iconfont icon-add_btn_ic"></i>
                <span>设置标签</span>
              </znl-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="row-height">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="所属行业："
              :is-must-fill="IsVerifyCompanyFile"
              :clearable="true"
              :select-options="selectOptionsIndustry"
              v-model="CustomerModel.Industry"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="货源渠道："
              :is-must-fill="IsVerifyCompanyFile"
              :clearable="true"
              :select-options="selectOptionsGoodsSource"
              v-model="CustomerModel.GoodsSource"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              placeholder="请选择"
              form-type="select"
              width="100%"
              popper-class="popperClass"
              title="所处阶段："
              :clearable="true"
              :select-options="selectOptionsCloseClass"
              v-model="CustomerModel.CloseClass"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="公司网址："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.Website"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="6">
          <znl-input
            :border="true"
            title-width="35px"
            form-type="select"
            width="100%"
            placeholder="请选择"
            title="来源："
            :is-must-fill="IsVerifyCompanyFile"
            :clearable="true"
            :select-options="selsectOptionsDemandSources"
            v-model="CustomerModel.DataSource"
          ></znl-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="row-height">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="select"
              width="100%"
              placeholder="请选择"
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="客户热度："
              :clearable="true"
              :select-options="selectOptions.CustomerHead"
              v-model="CustomerModel.HeatDegree"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="35px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="税号："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.TaxID"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="目标市场："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.TargetMarket"
              type="text"
            ></znl-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="主营产品："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.MainProducts"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="35px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :is-must-fill="false"
              title="传真："
              width="100%"
              :clearable="true"
              v-model="CustomerModel.Fax"
              type="text"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="row-height">
        <el-col :span="12">
          <el-row :gutter="24">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="银行帐号："
                  width="100%"
                  :clearable="true"
                  v-model="CustomerModel.BankAccount"
                  type="text"
                ></znl-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="银行名称："
                  width="100%"
                  :clearable="true"
                  v-model="CustomerModel.BankName"
                  type="text"
                ></znl-input>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="付款方式："
              :is-must-fill="false"
              :clearable="true"
              :select-options="selectOptionsPaymentTypeOptions"
              v-model="CustomerModel.PaymentType"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="35px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="币种："
              :is-must-fill="false"
              :clearable="true"
              :select-options="selectOptionsCurrencyCode"
              v-model="CustomerModel.CurrencyCode"
            ></znl-input>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="35px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="税率："
              :is-must-fill="false"
              :clearable="true"
              :select-options="selectOptionsTaxRate"
              v-model="CustomerModel.TaxRate"
            ></znl-input>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="24" class="row-height">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-row>
              <div class="grid-content bg-purple">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="IsVerifyCompanyFile"
                  title="公司地址："
                  :clearable="true"
                  width="100%"
                  v-model="CustomerModel.Address"
                  type="text"
                ></znl-input>
              </div>
            </el-row>
            <el-row>
              <div class="grid-content bg-purple-dark">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="货运地址："
                  width="100%"
                  :clearable="true"
                  v-model="CustomerModel.DeliveryAddress"
                  type="text"
                ></znl-input>
              </div>
            </el-row>
            <el-row>
              <div class="grid-content bg-purple-dark">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="公司地址2："
                  width="100%"
                  :clearable="true"
                  v-model="CustomerModel.Address2"
                  type="text"
                ></znl-input>
              </div>
            </el-row>
            <el-row>
              <div class="grid-content bg-purple-dark">
                <znl-input
                  :border="true"
                  title-width="60px"
                  form-type="input"
                  placeholder=" "
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="公司地址3："
                  width="100%"
                  :clearable="true"
                  v-model="CustomerModel.Address3"
                  type="text"
                ></znl-input>
              </div>
            </el-row>
          </div>
        </el-col>

        <el-col :span="12">
          <el-row class="row-remark">
            <div class="grid-content bg-purple-dark">
              <znl-input
                title="备注："
                title-width="60px"
                width="100%"
                layout="left"
                v-model="CustomerModel.Remark"
                type="textarea"
                :rows="3"
                :clearable="true"
                placeholder
              ></znl-input>
            </div>
          </el-row>
          <el-row class="row-remark" style="margin-top: 8px">
            <div class="grid-content bg-purple-dark">
              <znl-input
                title-width="60px"
                title="开票信息："
                width="100%"
                v-model="CustomerModel.BillContent"
                type="textarea"
                :clearable="true"
                :rows="2"
                placeholder
              ></znl-input>
            </div>
          </el-row>
        </el-col>
      </el-row>

      <!-- 页签 -->
      <el-row style="height: 290px">
        <bottom-tabs
          ref="contactList"
          :is-add="isAdd"
          v-if="showBottomTabs"
          :visible="visible"
          :contact-list="CustomerModel.ContactList"
          :CCompanyID="ccompanyid"
          :CompanyName="companyName"
          :customer-resources="resources"
        ></bottom-tabs>
      </el-row>

      <el-row class="foot-buttons">
        <el-button
          type="default"
          :disabled="loading"
          @click="onCancel"
          class="znl-btn-auxiliary"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="loading"
          class="znl-btn-main"
          @click="onSubmit"
          >{{ loading ? "保存中" : "保存" }}</el-button
        >
      </el-row>
    </div>

    <customer-label-set
      :company-id="ccompanyid"
      :company-name="companyName"
      :visible="showCustomerLabelSet"
      @close="onSetLabelClose"
    ></customer-label-set>
  </znl-dialog>
</template>

<script>
import { mixin as propSyncMixin } from "@scripts/mixins/propSync";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { dateTimeFormat, itemsHandle } from "@scripts/methods/common";
import bottomTabs from "@c_modules/Crm/CrmCustomerEditBottomTabs";
import customerLabelSet from "@c_modules/Crm/CustomerLabelSet";

const Config = {
  addURL: "CrmCustomers/DoAdd",
  configURL: "CrmCompanyContacts/GetConfig",
  updateURL: "CrmCustomers/DoEdit",
  searchContactsURL: "CrmCompanyContacts/Search",
  getInfoURL: "CrmCustomers/GetInfo",
  DictItemURL: "DictItem/GetItemValue",
  basDictItemURL: "BASDictItem/GetItems", // 客户热度
  getLabelsURL: "CrmCustomerLabel/GetLabels",
};
export default {
  name: "CrmCustomersAdd",
  mixins: [propSyncMixin, getCommonDataMixin, dateTimeFormat, itemsHandle],
  components: {
    bottomTabs,
    customerLabelSet,
  },
  data() {
    return {
      fromBtns: [
        {
          name: "新增",
          isShow: true,
          iconName: "icon-add_btn_ic",
          click: () => {
            this.$refs.flexGrid.addRow(true);
          },
        },
        {
          name: "删除",
          isShow: true,
          iconName: "icon-delete_btn_ic",
          click: () => {
            this.$refs.flexGrid.deleteCheckedRows();
          },
        },
      ],
      BuyEmpID: [],
      columns: [],
      binding: [],
      itemSource: [],
      pageSize: 1000,
      pageIndex: 1,
      totalCount: 0,
      CustomerModel: {
        CompanyID: "",
        CCompanyID: "",
        CompanyName: "",
        ShortName: "",
        CompanyCode: "",
        CompanyNamePYFirst: "",
        MainProducts: "",
        Industry: "",
        GoodsSource: "",
        TargetMarket: "",
        BankAccount: "",
        BankName: "",
        Fax: "",
        WebSite: "",
        Address: "",
        DeliveryAddress: "",
        Address2: "",
        Address3: "",
        Remark: "",
        DataSource: "",
        FollowUserID: "",
        FollowUserName: "",
        ProtectUserID: "",
        ProtectUserName: "",
        CurrencyCode: "RMB",
        TaxRate: "",
        TaxID: "",
        PaymentType: "",
        BillContent: "",
        SupplierCode: "",
        CompanyType: 1,
        ContactList: [],
        HeatDegree: "",
        CloseClass: null,
        LabelList: [],
      },
      defaultCustomerModel: {
        CompanyID: "",
        CCompanyID: "",
        CompanyName: "",
        ShortName: "",
        CompanyCode: "",
        CompanyNamePYFirst: "",
        MainProducts: "",
        Industry: "",
        GoodsSource: "",
        TargetMarket: "",
        BankAccount: "",
        BankName: "",
        Fax: "",
        WebSite: "",
        Website: "",
        Address: "",
        DeliveryAddress: "",
        Address2: "",
        Address3: "",
        Remark: "",
        DataSource: "",
        FollowUserID: [],
        FollowUserName: "",
        ProtectUserID: "",
        ProtectUserName: "",
        CurrencyCode: "RMB",
        TaxRate: "",
        TaxID: "",
        PaymentType: "",
        BillContent: "",
        SupplierCode: "",
        ContactList: [],
        HeatDegree: "",
        CloseClass: null,
        LabelList: [],
      },
      selectOptions: {
        CustomerHead: [], // 客户热度
      },
      labelBoxClassName: "",
      editRowIndex: -1,
      companyVisible: false,
      showBottomTabs: true,
      dialogLoading: false,
      showMoreLabel: false,
      showCustomerLabelSet: false, // 是否显示设置标签对话框
      labelChange: {},
    };
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    isAdd: {
      type: Boolean,
      default: true,
    },
    IsVerifyCompanyFile: {
      type: Boolean,
      default: false,
    },
    ccompanyid: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    resources: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        // 显示
        if (
          !this.hasValue(this.selectOptions.CustomerHead) ||
          this.selectOptions.CustomerHead.length === 0
        ) {
          this.getBASDictItems();
        }
        if (this.isAdd) {
          // 新增
          this.clearData();
          // 设置默认跟进人为当前账户
          this.BuyEmpID.push(this.$store.state.accountInfo.UserID);
        } else {
          // 编辑
          this.getInfo();
        }
      } else {
        // 隐藏
        setTimeout(() => {
          if (!this.visible) {
            this.clearData();
          }
        }, 1000);
      }
    },
  },

  methods: {
    onChangeFollowUser(val) {
      this.BuyEmpID = val;
    },

    async getInfo() {
      this.dialogLoading = true;
      if (!this.isAdd && this.ccompanyid && this.ccompanyid !== "") {
        this.$post(
          Config.getInfoURL,
          { CCompanyID: this.ccompanyid },
          (data) => {
            // 获取标签
            this.getLabels();

            this.CustomerModel = _.extend({}, this.defaultCustomerModel, data);
            // 格式化联系人
            this.removeTime(data.ContactList, ["Birthday"]);
            this.itemSource = data.ContactList;
            this.CustomerModel.ContactList = data.ContactList;
            // 设置跟进人
            this.BuyEmpID = [];
            if (data.FollowUserID) {
              let userids = data.FollowUserID.split(",");
              _.each(userids, (uid) => {
                if (uid) {
                  this.BuyEmpID.push(parseInt(uid));
                }
              });
            }

            this.dialogLoading = false;
          }
        );
      }
    },

    async getBASDictItems() {
      let data = await this.$post(Config.basDictItemURL, {
        DictCode: "CustomerHeat",
      });
      if (data && data.length > 0) {
        this.selectOptions.CustomerHead = data.map((m) => {
          return {
            key: m.ItemValue,
            value: m.ItemValue,
          };
        });
      } else {
        this.selectOptions.CustomerHead = [];
      }
    },

    checkData() {
      let ispass = true;
      if (this.CustomerModel.CompanyName === "") {
        ispass = false;
        this.$message({ message: "请输入公司名称", type: "error" });
      }

      let buyEmpUser = [];
      _.each(this.BuyEmpID, (item) => {
        let info = _.find(this.selectOptionsAllUserURL, (info) => {
          return info.key === item;
        });
        if (this.hasValue(info) && this.hasValue(info.value)) {
          buyEmpUser.push(info.value);
        }
      });
      let userIDs = this.BuyEmpID.join(",");
      let userNames = buyEmpUser.join(",");
      this.CustomerModel.FollowUserID = userIDs;
      this.CustomerModel.FollowUserName = userNames;
      if (this.IsVerifyCompanyFile) {
        if (this.CustomerModel.Industry === "") {
          ispass = false;
          this.$message({ message: "请选择所属行业", type: "error" });
        } else if (this.CustomerModel.GoodsSource === "") {
          ispass = false;
          this.$message({ message: "请选择货源渠道", type: "error" });
        } else if (this.CustomerModel.DataSource === "") {
          ispass = false;
          this.$message({ message: "请选择来源", type: "error" });
        } else if (this.CustomerModel.Address === "") {
          ispass = false;
          this.$message({ message: "请输入公司地址", type: "error" });
        }
      }

      return ispass;
    },

    onSubmit() {
      let vm = this;
      if (this.checkData() === false) {
        return false;
      }
      let timeStr = /^(\d{4})-(\d{2})-(\d{2})$/;

      let istime = true;
      let isNullName = true;

      _.some(vm.CustomerModel.ContactList, (item) => {
        if (item.Birthday && !timeStr.test(item.Birthday)) {
          istime = false;
        }
        if (!item.ContactName) {
          isNullName = false;
        }
      });
      if (!istime) {
        return vm.$message({
          message: "联系人中出生日期格式错误",
          type: "error",
        });
      }
      if (!isNullName) {
        return vm.$message({
          message: "联系人名称不能为空数据",
          type: "error",
        });
      }

      var submitUrl = vm.isAdd ? Config.addURL : Config.updateURL;
      vm.$post(submitUrl, vm.CustomerModel, (data, logic) => {
        if (data) {
          vm.$message({ message: "保存成功", type: "success" });
          this.BuyEmpID = [];
          vm.$emit("confirm", vm.labelChange);
        } else if (logic.code !== 200) {
          vm.$message({ message: logic.msg || "保存失败", type: "error" });
        }
      });
    },

    onCancel() {
      this.$emit("close", this.labelChange);
    },

    clearData() {
      this.BuyEmpID = [];
      this.CustomerModel = _.extend({}, this.defaultCustomerModel);
      this.itemSource = [];
      this.CustomerModel.ContactList = [];
    },

    async getLabels() {
      if (!this.hasValue(this.ccompanyid)) {
        return false;
      }
      let data = await this.$post(Config.getLabelsURL, {
        AssignToID: this.ccompanyid,
      });
      this.CustomerModel.LabelList = data;
      this.$nextTick(() => {
        this.setLabelBoxWidth();
      });
    },

    // 计算标签总宽度，如果超出，则隐藏，并显示[更多]按钮
    setLabelBoxWidth() {
      let items = document.getElementsByClassName("company-label-item");
      let width = 0;
      let maxWidth = 315;
      _.each(items, (item) => {
        width += item.offsetWidth;
      });
      if (width > maxWidth) {
        this.labelBoxClassName = "label-item-ex";
        this.showMoreLabel = true;
      } else {
        this.labelBoxClassName = "";
        this.showMoreLabel = false;
      }
    },

    onSetLabel() {
      this.showCustomerLabelSet = true;
    },

    onSetLabelClose(labels, isChange, labelChange) {
      this.labelChange = labelChange;
      this.showCustomerLabelSet = false;
      this.CustomerModel.LabelList = labels;
      this.$nextTick(() => {
        this.setLabelBoxWidth();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.row-height {
  padding-top: 3px;
}
.customer-edit-detail {
  .row-remark {
    min-height: 60px;
    margin-top: 2px;
  }
  .foot-buttons {
    float: right;
    margin-top: 10px;
  }

  .znl-labels {
    width: 400px;
    position: absolute;
    margin-left: 60px;

    .set-label-btn {
      margin-left: 2px;
    }

    .company-label-box {
      max-width: 315px;
      background: #fff;
      z-index: 999;
      float: left;
      position: relative;
      margin-top: -2px;
      padding: 2px 2px 2px 4px;
      &:hover {
        height: auto;
        padding: 1px 1px 1px 3px;
        border: 1px solid #e8e6d8;
        border-radius: 5px;
        box-shadow: 0px 0px 4px #888888;
        padding-bottom: 4px;
        .more-label-flag {
          display: none;
        }
      }
    }
    .label-item-ex {
      height: 30px;
      overflow: hidden;
    }
    .more-label-flag {
      float: left;
      position: absolute;
      right: 2px;
      font-size: 14px;
      top: 3px;
    }
  }

  .company-label-item {
    margin-right: 5px;
    &:hover {
      box-shadow: 0px 0px 4px #888888;
    }
  }
}
</style>

<template>
<znl-gridmodule ref="flexGrid"
              header="汇率设置"
              gridtype="action"
              :columns="columns"
              height="100%"
              :grid-show="gridShow"
              :has-znl-actions="false"
              :action-btns="actionBtns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :is-multi-rows-check="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
               :users="$store.state.users"
              :on-init="onInit"
              :is-init="false"
              :on-refresh="onRefresh"
              :on-delete-rows="onDeleteRows"
              :on-search="onSearch"
              :on-before-add-row="onBeforeAddRow"
              :on-save-row="onSaveRows"
              :on-delete-row="onDeleteRow"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              @page-loading="d=>{$emit('page-loading', d)}"
              @selection-changed="d=>{$emit('selection-changed',d)}"
              @edit:cell-ended="onCellEdited"
              checkboxBindingKey="CompanyID"
              >
             <!-- <div slot="action-form">
                <znl-button size="mini" type="primary" @click="$refs.flexGrid.search()" class="znl-btn-mini">
                    <i class="iconfont icon-search_ic"></i>搜索</znl-button>
             </div> -->
  </znl-gridmodule >
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  PK: 'CompanyID',
  configURL: 'Rate/GetConfig',
  saveConfigURL: 'Rate/SaveConfig',
  resetConfigURL: 'Rate/ResetConfig',
  searchURL: 'Rate/Search',
  updateURL: 'Rate/Edit',
  addURL: 'Rate/Add',
  batchSaveURL: 'Rate/BatchSave',
  deleteURL: 'Rate/Del',
  mulitDeleteURL: 'Rate/MultiDel',
  CurrencyCodeURL: 'DictItem/GetItemValue',
  companyUserURL: 'CompanyAccount/GetUsableUser',
  default: null
}
export default {
  name: 'Rate',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    mixins: [znlMethodsMixin],
    config: CONFIG
  },
  data () {
    return {
      actionBtns: [

      ],
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      role: '1',
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      resourceList: [],
      gridShow: false
    }
  },
  watch: {
    selectOptionsCurrencyCode (val) {
      let CurrencyNames = _.map(val, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.each(this.columns, column => {
        if (column.binding === 'CName') {
          this.$set(column, 'dataMap', CurrencyNames)
        }
      })
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('Rate', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      this.actionBtns = [
        {
          name: '添加',
          isShow: this.hasRes('Add'),
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow()
          }
        }, {
          name: '删除',
          isShow: this.hasRes('Delete'),
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onDelete()
          }
        }
      ]
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 从字典获取 币种名称
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      this.CurrencyNames = CurrencyNames
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CName'
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      )

      this.columns = columns
      this.pageSize = config.PageSize || 30
      this.showAddRowRightMenu = this.hasRes('Add')
      this.showDeleteRowRightMenu = this.hasRes('Delete')
      this.showSaveRowRightMenu = this.hasRes('Add')
      this.initData && this.onBindData()
      this.role = config.Role
      CONFIG.default = config
    },
    getColumn (name) {
      let list = []
      let columns = this.$refs.flexGrid.$refs.editFlexGrid.$refs.actionFlexGrid.$refs.baseFlexGrid.checkColumns || this.columns
      _.each(columns, item => {
        if (item.visible) {
          list.push(item)
        }
      })
      for (let i = 0; i < list.length; i++) {
        if (name === list[i].name) {
          return {
            col: columns[i],
            index: i
          }
        }
      }
    },

    onCellEdited (value, oldValue, colName, grid, rowIndex, colIndex) {
      // console.log(11111, value)
      // console.log(22222, oldValue)
      // console.log(33333, colName)
      // console.log(44444, grid)
      // console.log(55555, rowIndex)
      // console.log(66666, colIndex)
      let rowData = grid.row

      let colIndexs = grid.getColumn('CName').index

      return this.$post(CONFIG.CurrencyCodeURL, { DictCode:
          'Currency',
        ItemName: value }, (data) => {
          _.map(data, item => {
          rowData.CCode = item.ItemValue
          // 获取Code
          grid.setCellData(rowIndex, colIndexs - 1, item.ItemValue)// 算
          this.$refs.flexGrid.gridItemSourceData()[rowIndex]['CCode'] = item.ItemValue
        })
        })
    },
    setCellData (r, c, v) {
      let trs = this.$el.querySelectorAll('.el-table .el-table__body-wrapper .el-table__body tbody tr')
      let containerOld = trs[r].querySelectorAll('td')[c].querySelector('.cell div')
      let containerNew = trs[r].querySelectorAll('td')[c].querySelector('.cell input')
      containerOld ? containerOld.innerText = v : containerNew.value = v
    },
    onBeforeAddRow () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK, { IsActive: 2 })
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
      })
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    onDelete () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      // console.log(rows)
      if (rows.length === 1 && rows[0]['_PKIndex'] === -1) {
        return this.itemSource.shift(rows[0])
      }
      if (_.isUndefined(rows[0])) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }

      this.$confirm('确认删除' + rows.length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // let data = this.$refs.flexGrid.transformSubmitValue(_.filter(rows, row => row[CONFIG.PK]))
        return this.$post(CONFIG.mulitDeleteURL, rows, (data) => {
          if (data > 0) {
            this.$refs.flexGrid.clearSelection()
            this.$message({ message: '删除成功', type: 'success' })
            this.onSearch()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        })
      })
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('Rate')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('Rate')
      return this.$post(CONFIG.resetConfigURL)
    },
    onSaveConfig (column) {
      localStorage.removeItem('Rate')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onDeleteRows (rows) {
      // console.log(rows)
      // if (_.filter(rows, row => row[CONFIG.PK]).length > 0) {
      return this.$post(CONFIG.mulitDeleteURL, rows, (data) => {
        if (Number(data) > 0) {
          this.$message({ message: '删除成功！', type: 'success' })
          return this.onBindData(this.pageIndex)
        } else {
          this.$message({ message: '删除失败！', type: 'error' })
          return { 'error': true }
        }
      })
      // } else {
      //   this.$message({ message: '删除成功！', type: 'success' })
      //   return true
      // }
    },
    onSaveRows (rows) {
      // let items = this.$refs.flexGrid.transformSubmitValue(rows)
      if (rows.length > 0) {
        let res = this.$post(CONFIG.batchSaveURL, rows, (data) => {
          if (data) {
            this.$message({ message: '保存成功', type: 'success' })
            // this.onSearch()
          } else {
            this.$message({ message: '保存失败', type: 'error' })
            this.onBindData(this.pageIndex)
          }
        })
        return res
      }
    }

  }
}
</script>

<style lang="scss">
</style>


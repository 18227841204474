<template>
  <div class="m-crm-supplier">
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('crmSuppliers')"
      :is-fields-search="isShowFiedsSearch"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :page-index="pageIndex"
      :page-size="pageSize"
      :search-fields="onSearch"
      :show-check="true"
      :show-title-menus="true"
      :total-count="totalCount"
      box-class="m-crm-supplier"
      checkbox-binding-key="CCompanyID"
      element-loading-text="数据加载中,请稍后..."
      gridtype="base"
      @supplier-name-click="onCustomerEdit"
      @company-tag-click="onShowLabelSet"
      @current-row-change="d=>{$emit('selection-changed',d)}"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            v-model="CompanyName"
            :border="true"
            :clearable="true"
            form-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="请输入供应商名称"
            size="mini"
            type="text"
            width="150px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            v-model="ContactInfo"
            :border="true"
            :clearable="true"
            class="ml2"
            form-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="联系人 电话 邮箱 QQ 阿里旺旺"
            size="mini"
            title
            type="text"
            width="172px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <znl-input
            v-model="FollowUserName"
            :border="true"
            :clearable="true"
            :select-options="usersOptions"
            form-type="select"
            placeholder="跟进人"
            size="mini"
            tabindex="4"
            width="80px"
          ></znl-input>

          <el-checkbox v-model="IsProtected" class="form-group" @change="onSearch()">仅显示已保护</el-checkbox>

          <znl-button
            :height="22"
            class="box-left-btn search-btn ml5"
            style-type="mac"
            type="multiple"
          >
            <znl-button-menu tip="点击右侧向下三角图标显示/隐藏表头搜索" @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <!-- <el-checkbox class="form-group" v-model="IsWithLabel" @change="onSearch()">仅在当前标签中搜索</el-checkbox> -->
        </el-row>
        <div class="el-row">
          <znl-button v-if="this.hasRes('Add')" style-type="mac" @click="onCustomerAdd()">
            <span>
              <i class="iconfont icon-add_btn_ic"></i>新增
            </span>
          </znl-button>
          <znl-button v-if="this.hasRes('Delete')" style-type="mac" @click="onDeleteRows()">
            <span>
              <i class="iconfont icon-delete_btn_ic"></i>删除
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('SetSupplier')"
            class="ml10"
            style-type="mac"
            @click="onSetCustomer()"
          >
            <span>
              <i class="iconfont icon-tag_btn_ic"></i>同时为客户
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('SettingsWidget')"
            style-type="mac"
            @click="OnSettingsWidget()"
          >
            <span>
              <i class="iconfont icon-labelling_btn"></i>设置标签
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('EditFollowUser')"
            style-type="mac"
            @click="onSetFollowUser()"
          >
            <span>
              <i class="iconfont icon-edit_btn_ic"></i>修改跟进人
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('SetProtectUser')"
            class="ml10"
            style-type="mac"
            @click="onSetProtectUser(1)"
          >
            <span>
              <i class="iconfont icon-admin_user_ic"></i>保护
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('CancelProtectUser')"
            style-type="mac"
            @click="onSetProtectUser(0)"
          >
            <span>
              <i class="iconfont icon-backout_btn_ic"></i>取消保护
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('onImport')"
            class="ml10"
            style-type="mac"
            @click="onImport()"
          >
            <span>
              <i class="iconfont icon-leading-in-wh_btn_ic"></i>导入
            </span>
          </znl-button>

          <znl-button
            v-if="this.hasRes('onExport')"
            :btns="printznlbtns"
            style-type="mac"
            @click="onExport"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>

          <znl-float-list v-if="false" :lists="moreBtns">
            <znl-button :height="22" style-type="mac">
              <i class="iconfont icon-left_nav_show_btn"></i>
              <span>更多操作</span>
            </znl-button>
          </znl-float-list>
        </div>
      </div>
    </znl-table>

    <crm-supplier-edit-dialog
      v-if="showSuppliersDialog"
      ref="CrmSupplierEditDialog"
      :IsVerifyCompanyFile="this.IsVerifyCompanyFile"
      :resources="resourceList"
      :visible="showSuppliersDialog"
      @close="onEditDialogClose"
      @confirm="onEditDialogConfirm"
    ></crm-supplier-edit-dialog>

    <CrmSetFollowUserDialog
      v-if="showCustomerFollowUserDialog"
      ref="CrmSetFollowUserDialog"
      :visible="showCustomerFollowUserDialog"
      @close="()=>{showCustomerFollowUserDialog=false}"
      @confirm="(v)=>{ showCustomerFollowUserDialog=false,
                      v&&this.onRefresh(), this.$refs.table.cleanAllCheck()}"
    ></CrmSetFollowUserDialog>

    <export-column
      v-if="exportInfos.setExportColumnVisible"
      :addColumns="exportInfos.addColumnsList"
      :addConfigColumns="exportInfos.setConfigColumns"
      :saveConfigURL="exportInfos.setSaveConfigURL"
      :title="exportInfos.title"
      :visible="exportInfos.setExportColumnVisible"
      @close="()=>{exportInfos.setExportColumnVisible=false}"
      @confirm="(v)=>{exportInfos.setExportColumnVisible=false}"
    ></export-column>

    <crm-supplier-label-edit
      :company-id="CompanyId"
      :company-name="CompanyName"
      :visible="showSupplierLabelEdit"
      @close="onSetLabelClose"></crm-supplier-label-edit>

    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>

<script>
import {mixin as getCommonDataMixin} from "~assets/scripts/methods/getCommonData";
import CrmSupplierEditDialog from "@/components/modules/Crm/CrmSupplierEdit";
import CrmSetFollowUserDialog from "@/components/modules/Crm/CrmBatchSaveFollowUser";
import CrmSupplierLabelEdit from "@/components/modules/Crm/SupplierLabelEdit"

import ExportColumn from "@c_modules/Crm/setExportColumn";
import {on} from "~assets/scripts/utils/dom";
import {exportData} from "@scripts/methods/common";
import {erpTableSetHandler} from "@scripts/methods/common";

import EnquiryView from "@c_modules/Enquiry/index"

import {isClient} from "~/lib/runtime";

const CONFIG = {
  PK: "CAutoID",
  configURL: "CrmSuppliers/GetConfig",
  saveConfigURL: "CrmSuppliers/SaveConfig",
  searchURL: "CrmSuppliers/Search",
  addURL: "CrmSuppliers/Add",
  updateURL: "CrmSuppliers/Edit",
  deleteURL: "CrmSuppliers/Del",
  multiDeleteURL: "CrmSuppliers/MultiDel",
  SetSupplierURL: "CrmCustomers/SetCompanyOrSupplier", // 设置客户和供应商
  SetProtectUserURL: "CrmCustomers/SetProtectUser", // 设置取消保护人
  contactConfigURL: "CrmCompanyContacts/GetConfig", // 联系人信息配置
  contactConfigSaveURL: "CrmCompanyContacts/SaveConfig" // 保存联系人信息配置
};
export default {
  name: "CrmSuppliers",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  config: CONFIG,
  components: {
    CrmSetFollowUserDialog,
    ExportColumn,
    CrmSupplierEditDialog,
    CrmSupplierLabelEdit,
    EnquiryView
  },
  data() {
    return {
      enquiryVisible: true,
      ZnlRegex: {
        GUID: /^[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{12}$/,
        INT: /^[1-9]{1}[0-9]*$/
      },
      dataMapSource: {
        DataSource: [
          "电话",
          "网站",
          "Email",
          "QQ",
          "阿里巴巴",
          "阿里旺旺",
          "ICO",
          "Skype",
          "MSN"
        ]
      },
      companyTypeMap: [{key: "客户", value: 3}],
      stkInquiry: {
        // 询价参数传递
        SupplierName: "",
        SupplierID: "",
        Address: "",
        Telephone: "",
        Mobile: "",
        Contact: "",
        QQ: ""
      },
      loading: false,
      isShowFiedsSearch: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      columns: [],
      defaultConfig: {},
      itemSource: [],
      searchFields: {},
      defaultSearchCondition: {
        searchFields: {}
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      CompanyId: "",
      CompanyName: "",
      showSupplierLabelEdit: false,
      FollowUserName: "",
      ContactInfo: "",
      SelectedLabel: "",
      IsWithLabel: false,
      IsProtected: false,
      usableUser: [],
      ParamJosn: [],
      ExportColumns: [],
      IsVerifyCompanyFile: false,
      resourceList: [],
      showSuppliersDialog: false,
      showCustomerFollowUserDialog: false,
      parameterConfig: [],
      ExportCol: [],
      usersOptions: [],
      printznlbtns: [
        {
          name: "设置供应商导出列",
          click: () => {
            this.showExportColumn("supplier");
          }
        },
        {
          name: "设置联系人导出列",
          click: () => {
            this.showExportColumn("contact");
          }
        }
      ],
      exportInfos: {
        title: "设置导出列",
        setExportColumnVisible: false,
        addColumnsList: [],
        setConfigColumns: {},
        setSaveConfigURL: ""
      },
      contactConfig: {
        isGet: false,
        columns: [],
        defaultConfig: []
      },
      searchCondition: {},
      moreBtns: [], // 更多操作
      isAttachLabelEvent: false,
      initEnquiryData: null
    };
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    // 标签设置
    OnSettingsWidget() {
      // 设置标签
      let row = this.$refs.table.getCheckedRows();
      if (row.length > 0) {
        console.log(row)
        this.onShowLabelSet(row[0], 1);
        this.clearSelectedRows();
      } else {
        return this.$message({message: "请选择一条数据", type: "error"});
      }
    },
    onShowLabelSet(e, type) {
      let row = type === 1 ? e : e.row;
      this.currentRow = row;
      this.CompanyId = row.CCompanyID;
      this.CompanyName = row.CompanyName;
      this.showSupplierLabelEdit = true;
    },
    onSetLabelClose(labels, isChange, labelChange = null) {
      this.showSupplierLabelEdit = false;
      if (isChange) {
        this.onRefresh(this.currentRow);
      }
      if (labelChange) {
        this.$emit("label-change", labelChange);
      }
    },

    async onInit(config) {
      let vm = this;
      if (!config) {
        config = await this.isSaveStorage("crmSuppliers", CONFIG.configURL);
      }

      // 保存时验证公司资料
      this.IsVerifyCompanyFile =
        this.getCompanySetupValue("SaveCompanyWithFullCheck") === "1";
      this.resourceList = config.ResourceList.map(item => item.Code);

      let tempColumns = config.ColumnConfigs;
      this.ExportColumns = config.ColumnConfigs;

      // 币种
      let currencyNames = _.map(
        this.selectOptionsCurrencyCode,
        (item, index) => {
          return {key: item.value, value: item.key};
        }
      );
      let colIndex = _.findIndex(
        tempColumns,
        column => column.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(tempColumns[colIndex], {
          type: "select",
          width: 55,
          options: currencyNames
        });
      }

      // 类型
      colIndex = _.findIndex(
        tempColumns,
        col => col.BindField === "CompanyType"
      );
      if (colIndex > -1) {
        _.extend(tempColumns[colIndex], {
          type: "select",
          width: 50,
          options: this.companyTypeMap
        });
      }

      // 跟进人
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
        obj,
        index
      ) {
        return {key: obj.value, value: obj.value};
      });
      this.usersOptions = UserAllowUsers;
      _.each(tempColumns, column => {
        // 标签
        if (column.BindField === "Label") {
          _.extend(column, {
            event: "company-tag-click",
            className: "v-cell-link label-text-left",
            formatter(rowData, rowIndex, field) {
              return vm.formatLabels(rowData.Label);
            }
          });
        }
        // 客户名称
        if (column.BindField === "CompanyName") {
          _.extend(column, {
            event: "supplier-name-click",
            className: "v-cell-link"
          });
        }
        // 特殊类型设置为系统字段不导出
        if (column.BindField === "Type") {
          column.isSystem = true;
        }
      });
      // 操作列
      let btnCols = [
        {
          name: "operate",
          width: 40,
          type: "button",
          title: "询价",
          btnTxt: "询",
          visible: true,
          click: row => {
            this.onStkInquiry(row);
          }
        }
      ];

      this.parameterConfig = config.ParamJosn;
      this.defaultConfig = config;
      this.columns = btnCols.concat(tempColumns);
      this.pageSize = config.PageSize;
      this.$refs.table.init();
      this.itemSource = [];
      this.initData && this.onBindData();
      this.$emit("page-loading", false);
    },
    onStkInquiry: async function (obj) {
      // 询价
      if (obj) {
        this.initEnquiryData = obj
        this.$nextTick(function () {
          if (isClient()) {
            this.stkInquiry.SupplierName = obj.CompanyName;
            this.stkInquiry.SupplierID = obj.CCompanyID;
            this.stkInquiry.Address = obj.Address;
            this.addInquire(this.stkInquiry, function (msg) {
              if (msg === "不能向自己询价") {
                this.$message({message: msg, type: "warning"});
              }
            });
          } else {
            const data = {
              SupplierName: obj.CompanyName,
              SupplierID: obj.CCompanyID
            }
            this.$refs.enquiryView.onOpen(data)
          }
        });
      }
    },

    createSearchWhere(pageIndex, isSearchNoSetLabel = false) {
      let fieldsData = _.extend({}, this.searchFields);
      /* 跟进人是否是GUID */
      if (
        fieldsData.FollowUserName &&
        this.ZnlRegex.INT.test(fieldsData.FollowUserName)
      ) {
        let currObj = _.find(this.usableUser, function (user) {
          return (
            user.UserID.toString() === fieldsData.FollowUserName.toString()
          );
        });
        fieldsData.FollowUserName = currObj ? currObj.UserName : "";
      }
      /* 保护人是否是GUID */
      if (
        fieldsData.ProtectUserName &&
        this.ZnlRegex.INT.test(fieldsData.ProtectUserName)
      ) {
        let currObj = _.find(this.usableUser, function (user) {
          return (
            user.UserID.toString() === fieldsData.ProtectUserName.toString()
          );
        });
        fieldsData.ProtectUserName = currObj ? currObj.UserName : "";
      }
      // 仅在当前标签中搜索
      if (this.IsWithLabel) {
        fieldsData["Label"] = this.SelectedLabel;
      } else {
        fieldsData["Label"] = "";
      }
      var jsonData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(fieldsData),
          CompanyTypes: "2,3",
          PageIndex: pageIndex || 1,
          PageSize: this.pageSize
        },
        {
          CompanyName: this.CompanyName,
          FollowUserName: this.FollowUserName,
          IsProtected: this.IsProtected,
          ContactInfo: this.ContactInfo
          // 外部搜索条件
        }
      );
      if (
        this.SelectedLabel === "未设置标签" &&
        (this.IsWithLabel || isSearchNoSetLabel)
      ) {
        jsonData.IsMarkLabels = false;
      }
      return jsonData;
    },
    onBindData(pageIndex, isSearchNoSetLabel = false) {
      this.loading = true;
      let jsonData = this.createSearchWhere(pageIndex, isSearchNoSetLabel);
      return this.$post(CONFIG.searchURL, jsonData, data => {
        this.pageIndex = pageIndex;
        if (data.ResultList === null || data.ResultList.length === 0) {
          this.itemSource = [];
        } else {
          this.itemSource = data.ResultList;
          this.$emit("selection-changed", this.itemSource[0]);
        }
        this.loading = false;
        this.totalCount = data.TotalCount;
        if(this.$refs.table) this.$refs.table.cleanClickRow();
      });
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    onSearch(params = null) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },

    // 标签单击事件
    labelClick(id, labelName) {
      // 使用默认搜索条件
      this.searchFields = _.extend(
        {},
        this.defaultSearchCondition.searchFields
      );
      this.PageIndex = 1;
      this.CompanyName = "";
      this.FollowUserName = "";
      this.IsProtected = false;
      this.ContactInfo = "";

      if (id === -99) {
        this.IsWithLabel = false;
        this.SelectedLabel = "";
      } else {
        this.IsWithLabel = true;
        this.SelectedLabel = labelName;
      }
      this.searchFields = _.extend(this.searchFields, {Label: labelName});
      this.onBindData(1, true);
      this.searchFields = null;
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onCustomerAdd() {
      this.openCustomerDialog(true, {CAutoID: 0});
    },
    onCustomerEdit(e) {
      if (e.field !== "CompanyName") {
        return;
      }
      this.openCustomerDialog(false, e.row);
    },
    openCustomerDialog(isAdd, model) {
      if (!isAdd) {
        let paramOrder = {CCompanyID: model.CCompanyID};
        this.$store.commit("setParamOrder", paramOrder);
      }
      this.$nextTick(function () {
        this.showSuppliersDialog = true;
      });
    },
    onSetFollowUser() {
      let rows = this.$refs.table.getCheckedRows();
      var ids = [];
      if (!_.isUndefined(rows[0])) {
        _.each(rows, r => {
          ids.push(r.CCompanyID);
        });
      }
      let info = this.createSearchWhere(1);
      info.RowsCount = ids.length;
      info.CCompanyIDs = ids;
      info.WhereCount = this.totalCount;
      this.$store.commit("setParamOrder", {info: info});
      this.$nextTick(function () {
        this.showCustomerFollowUserDialog = true;
      });
    },
    onSetProtectUser(vtype) {
      // 设置保护人，取消保护人
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请至少选择一条数据操作！",
            type: "error"
          });
        }
      }
      if (rows.length === 0) {
        this.$message({message: "请至少选择一条数据操作！", type: "error"});
        return;
      }
      var msg = vtype === 1 ? "确定要设置保护?" : "确定要取消保护?";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        var checkMsg = this.checkProtectUser(vtype);
        if (checkMsg !== "") {
          this.$message({message: checkMsg, type: "error"});
        } else {
          _.each(rows, r => {
            // 0,取消1，设置当前用户
            if (vtype === 1) {
              r.ProtectUserID = 1;
            } else {
              r.ProtectUserID = 0;
            }
          });
          return this.$post(CONFIG.SetProtectUserURL, rows, data => {
            this.$refs.table.cleanAllCheck();
            this.$message({message: "设置成功", type: "success"});
            this.onRefresh();
          });
        }
      });
    },
    checkProtectUser(vtype) {
      let rows = this.$refs.table.getCheckedRows();
      let hasRow = 0;
      let noHasRow = 0;
      _.each(rows, r => {
        // 0,取消1，设置当前用户
        if (vtype === 1) {
          if (r.ProtectUserName !== undefined) {
            if (r.ProtectUserName.length > 0) {
              hasRow++;
            }
          }
        } else {
          if (r.ProtectUserName !== undefined) {
            if (r.ProtectUserName.length === 0) {
              noHasRow++;
            }
          } else {
            noHasRow++;
          }
        }
      });
      if (hasRow > 0) {
        return "所选供应商已设置了保护，不能继续操作";
      } else if (noHasRow > 0) {
        return "所选供应商未设置保护，不能继续操作";
      } else {
        return "";
      }
    },
    onSetCustomer() {
      // 设置为客户
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请至少选择一条数据操作！",
            type: "error"
          });
        }
      }
      if (rows.length === 0) {
        this.$message({message: "请至少选择一条数据操作！", type: "error"});
        return;
      }
      this.$confirm("确定将该供应商设置为客户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // _.each(rows, r => {
        //   r.CompanyType = 1;
        // });
        return this.$post(CONFIG.SetSupplierURL, rows, data => {
          this.$refs.table.cleanAllCheck();
          this.$message({message: "设置成功", type: "success"});
          this.onRefresh();
        });
      });
    },
    onDeleteRows() {
      let rows = this.$refs.table.getCheckedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.currentItem) {
          return this.$message({
            message: "请先选中要删除的数据！",
            type: "error"
          });
        }
      }
      if (rows.length <= 0) {
        this.$message({message: "请先选中要删除的数据！", type: "error"});
        return;
      }
      this.$confirm("删除确认, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        return this.$post(CONFIG.multiDeleteURL, rows, data => {
          this.$refs.table.cleanAllCheck();
          this.$message({message: "删除成功", type: "success"});
          this.onRefresh();
        });
      });
    },
    settingLabel(row) {
      if (!row) {
        return;
      }
      this.$nextTick(function () {
        let param = {
          CompanyName: row.CompanyName,
          CompanyGuid: row.CCompanyID
        };
        this.setLabel(param, success => {
          console.log(success);
        });

        if (this.isAttachLabelEvent === false) {
          this.refreshSupplierList = true;
          window.addEventListener(
            "onLabelSet",
            e => {
              this.onBindData(this.pageIndex);
              this.$emit("label-change");
            },
            false
          );
        }
      });
    },

    onImport: async function () {
      this.$nextTick(function () {
        this.importData({importType: "supplier"});
      });
    },
    clearSelectedRows() {
      this.$refs.table.cleanAllCheck();
    },
    async onExport() {
      let columns = [];
      _.each(this.columns, col => {
        if (col.IsExprot) {
          columns.push(col);
        }
      });
      // 待改code的列(列名跟供应商信息列名重复)
      let colMapping = {
        Remark: "ContactRemark",
        DataSource: "ContactDataSource"
      };
      _.each(this.contactConfig.columns, col => {
        if (col.IsExprot) {
          let mapField = colMapping[col.BindField];
          if (mapField) {
            let newCol = _.extend({}, col, {BindField: mapField});
          } else {
            columns.push(col);
          }
        }
      });
      let jsonData = this.createSearchWhere(1);
      this.loading = await this.exportData(
        "CrmSuppliers",
        jsonData,
        columns,
        "供应商资料"
      );
    },
    async showExportColumn(setType) {
      if (setType === "supplier") {
        this.exportInfos.title = "设置供应商导出列";
        this.exportInfos.setSaveConfigURL = CONFIG.saveConfigURL;
        this.exportInfos.addColumnsList = this.columns;
        this.exportInfos.setConfigColumns = this.defaultConfig;
      } else {
        this.exportInfos.title = "设置联系人导出列";
        this.exportInfos.setSaveConfigURL = CONFIG.contactConfigSaveURL;

        if (this.contactConfig.isGet === false) {
          let config = await this.isSaveStorage(
            "CrmCompanyContacts",
            CONFIG.contactConfigURL
          );
          this.contactConfig.isGet = true;
          this.contactConfig.columns = config.ColumnConfigs;
          this.contactConfig.defaultConfig = config;
        }

        this.exportInfos.addColumnsList = this.contactConfig.columns;
        this.exportInfos.setConfigColumns = this.contactConfig.defaultConfig;
      }
      this.exportInfos.setExportColumnVisible = true;
    },
    onRefresh(currentRow) {
      this.onBindData(this.pageIndex, currentRow);
      //this.onCurrentRowChangeHandle()
    },
    onEditDialogClose(isrefresh, isRefreshLabel) {
      this.showSuppliersDialog = false;
      if (isrefresh) {
        this.onRefresh();
      }
      // console.log("on edit dialog close", isRefreshLabel, isrefresh);
      // if (isRefreshLabel) {
      //   this.$emit("label-change", true);
      // }
      this.$emit("label-change", true);
    },
    onEditDialogConfirm(isrefresh, isRefreshLabel) {
      isrefresh && (payVisible = false);
      this.$emit("confirm", true);
      this.onRefresh();
      this.$refs.table.cleanAllCheck();
      if (isRefreshLabel) {
        this.$emit("label-change");
      }
    },

    formatLabels(labelStr) {
      let html = "";
      if (this.hasValue(labelStr)) {
        let arry = labelStr.split(",");
        _.each(arry, label => {
          html += `<span class="el-tag el-tag--mini mr2">${label}</span>`;
        });
      } else {
        // html = '<div class="znl-button znl-btn-default mac list-set-label-btn"><div class="button"><span><i class="iconfont icon-labelling_btn"></i>设置标签</span></div></div>'
      }

      return `<div title="${labelStr}">${html}</div>`;
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("crmSuppliers", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("crmSuppliers", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("crmSuppliers", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("crmSuppliers");
    },

    onConfirm(data) {
      const selectItem = this.itemSource.find(item => item.CCompanyID === data.SupplierID)
      this.$emit("selection-changed",  selectItem);
    }
  },
  async mounted() {
    await this.getCurrencyCodeListAsync;

    on(window, "onCSSupplierLabelsUpdate", e => {
      this.onRefresh();
      this.$emit("label-change");
      if (e) {
        _.each(e, label => {
          let berow = this.itemSource.find(row => {
            return row.SupplierName === label.SupplierName;
          });
          if (berow.label.length > 0) {
            berow.label += "," + label.LabelName;
          } else {
            berow.label += label.LabelName;
          }
        });
      }
    });
    this.onInit();
  },
  created() {
    this.defaultSearchCondition = _.extend({}, this.searchFields);
  }
};
</script>

<style lang="scss">
.label-text-left {
  div {
    // text-align: left !important;
  }
}

.list-set-label-btn {
  height: 20px;
  line-height: 20px;
  color: #3e3e3e;
}
</style>


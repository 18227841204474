<template>
  <div class="label-container">
    <znl-input :border="true"
      width="65%"
      class="label-input"
      v-model="filterText"
      placeholder="标签快速定位"
      @keyup.enter.native="initLabels()"
      tabindex="1"></znl-input>
    <znl-button style-type="mac"
      :height="22"
      :width="25"
      tip="搜索"
      @click="initLabels()"
      style="margin-left:2px;">
      <span>
        <i class="iconfont icon-search_ic"></i>
      </span>
    </znl-button>
    <znl-button style-type="mac"
      :height="22"
      :width="25"
      tip="刷新"
      @click="onRefresh()">
      <span>
        <i class="iconfont icon-table_refresh_btn_n"></i>
      </span>
    </znl-button>
    <el-tree ref="labeltree"
      :data="labels"
      class="filter-tree"
      :props="treeDataFormat"
      :filter-node-method="filterNode"
      :show-checkbox="false"
      @node-click="handleNodeClick">
      <span class="custom-tree-node"
        slot-scope="{ node, data }">
        <el-tag size="mini" @click="handleNodeClick(data)">
          {{ data.LabelName }}
          <i v-if="data.LID !== -99">({{ data.AssignedCount}})</i>
        </el-tag>
      </span>
    </el-tree>
  </div>
</template>

<script>
const CONFIG = {
  searchLabelURL: 'CrmSuppliers/labels',
  getCustomerLabelURL: 'CrmCustomerLabel/GetLabelWithCount'
}
export default {
  name: 'name',
  config: CONFIG,
  components: {},
  props: {
    // 标签类型: supplier, customer
    labelType: {
      type: String,
      default: 'supplier'
    }
  },
  data () {
    return {
      filterText: '',
      treeData: [],
      labels: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentItem: {},
      currentLabel: ''
    }
  },
  watch: {
    filterText (val) {
      this.$refs.labeltree.filter(val)
    }
  },
  methods: {
    onInit () {
      this.initLabels()
    },
    initLabels () {
      this.addAllTag()
      if (this.labelType === 'supplier') {
        this.getSupplierLabels()
      } else if (this.labelType === 'customer') {
        this.getCustomerLabels()
      }
    },

    treeDataFormat (data, node) {
      return `${data.LabelName}(${data.AssignedCount})`
    },

    getSupplierLabels () {
      this.$post(
        CONFIG.searchLabelURL,
        {
          PageIndex: 1,
          PageSize: 200,
          LabelName: this.filterText
        },
        data => {
          if (data && data.TotalCount > 0) {
            this.labels = data.ResultList
            this.treeData = _.map(data.ResultList, item => {
              return {
                LID: item.LID,
                label: item.LabelName + '(' + item.AssignedCount + ')',
                labelText: item.LabelName,
                count: item.AssignedCount
              }
            })
          } else {
            this.labels = []
          }
          this.addAllTag()
        }
      )
    },

    getCustomerLabels () {
      this.$post(CONFIG.getCustomerLabelURL, { LabelName: this.filterText, IsGetUnSetLabels: true },
        data => {
          if (data && data.length > 0) {
            this.labels = data
            this.treeData = _.map(data, item => {
              return {
                LID: item.LID,
                label: item.LabelName + '(' + item.AssignedCount + ')',
                labelText: item.LabelName,
                labelName: item.LabelName,
                count: item.AssignedCount
              }
            })
          } else {
            this.labels = []
          }
          this.addAllTag()
        }
      )
    },

    addAllTag () {
      let index = _.findIndex(this.labels, item => item.LID === -99)
      if (index === -1) {
        let item = {
          LID: -99,
          AssignedCount: '-',
          LabelName: '全部'
        }
        this.labels.unshift(item)
      }
    },

    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick (data) {
      this.currentLabel = data.LabelName
      this.currentItem = data
      let param = {
        LID: data.LID,
        labelText: data.LabelName
      }
      this.$emit('selected-label', param)
    },
    onRefresh () {
      this.filterText = null
      this.initLabels()
    },

    labelChange (changeInfo) {
      if (changeInfo === undefined || changeInfo === null) {
        return
      }

      // // 统计新增的标签数
      // if (changeInfo.addArray && changeInfo.addArray.length > 0) {
      //   _.each(changeInfo.addArray, newLabel => {
      //     let item = _.find(this.labels, l => l.LabelName === newLabel)
      //     if (item) {
      //       item.AssignedCount = item.AssignedCount + 1
      //     } else {
      //       this.labels.push({
      //         LID: 0,
      //         AssignedCount: 1,
      //         LabelName: newLabel
      //       })
      //     }
      //   })
      // }

      // _.each(this.labels, label => {
      //   // 删除的标签
      //   let isDelete = _.some(changeInfo.removeArray, m => m === label.LabelName)
      //   if (isDelete && label.AssignedCount > 0) {
      //     label.AssignedCount = label.AssignedCount - 1
      //   }
      // })

      //从后端加载标签，前端统计不准确
      if (this.labelType === "supplier") {
        this.getSupplierLabels();
      } else if (this.labelType === "customer") {
        this.getCustomerLabels();
      }

    },

    currentItemClass (item) {
      if (this.currentItem === item) {
        return 'is-current'
      }
    }
  },
  mounted () {
    this.onInit()
  },
  computed: {}
}
</script>

<style lang="scss">
.label-container {
  padding-top: 5px;
  height: 100%;
  background-color: #f2f2f2;
  .label-input {
    // top: 4px;
    // left: 5px;
    z-index: 999;
  }
  .filter-tree {
    margin-top: 3px;
    height: 97%;
    border: 1px solid #c0c0c0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .is-current, .el-tree-node:focus>.el-tree-node__content{
    background-color:rgba(0,0,0,.12);;
  }
  .custom-tree-node {
    i{
      font-style: initial;
    }
  }
}
</style>

<template>
  <!-- 客户，供应商联系人 -->
  <znl-table
    ref="table"
    gridtype="base"
    :columns="columns"
    checkboxBindingKey="ContactGUID"
    height="100%"
    layoutType="other"
    loading-type="animation"
    :item-source="itemSource"
    :search-fields="onSearch"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :on-init="onInit"
    :is-init="true"
    :show-header="false"
    :is-show-rightbtns="false"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :loading="loading"
    @on-show-trigger="e => {hideShowColTrigger('CrmCompanyContacts', e.col)}"
    :drag-done="col=>setTableColWidth('CrmCompanyContacts', col)"
    @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig('CrmCompanyContacts', cols,true)}"
    @on-config-save="(cols)=>{columns = cols, saveErpTableConfig('CrmCompanyContacts', cols,false)}"
    @on-reset-click="resetTableConfig('CrmCompanyContacts')"
    :on-page-changed="onBindData"
  ></znl-table>
</template>

<script>

import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { erpTableSetHandler, itemsHandle } from '@scripts/methods/common'
const Config = {
  PK: 'ContactID',
  configURL: 'CrmCompanyContacts/GetConfig',
  saveConfigURL: 'CrmCompanyContacts/SaveConfig',
  resetConfigURL: 'CrmCompanyContacts/ResetConfig',
  searchURL: 'CrmCompanyContacts/Search',
  addURL: 'CrmCompanyContacts/Add',
  updateURL: 'CrmCompanyContacts/Edit',
  SaveURL: 'CrmCompanyContacts/SaveContacts',
  deleteURL: 'CrmCompanyContacts/Del',
  multiDeleteURL: 'CrmCompanyContacts/MultiDel',
  default: null
}
export default {
  mixins: [znlMethodsMixin, erpTableSetHandler, itemsHandle],
  config: Config,
  name: 'CompanyContacts',
  components: {},
  data () {
    return {
      dataMapSource: {
        DataSource: ['电话', '网站', 'Email', 'QQ', '阿里巴巴', '阿里旺旺', 'ICO', 'Skype', 'MSN']
      },
      ContactID: '',
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      resourceList: [],
      pageSize: 9999999,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      CustomerModel: {},
      loading: false
    }
  },

  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('CrmCompanyContacts', Config.configURL)
      }
      var tempColumns = config.ColumnConfigs
      this.resourceList = config.ResourceList.map(item => item.Code)
      // 行业
      if (_.find(tempColumns, function (column) { return column.binding === 'Gender' })) {
        var genderDataMap = []
        genderDataMap.push({ 'key': '0', 'value': '女' })
        genderDataMap.push({ 'key': '1', 'value': '男' })
        _.extend(_.find(tempColumns, function (column) { return column.binding === 'Gender' }), { dataMap: genderDataMap, showDropDown: true })
      }
      // 数据来源
      if (_.find(tempColumns, function (column) { return column.binding === 'DataSource' })) {
        var dataSourceDataMap = []
        _.each(this.dataMapSource.DataSource, function (item) {
          dataSourceDataMap.push({ 'key': item, 'value': item })
        })
        _.extend(_.find(tempColumns, function (column) { return column.binding === 'DataSource' }), { dataMap: dataSourceDataMap, showDropDown: true })
      }
      this.defaultConfig = config
      this.columns = tempColumns
      this.pageSize = config.PageSize
      this.role = config.Role
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData()
      }
      this.$nextTick(() => {
        this.$emit('page-loading', false)
      })
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },

    onHeadSearch (custName) {
      if (!this.hasValue(custName)) {
        return
      }
      this.customerModel = custName
      return this.onBindData(1)
    },

    onBindData (pageIndex) {
      //this.$refs.table.cleanSort()
      let fieldsData = _.extend({}, this.searchModel)
      if (fieldsData.Gender === '') {
        this.$delete(fieldsData, 'Gender')
      }
      var jsonData = _.extend({}, {
        FieldWhereString: JSON.stringify(fieldsData),
        PageIndex: pageIndex || 1,
        PageSize: this.pageSize,
        CCompanyID: this.customerModel
      })
      return this.$post(Config.searchURL, jsonData, (data) => {
        this.removeTime(data.ResultList, ['Birthday'])
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('set-total-count', this.totalCount)
      }).finally(() => {
        this.$emit('page-loading', false)
      })
    },

    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },

    getHeader () {
      if (this.$route.path.toString().indexOf('crmcustomers') > -1) {
        return '客户联系人'
      } else if (this.$route.path.toString().indexOf('crmsuppliers') > -1) {
        return '供应商联系人'
      }
    }
  },

  async mounted () {
    await this.onInit()
  }
}
</script>

<style lang="scss">
</style>

<template>
  <div style="height:100%">
    <znl-layout-spa
      class="c-suppliers"
      :page-loading="pageLoading"
      znl-loading-text="页面加载中,请稍后..."
      height="100%">
      <el-tabs
        v-model="mianActiveName"
        height="100%"
        class="nav-common-ui tab-btnstyle-box grid-ordermange-box tab-manage-tab">
        <el-tab-pane name="supplierInfo" class="supplier-tabpanel" label="供应商资料">
          <znl-layout-leftright leftWidth="190px">
            <!-- class="标签分类" -->
            <crm-labels
              slot="znlLeft"
              ref="supplierLabel"
              class="znlLeft"
              @selected-label="onLabelSelect"
            ></crm-labels>

            <!-- 供应商信息 -->
            <znl-layout-topbottom top-height="62%" slot="znlRight" class="znlRight">
              <CrmSuppliers
                slot="znlTop"
                class="znlTop"
                ref="Suppliers"
                :init-data="false"
                :searchModel="crmSuppliersSearchModel"
                @page-loading="(d)=>{pageLoading_Suppliers=d}"
                @selection-changed="suppliersSelectionChange"
                @label-change="onLabelChange"
              ></CrmSuppliers>

              <el-tabs
                slot="znlBottom"
                height="38%"
                v-model="bottomActiveName"
                @tab-click="onBottomTabClick"
                class="znlBottom tab-btnstyle-box tab-details"
              >
                <el-tab-pane
                  :label="tabTitleContact"
                  name="CrmCompanyContacts"
                >
                  <company-contacts
                    :is-fields-search="true"
                    ref="CrmCompanyContacts"
                    :init-data="false"
                    @page-loading="(d)=>{pageLoading_Contacts=d}"
                    @set-total-count="(count) => {tabData.contactCount = count || 0}"
                  ></company-contacts>
                </el-tab-pane>
                <el-tab-pane :label="tabTitleInquire" v-if="hasRight('PastInquire')" name="PastInquire">
                  <inquire-details
                    ref="inquireRecord"
                    v-if="InquireTableInit"
                    :is-operate="false"
                    :init-data="false"
                    :is-supplier-click="false"
                    :is-generate-purchase-order="true"
                    @set-total-count="(count) => {tabData.inquireCount = count || 0}"
                  ></inquire-details>
                </el-tab-pane>
                <el-tab-pane :label="tabTitleStkin" v-if="hasRight('PastStkIn')" name="PastStkIn">
                  <stk-in-details
                    ref="inRecord"
                    v-if="StkInTableInit"
                    :init-data="false"
                    :is-operate="false"
                    module-name='StkInDetails'
                    @set-total-count="(count) => {tabData.stkinCount = count || 0}"
                  ></stk-in-details>
                </el-tab-pane>
              </el-tabs>
            </znl-layout-topbottom>
          </znl-layout-leftright>
        </el-tab-pane>
      </el-tabs>
    </znl-layout-spa>


  </div>
</template>

<script>
import { switchToLegacyPage } from '~/lib/native'
import CrmLabels from '@/components/modules/Crm/Labels'
import CrmSuppliers from '@/components/modules/Crm/CrmSuppliers'
import CompanyContacts from '@/components/modules/Crm/CompanyContacts'
import StkInDetails from '@c_modules/Stk/StkInDetails'
import InquireDetails from '@c_modules/RFQ/InquireDetails'

const CONFIG = {
  getContactCountUrl: 'CrmCompanyContacts/GetCount',
  getInquireCountUrl: 'StkInquireRecord/GetCount',
  getStkinRecordCountUrl: 'StkInDetails/GetCount'
}

export default {
  name: 'VSuppliers',
  components: {
    CrmSuppliers,
    CompanyContacts,
    CrmLabels,
    StkInDetails,
    InquireDetails
  },
  props: {},
  data() {
    return {
      mianActiveName: 'supplierInfo',
      bottomActiveName: 'CrmCompanyContacts',
      crmSuppliersSearchModel: {},
      SuppliersContacts: {},
      Inquire: {},
      ZnlStkInRecord: {},
      CurrentSupplier: {},
      pageLoading_Suppliers: true,
      pageLoading_Contacts: true,
      InquireTableInit: false,
      StkInTableInit: false,

      tabData: {
        contactCount: 0,
        inquireCount: 0,
        stkinCount: 0
      }
    }
  },
  computed: {
    pageLoading() {
      return this.pageLoading_Suppliers && this.pageLoading_Contacts
    },
    tabTitleContact () {
      return '供应商联系人(' + this.tabData.contactCount + ')'
    },
    tabTitleInquire () {
      return '询价记录(' + this.tabData.inquireCount + ')'
    },
    tabTitleStkin () {
      return '入库记录(' + this.tabData.stkinCount + ')'
    }
  },
  methods: {
    onInit() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      this.$post(pageUrl, { PageCode: 'VSuppliers' }, data => {
        this.pageData = data
      })
    },
    onLabelSelect(sdata) {
      this.$refs.Suppliers.labelClick(sdata.LID, sdata.labelText)
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    suppliersSelectionChange(item) {
      this.CurrentSupplier = item
      if (item === undefined || item === null) {
        this.CurrentSupplier = null
      }
      switch (this.bottomActiveName) {
        case 'CrmCompanyContacts':
          this.getInquireCount()
          this.getStkinCount()
          break
        case 'PastInquire':
          this.getContactCount()
          this.getStkinCount()
          break
        case 'PastStkIn':
          this.getContactCount()
          this.getInquireCount()
          break
      }
      this.lineSearch()
    },
    onBottomTabClick() {
      this.lineSearch()
    },
    lineSearch() {
      if (this.CurrentSupplier === null) {
        this.CurrentSupplier = {
          CCompanyID: null,
          CompanyName: null
        }
      }
      if (this.bottomActiveName === 'CrmCompanyContacts') {
        this.SuppliersContacts = {
          CCompanyID: this.CurrentSupplier.CCompanyID,
        }
        this.$nextTick(() => {
          this.$refs.CrmCompanyContacts && this.$refs.CrmCompanyContacts.onHeadSearch(this.CurrentSupplier.CCompanyID)
        })
      } else if (this.bottomActiveName === 'PastInquire') {
        if (!this.InquireTableInit) {
          this.InquireTableInit = true;
        }
        if (!this.CurrentSupplier.CompanyName) {
          return;
        }
        this.Inquire = {
          CCompanyID: this.CurrentSupplier.CCompanyID,
          SupplierName: this.CurrentSupplier.CompanyName
        }
        this.$nextTick(() => {
          this.$refs.inquireRecord && this.$refs.inquireRecord.onHeadSearch(this.Inquire)
        })
      } else if (this.bottomActiveName === 'PastStkIn') {
        if (!this.StkInTableInit) {
          this.StkInTableInit = true;
        }
        if (!this.CurrentSupplier.CompanyName) {
          return;
        }
        this.ZnlStkInRecord = {
          CCompanyID: this.CurrentSupplier.CCompanyID,
          SupplierName: this.CurrentSupplier.CompanyName
        }
        this.$nextTick(() => {
          this.$refs.inRecord && this.$refs.inRecord.onHeadSearch(this.ZnlStkInRecord)
        })
      }
    },
    labelSeted(labelse) {
      this.$refs.supplierLabel.initLabels()
    },
    async oldEdition() {
      await switchToLegacyPage('Supplier')
    },

    // 统计联系人总数
    getContactCount () {
      if (this.CurrentSupplier === null || !this.hasValue(this.CurrentSupplier) || !this.hasValue(this.CurrentSupplier.CCompanyID)) {
        this.tabData.contactCount = 0
        return true
      }
      let param = { CCompanyID: this.CurrentSupplier.CCompanyID }
      this.$post(CONFIG.getContactCountUrl, param, data => {
        this.tabData.contactCount = data || 0
      })
    },
    // 统计询价记录总数
    getInquireCount () {
      if (this.CurrentSupplier === null || !this.hasValue(this.CurrentSupplier) || !this.hasValue(this.CurrentSupplier.CCompanyID) || !this.hasRight('PastInquire')) {
        this.tabData.inquireCount = 0
        return true
      }
      let fieldWhereString = {
        CCompanyID: this.CurrentSupplier.CCompanyID,
        SupplierName: this.CurrentSupplier.CompanyName
      }
      let param = {
        FieldWhereString: JSON.stringify(fieldWhereString)
      }
      this.$post(CONFIG.getInquireCountUrl, param, data => {
        this.tabData.inquireCount = data || 0
      })
    },
    // 统计入库记录总数
    getStkinCount () {
      if (this.CurrentSupplier === null || !this.hasValue(this.CurrentSupplier) || !this.hasValue(this.CurrentSupplier.CCompanyID) || !this.hasRight('PastStkIn')) {
        this.tabData.stkinCount = 0
        return true
      }
      let fieldWhereString = {
        CCompanyID: this.CurrentSupplier.CCompanyID,
        SupplierName: this.CurrentSupplier.CompanyName
      }
      let param = {
        FieldWhereString: JSON.stringify(fieldWhereString),
        IsReturn: false
      }
      this.$post(CONFIG.getStkinRecordCountUrl, param, data => {
        this.tabData.stkinCount = data || 0
      })
    },

    onLabelChange () {
      this.$refs.supplierLabel && this.$refs.supplierLabel.onRefresh()
    }
  },
  mounted() { },
  created() {
    this.onInit()
  }
}
</script>

<style lang='scss'>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('znl-layout-spa',{attrs:{"page-loading":_vm.pageLoading,"znl-loading-text":"页面加载中,请稍后..."}},[_c('el-tabs',{staticClass:"nav-common-ui tab-btnstyle-box grid-ordermange-box tab-manage-tab",attrs:{"value":"VCustManage","height":"100%"},model:{value:(_vm.mianActiveName),callback:function ($$v) {_vm.mianActiveName=$$v},expression:"mianActiveName"}},[_c('el-tab-pane',{attrs:{"label":"客户资料","name":"custMange"}},[_c('znl-layout-leftright',{attrs:{"left-width":"190px"}},[_c('crm-labels',{ref:"customerLabel",staticClass:"znlLeft",attrs:{"slot":"znlLeft","label-type":"customer"},on:{"selected-label":_vm.onLabelSelect},slot:"znlLeft"}),_c('znl-layout-topbottom',{staticClass:"znlRight",attrs:{"slot":"znlRight","top-height":"62%"},slot:"znlRight"},[_c('crm-customers',{ref:"Customers",staticClass:"znlTop",attrs:{"slot":"znlTop","init-data":false,"searchModel":_vm.crmCustomersSearchModel},on:{"page-loading":function (d) {
                _vm.pageLoading_Cust = d;
              },"selection-changed":_vm.customersSelectionChange,"label-change":_vm.onLabelChange},slot:"znlTop"}),_c('el-tabs',{staticClass:"znlBottom tab-btnstyle-box tab-details",attrs:{"slot":"znlBottom"},on:{"tab-click":_vm.pageSearch},slot:"znlBottom",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.tabTitleContact,"name":"custContacts"}},[_c('crm-contacts',{ref:"custContacts",attrs:{"init-data":false},on:{"page-loading":function (d) {
                    _vm.pageLoading_Contacts = d;
                  },"set-total-count":function (cnt) {
                    _vm.tabData.contactCount = cnt;
                  }}})],1),(_vm.hasRight('CustomerRequire'))?_c('el-tab-pane',{attrs:{"label":_vm.tabTitleBOM,"name":"PastQuote"}},[(_vm.quoteTableInit)?_c('znl-hisofferpricebymodel',{ref:"bomLine",attrs:{"is-fields-search":true,"init-data":false,"searchModel":_vm.Hisofferprice,"parent-module":"customer"},on:{"page-loading":function (d) {
                    _vm.pageLoading_Contacts = d;
                  },"set-total-count":function (cnt) {
                    _vm.tabData.bomCount = cnt;
                  }}}):_vm._e()],1):_vm._e(),(_vm.hasRight('PastStkOut'))?_c('el-tab-pane',{attrs:{"label":_vm.tabTitleStkout,"name":"PastStkOut"}},[(_vm.stkOutTableInit)?_c('stk-out-details',{ref:"PastStkOut",attrs:{"is-operate":false,"init-data":false,"searchModel":_vm.stkOutSearchByModel},on:{"set-total-count":function (cnt) {
                    _vm.tabData.stkoutCount = cnt;
                  }}}):_vm._e()],1):_vm._e(),_c('el-tab-pane',{attrs:{"label":_vm.tabTitleFollow,"name":"custFollowInfo"}},[(_vm.followInfoTableInit)?_c('crm-company-follow-info',{ref:"custFollowInfo",attrs:{"init-data":false},on:{"set-total-count":function (cnt) {
                    _vm.tabData.followHistoryCount = cnt;
                  }}}):_vm._e()],1)],1)],1)],1)],1)],1),(_vm.isErpClient)?_c('a',{staticClass:"old-edition",on:{"click":function($event){return _vm.oldEdition()}}},[_vm._v("切换到旧版")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- 跟进 -->
  <znl-dialog
    :visible="visible"
    title="编辑跟进记录"
    width="600px"
    height="420px"
    class="dialog-finpayin-add-box"
    v-loading="loading"
    :element-loading-text="LoadingText"
    @close="()=>{$emit('close')}"
  >
    <div class="row-box">
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="跟进人："
              :is-must-fill="false"
              :clearable="true"
              :select-options="usableUser"
              v-model="FollowInfoModel.FollowUserName"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="select"
              width="100%"
              placeholder="请选择"
              popper-class="popperClass"
              title="跟进方式："
              :is-must-fill="true"
              :clearable="true"
              :select-options="FollowTypes"
              v-model="FollowInfoModel.FollowType"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              form-type="input"
              placeholder=" "
              size="mini"
              layout="left"
              :disabled="true"
              :is-must-fill="false"
              title="公司名称："
              width="100%"
              :clearable="true"
              v-model="FollowInfoModel.CCompanyName"
              type="text"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="input"
              width="100%"
              placeholder
              popper-class="popperClass"
              title="联系人："
              :is-must-fill="false"
              :clearable="true"
              v-model="FollowInfoModel.Contact"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="datepicker"
              width="100%"
              placeholder
              popper-class="popperClass"
              title="跟进日期："
              :is-must-fill="false"
              :clearable="true"
              v-model="FollowInfoModel.FollowDate"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="input"
              width="100%"
              placeholder
              popper-class="popperClass"
              title="电话："
              :is-must-fill="false"
              :clearable="true"
              v-model="FollowInfoModel.TelPhone"
              type="text"
            ></znl-input>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="datepicker"
              width="100%"
              placeholder
              popper-class="popperClass"
              title="计划日期："
              :is-must-fill="false"
              :clearable="true"
              v-model="FollowInfoModel.PlanDate"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              layout="left"
              form-type="input"
              width="100%"
              placeholder
              popper-class="popperClass"
              title="标题："
              :is-must-fill="true"
              :clearable="true"
              v-model="FollowInfoModel.FollowTitle"
              type="text"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="form_Row_W100">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <znl-input
              :border="true"
              title-width="60px"
              width="100%"
              popper-class="popperClass"
              title="跟进内容："
              :is-must-fill="true"
              :clearable="true"
              :rows="5"
              placeholder
              v-model="FollowInfoModel.FollowContent"
              type="textarea"
            ></znl-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <div slot="footer">
      <znl-button :height="30" :width="60" @click="onCancel">关闭</znl-button>
      <znl-button :height="33" :width="60" style-type="main" @click="onSubmit">保存</znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'CrmFollowInfoEditDialog',
  mixins: [propSyncMixin],
  components: {},
  data () {
    return {
      config: {
        PK: 'CAutoID',
        companyUserURL: 'CompanyAccount/GetUsableUser',
        companyContactURL: 'CrmFollowInfoEditDialog/GetContacts',
        addURL: 'CrmFollowInfoEditDialog/Add',
        updateURL: 'CrmFollowInfoEditDialog/Edit',
        fileUploadURL: 'CrmFollowInfoEditDialog/UploadAttachment'
      },
      FollowInfoModel: {
        FWID: 0,
        CCompanyGUID: '',
        CCompanyName: '',
        FollowUserName: '',
        FollowTitle: '',
        FollowContent: '',
        FollowDate: '',
        PlanDate: '',
        IsShowTips: '',
        FollowType: '',
        Contact: '',
        TelPhone: '',
        IsComplete: false,
        FollowResult: ''
      },
      defaultFollowInfo: {
        FWID: 0,
        CCompanyGUID: '',
        CCompanyName: '',
        FollowUserName: '',
        FollowTitle: '',
        FollowContent: '',
        FollowDate: '',
        PlanDate: '',
        IsShowTips: '',
        FollowType: '',
        Contact: '',
        TelPhone: '',
        IsComplete: false,
        FollowResult: ''
      },
      fileList: [{ name: '', url: '' }],
      FollowTypes: [
        { key: '电话', value: '电话' },
        { key: 'QQ', value: 'QQ' },
        { key: 'Email', value: 'Email' },
        { key: '上门拜访', value: '上门拜访' }
      ],
      usableUser: [],
      contacts: [],
      loading: false,
      LoadingText: '正在保存，请稍等...'
    }
  },

  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: false,
    title: String,
    dataModel: {
      type: Object,
      default: () => {
        return {
          FWID: 0,
          CCompanyGUID: '',
          CCompanyName: '',
          FollowUserName: '',
          FollowTitle: '',
          FollowContent: '',
          FollowDate: '',
          PlanDate: '',
          IsShowTips: '',
          FollowType: '',
          Contact: '',
          TelPhone: ''
        }
      }
    }
  },

  watch: {
    dataModel (item) {
      _.extend(this.FollowInfoModel, this.defaultFollowInfo, item)
      let vm = this
      vm.$post(vm.config.companyUserURL, {}, companyUserData => {
        vm.usableUser = []
        _.each(companyUserData.Users, function (user) {
          vm.usableUser.push(_.clone(user))
        })
        vm.usableUser = _.map(vm.usableUser, item => {
          return {
            key: item.UserName,
            value: item.UserName
          }
        })
      })
      vm.$post(vm.config.companyContactURL, this.FollowInfoModel, contacts => {
        vm.contacts = []
        _.each(contacts, function (contacter) {
          vm.contacts.push(_.clone(contacter))
        })
        if (vm.contacts.length > 0) {
          let defaultContact = _.find(vm.contacts, function (item, index) {
            return (
              (item.Telephone && item.Telephone.length > 0) ||
              (item.Mobile && item.Mobile.length > 0)
            )
          })
          if (_.isUndefined(defaultContact)) {
            defaultContact = vm.contacts[0]
          }

          this.FollowInfoModel.Contact = defaultContact.ContactName
          this.FollowInfoModel.TelPhone = this.hasValue(defaultContact.Telephone)
            ? defaultContact.Telephone
            : defaultContact.Mobile
        }
      })

      // 默认跟进人，跟进人为空，默认当前登录用户
      if (
        item.FollowUserID === 0 ||
        item.FollowUserID === undefined ||
        item.FollowUserID === '' ||
        item.FollowUserID === null
      ) {
        this.FollowInfoModel.FollowUserName = this.$store.state.accountInfo.UserName
      } else {
        this.FollowInfoModel.FollowUserName = item.UserName
      }
      this.FollowInfoModel.PlanDate = this.FormatDate(new Date(), 'yyyy-MM-dd')
    },
    visible (val) {
    }
  },

  methods: {
    FormatDate (dateobj, fmt) {
      // author: meizz
      var o = {
        'M+': dateobj.getMonth() + 1, // 月份
        'd+': dateobj.getDate(), // 日
        'h+': dateobj.getHours(), // 小时
        'm+': dateobj.getMinutes(), // 分
        's+': dateobj.getSeconds(), // 秒
        'q+': Math.floor((dateobj.getMonth() + 3) / 3), // 季度
        S: dateobj.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (dateobj.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return fmt
    },

    queryContactSearch (queryString, cb) {
      var restaurants = []
      _.each(this.contacts, function (item, index) {
        restaurants.push(_.extend({ value: item.ContactName }, item))
      })

      var results =
        queryString && !_.isUndefined(restaurants)
          ? restaurants.filter(this.createContactFilter(queryString))
          : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    createContactFilter (queryString) {
      return restaurant => {
        return restaurant.value.indexOf(queryString.toLowerCase()) === 0
      }
    },

    onSubmit () {
      let vm = this
      this.loading = true
      let url = vm.FollowInfoModel.FWID <= 0 ? vm.config.addURL : vm.config.updateURL
      let param = this.FollowInfoModel.FWID <= 0 ? this.FollowInfoModel : [this.FollowInfoModel]
      vm.$post(url, param,
        (data, logic) => {
          if (logic.code === 200) {
            vm.p_dialogVisible = false
            vm.$message({ message: '保存成功', type: 'success' })
            vm.$nextTick(function () {
              this.$emit('confirm', true)
            })
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        }
      ).finally(() => {
        this.loading = false
        this.gridShow = true
      })
    },

    onCancel () {
      this.p_dialogVisible = false
      this.$emit('confirm', false)
    }
  }
}
</script>

<style lang="scss">
.dialog-finpayin-add-box {
  .form_Row_W100 {
    padding: 5px 0;
  }
  .row-box {
    height: 100%;
    padding-left: 15px;
  }
  .znl-dialog__footer {
    padding-right: 16px;
  }
}
</style>

<template>
  <div>
    <znl-table
          gridtype='base'
          ref="table"
          header="跟进记录"
          height="100%"
          layoutType="other"
          loading-type="animation"
          :columns="columns"
          :item-source="itemSource"
          :search-fields="onSearch"
          :page-size="pageSize"
          :page-index="pageIndex"
          :total-count="totalCount"
          :role="role"
          :users="$store.state.users"
          :on-init="onInit"
          :on-refresh="onRefresh"
          :on-search="onSearch"
          :loading="isMini"
          @on-show-trigger="e => {hideShowColTrigger('CrmCompanyFollowInfo', e.col)}"
          :drag-done="col=>setTableColWidth('CrmCompanyFollowInfo', col)"
          @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig('CrmCompanyFollowInfo', cols,true)}"
          @on-config-save="(cols)=>{columns = cols, saveErpTableConfig('CrmCompanyFollowInfo', cols,false)}"
          @on-reset-click="resetTableConfig('CrmCompanyFollowInfo')"
          :on-page-changed="onBindData">
    </znl-table>

    <crm-follow-info-edit-dialog
      ref="CrmFollowInfoEditDialog"
      :dataModel="dataModel"
      :visible="dialogVisible"
      title="编辑跟进记录"
      :isRefresh="refreshDialog"
      @close="onEditDialogClose"
      @confirm="onEditDialogConfirm"
    ></crm-follow-info-edit-dialog>

  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import CrmFollowInfoEditDialog from '@/components/modules/Crm/CrmFollowInfoEditDialog'
import { exportData, erpTableSetHandler, itemsHandle } from '@scripts/methods/common'
const Config = {
  PK: 'FWID',
  configURL: 'CrmCompanyFollowInfo/GetConfig',
  saveConfigURL: 'CrmCompanyFollowInfo/SaveConfig',
  resetConfigURL: 'CrmCompanyFollowInfo/ResetConfig',
  searchURL: 'CrmCompanyFollowInfo/Search',
  deleteURL: 'CrmCompanyFollowInfo/Del',
  SaveURL: 'CrmFollowInfoEditDialog/Edit',
  default: null
}
export default {
  name: 'CrmCompanyFollowInfo',
  mixins: [znlMethodsMixin, exportData, erpTableSetHandler, itemsHandle],
  config: Config,
  components: { CrmFollowInfoEditDialog },
  data () {
    return {
      columns: [],
      itemSource: [],
      resourceList: [],
      fieldsFilter: {},
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      dialogVisible: false,
      dataModel: {},
      refreshDialog: false,
      isFullMatch: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('CrmCompanyFollowInfo', Config.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)

      let btnCols = [
        {
          width: 35,
          type: 'button',
          title: '',
          btnTxt: '编辑',
          isFrozen: true,
          click: row => {
            this.onEdit(row)
          }
        },
        {
          width: 35,
          type: 'button',
          title: '',
          btnTxt: '删除',
          isFrozen: true,
          click: (row, rowIndex) => {this.onDelete(row, rowIndex)}
        }
      ]

      this.defaultConfig = config
      this.columns = btnCols.concat(config.ColumnConfigs)
      this.pageSize = config.PageSize
      this.role = config.Role
      this.$refs.table.init()
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    onHeadSearch (custName, isFullMatch = false) {
      if (!this.hasValue(custName)) {
        return
      }
      this.customerModel = custName
      this.isFullMatch = isFullMatch
      return this.onBindData(1)
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData (pageIndex) {
      var vm = this
      //this.$refs.table.cleanSort()
      let fieldsData = _.extend({}, this.searchModel)
      var jsonData = _.extend({}, {
        FieldWhereString: JSON.stringify(fieldsData),
        PageIndex: pageIndex || 1,
        PageSize: this.pageSize,
        CCompanyName: this.customerModel,
        CustomerExact: this.isFullMatch
      })
      return this.$post(Config.searchURL, jsonData, (data) => {
        this.$emit('page-loading', false)
        this.$emit('set-total-count', data.TotalCount)
        this.removeTime(data.ResultList, ['PlanDate', 'FollowDate'])
        vm.pageIndex = jsonData.PageIndex
        vm.itemSource = data.ResultList
        vm.totalCount = data.TotalCount
      })
    },

    onEdit (row) {
      this.dataModel = row
      this.dialogVisible = true
      this.refreshDialog = !this.refreshDialog
    },

    onEditDialogClose () {
      this.dialogVisible = false
    },

    onEditDialogConfirm () {
      this.onEditDialogClose()
      this.onBindData(1)
    },

    async onDelete (row, rowIndex) {
      let msg = `您确定要删除第${(rowIndex + 1)}行的客户跟进记录？`
      let isok = await this.$confirm(msg, '删除跟进记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

      if (isok) {
        let data = await this.$post(Config.deleteURL, {FWID: row.FWID})
        if (data && data > 0) {
          this.$message({message: '删除成功', type: 'success'})
          this.onBindData(1)
        } else {
          this.$message({message: '删除失败', type: 'error'})
        }
      }
    }

  },

  async mounted () {
    await this.onInit()
  }
}
</script>

<style lang="scss">
</style>

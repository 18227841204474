<template>
  <!--批量更新跟进人-->
  <znl-dialog
    title="批量设置或清除跟进人"
    :visible="visible"
    height="200px"
    width="400px"
    @close="()=>{$emit('close')}"
  >
    <div slot="znl-dialog">
      <!--第一列 -->
      <el-row>
        <el-col :span="12">
          <el-radio class="radio" v-model="isWhereStr" :label="false">
            按已勾选行：
            <span style="color:red;">{{paramInfo.RowsCount}}</span>&nbsp;条
          </el-radio>
        </el-col>
        <el-col :span="12">
          <el-radio class="radio" v-model="isWhereStr" :label="true">
            按搜索条件：
            <span style="color:red;">{{paramInfo.WhereCount}}</span>&nbsp;条
          </el-radio>
        </el-col>
      </el-row>
      <el-row>
        <znl-input
          form-type="select"
          :multiple="true"
          title-width="80px"
          :border="true"
          placeholder="请选择"
          size="mini"
          layout="left"
          title="修改跟进人为："
          width="84%"
          :tags-clear="false"
          :clearable="true"
          @change="onChangeUser"
          :select-options="usableUser"
          v-model="BuyEmpID"
          type="text"
        ></znl-input>
        <el-row style="font-size: 13px;padding-left: 80px;">*不勾选跟进人,将会默认清空</el-row>
      </el-row>
    </div>
    <div slot="footer">
      <znl-button style-type="mac" @click="onCancel" :height="24">
        <i class="iconfont icon-close_btn"></i>
        <span>取消</span>
      </znl-button>
      <znl-button style-type="main" @click="onSubmit" :height="24">
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存</span>
      </znl-button>
    </div>
  </znl-dialog>
</template>
<script>

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
export default {
  name: 'CrmBatchSaveFollowUser',
  mixins: [getCommonDataMixin],
  components: {},
  data () {
    return {
      config: {
        setFollowUserURL: 'CrmCustomers/SetBatchFollowUser'
      },
      isWhereStr: false,
      CustomerModel: {
        IsWhere: false,
        TotalCount: 0,
        RowsCount: 0,
        FollowUserID: '',
        FollowUserName: ''
      },
      defaultCustomerModel: {
        IsWhere: false,
        TotalCount: 0,
        RowsCount: 0
      },
      usableUser: [],
      BuyEmpID: [],
      paramInfo: null
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean,
    title: String,
    dataModel: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  created () {
    this.onInit()
  },
  methods: {

    onInit () {
      this.usableUser = this.selectOptionsAllUserURL
      if (this.$store.state.paramOrder && this.$store.state.paramOrder.info) {
        let info = this.$store.state.paramOrder.info
        this.paramInfo = info
      }
    },

    onChangeUser (val) {
      this.BuyEmpID = val
    },

    onSubmit () {
      this.paramInfo.IsWhere = this.isWhereStr
      if (!this.paramInfo.IsWhere && this.paramInfo.RowsCount === 0) {
        return this.$message({
          message: '您还没勾选要操作的数据',
          type: 'error'
        })
      }
      if (this.paramInfo.IsWhere && this.paramInfo.WhereCount === 0) {
        return this.$message({
          message: '当前搜索条件的条数为0,请重新输入搜索条件',
          type: 'error'
        })
      }

      let buyEmpUser = []
      _.each(this.BuyEmpID, item => {
        let info = _.find(this.usableUser, info => {
          return info.key === item
        })
        if (this.hasValue(info) && this.hasValue(info.value)) {
          buyEmpUser.push(info.value)
        }
      })
      let userIDs = this.BuyEmpID.join(',')
      let userNames = buyEmpUser.join(',')
      this.paramInfo.SetFollowUserID = userIDs
      this.paramInfo.SetFollowUserName = userNames
      this.$post(this.config.setFollowUserURL, this.paramInfo, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '设置成功', type: 'success' })
          this.$emit('confirm', true)
        }
      })
    },
    onCancel () {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  padding: 5px;
}
</style>

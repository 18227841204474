<template>
  <znl-layout-spa
    :page-loading="pageLoading"
    znl-loading-text="页面加载中,请稍后..."
  >
    <el-tabs
      v-model="mianActiveName"
      value="VCustManage"
      height="100%"
      class="nav-common-ui tab-btnstyle-box grid-ordermange-box tab-manage-tab"
    >
      <el-tab-pane label="客户资料" name="custMange">
        <znl-layout-leftright left-width="190px">
          <crm-labels
            slot="znlLeft"
            ref="customerLabel"
            class="znlLeft"
            label-type="customer"
            @selected-label="onLabelSelect"
          ></crm-labels>
          <znl-layout-topbottom
            top-height="62%"
            slot="znlRight"
            class="znlRight"
          >
            <crm-customers
              ref="Customers"
              slot="znlTop"
              class="znlTop"
              :init-data="false"
              :searchModel="crmCustomersSearchModel"
              @page-loading="
                (d) => {
                  pageLoading_Cust = d;
                }
              "
              @selection-changed="customersSelectionChange"
              @label-change="onLabelChange"
            ></crm-customers>

            <el-tabs
              slot="znlBottom"
              class="znlBottom tab-btnstyle-box tab-details"
              v-model="activeName"
              @tab-click="pageSearch"
            >
              <!-- 联系人信息 -->
              <el-tab-pane :label="tabTitleContact" name="custContacts">
                <crm-contacts
                  ref="custContacts"
                  :init-data="false"
                  @page-loading="
                    (d) => {
                      pageLoading_Contacts = d;
                    }
                  "
                  @set-total-count="
                    (cnt) => {
                      tabData.contactCount = cnt;
                    }
                  "
                ></crm-contacts>
              </el-tab-pane>

              <!-- 需求记录 -->
              <el-tab-pane
                :label="tabTitleBOM"
                name="PastQuote"
                v-if="hasRight('CustomerRequire')"
              >
                <znl-hisofferpricebymodel
                  ref="bomLine"
                  :is-fields-search="true"
                  :init-data="false"
                  :searchModel="Hisofferprice"
                  parent-module="customer"
                  v-if="quoteTableInit"
                  @page-loading="
                    (d) => {
                      pageLoading_Contacts = d;
                    }
                  "
                  @set-total-count="
                    (cnt) => {
                      tabData.bomCount = cnt;
                    }
                  "
                ></znl-hisofferpricebymodel>
              </el-tab-pane>

              <!-- 销售出货记录 -->
              <el-tab-pane
                :label="tabTitleStkout"
                name="PastStkOut"
                v-if="hasRight('PastStkOut')"
              >
                <stk-out-details
                  ref="PastStkOut"
                  :is-operate="false"
                  :init-data="false"
                  :searchModel="stkOutSearchByModel"
                  v-if="stkOutTableInit"
                  @set-total-count="
                    (cnt) => {
                      tabData.stkoutCount = cnt;
                    }
                  "
                ></stk-out-details>
              </el-tab-pane>

              <!-- 跟进记录 -->
              <!-- v-if="hasRight('CrmCompanyFollowInfo')" -->
              <el-tab-pane :label="tabTitleFollow" name="custFollowInfo">
                <crm-company-follow-info
                  ref="custFollowInfo"
                  :init-data="false"
                  v-if="followInfoTableInit"
                  @set-total-count="
                    (cnt) => {
                      tabData.followHistoryCount = cnt;
                    }
                  "
                ></crm-company-follow-info>
              </el-tab-pane>
            </el-tabs>
          </znl-layout-topbottom>
        </znl-layout-leftright>
      </el-tab-pane>
    </el-tabs>
    <a class="old-edition" @click="oldEdition()" v-if="isErpClient"
      >切换到旧版</a
    >
  </znl-layout-spa>
</template>

<script>
import CrmCustomers from "@/components/modules/Crm/CrmCustomers";
import { switchToLegacyPage } from "~/lib/native";

import ZnlHisofferpricebymodel from "@c_modules/Ord/HisOfferPriceByModel";
import CrmContacts from "@/components/modules/Crm/CompanyContacts";
import CrmCompanyFollowInfo from "@/components/modules/Crm/CrmCompanyFollowInfo";
import StkOutDetails from "@c_modules/Stk/StkOutDetails";
import CrmLabels from "@/components/modules/Crm/Labels";
import { isClient } from "~/lib/runtime";

const CONFIG = {
  getContactCountUrl: "CrmCompanyContacts/GetCount",
  getBOMCountUrl: "CustomerRequire/GetCount",
  getStkoutCountUrl: "StkOutByModel/GetCount",
  getFollowHistoryCountUrl: "CrmCompanyFollowInfo/GetCount",
};

export default {
  name: "VCrmCustomers",
  components: {
    CrmCustomers,
    ZnlHisofferpricebymodel,
    CrmContacts,
    CrmCompanyFollowInfo,
    StkOutDetails,
    CrmLabels,
  },
  data() {
    return {
      crmCustomersSearchModel: {},
      CCompanyID: "",
      crmCompanyContactsSearchModel: {},
      pastBomModel: {},
      pageData: [],
      customerName: "",
      CustomerNameID: "",
      stkOutSearchByModel: {},
      Hisofferprice: {}, // 报价记录（按客户）
      CrmCustomers: {}, // 客户联系人
      activeName: "custContacts",
      searchModelCrmOld: { CCompanyID: "" },
      mianActiveName: "custMange",
      pageLoading_Cust: true,
      pageLoading_Contacts: true,
      quoteTableInit: false,
      stkOutTableInit: false,
      followInfoTableInit: false,

      tabData: {
        contactCount: 0, // 联系人总数
        bomCount: 0, // 需求记录总数
        stkoutCount: 0, // 出库记录总数
        followHistoryCount: 0, // 跟进记录总数
      },
      isErpClient: false,
    };
  },
  computed: {
    pageLoading() {
      return this.pageLoading_Cust && this.pageLoading_Contacts;
    },
    tabTitleContact() {
      return `客户联系人(${this.tabData.contactCount})`;
    },
    tabTitleBOM() {
      return `需求记录(${this.tabData.bomCount})`;
    },
    tabTitleStkout() {
      return `销售出货记录(${this.tabData.stkoutCount})`;
    },
    tabTitleFollow() {
      return `跟进记录(${this.tabData.followHistoryCount})`;
    },
  },
  methods: {
    // 选中标签后
    onLabelSelect(data) {
      this.$refs.Customers.onlabelClick(data.LID, data.labelText);
    },

    // 标签修改时.
    /**
     * @param
     * labels: json对象。 {addArray: [], removeArray:[]}
     */
    onLabelChange(labels) {
      if (labels) {
        this.$refs.customerLabel &&
          this.$refs.customerLabel.labelChange(labels);
      }
    },

    customersSearch() {
      this.$set(
        this.crmCustomersSearchModel,
        "CompanyName",
        this.Customers.CompanyName
      );
    },
    customersSelectionChange(item) {
      if (
        item &&
        item.CCompanyID !== undefined &&
        item.CCompanyID !== "" &&
        item.CCompanyID !== null
      ) {
        this.CCompanyID = item.CCompanyID;
        this.$set(
          this.crmCompanyContactsSearchModel,
          "CCompanyID",
          this.CCompanyID
        );
        this.pastBomModel = {
          CustomerName: item.CompanyName,
          CustomerNameID: this.CCompanyID,
        };
        this.pageSearch();
      }
      this.getTabDataCount();
    },

    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VCrmCustomers" });
    },

    hasRight(code) {
      return _.some(this.pageData, (page) => page.Code === code);
    },

    pageSearch() {
      if (this.activeName === "custContacts") {
        this.$nextTick(() => {
          this.pastBomModel.CustomerNameID &&
            this.$refs.custContacts.onHeadSearch(
              this.pastBomModel.CustomerNameID
            );
        });
      } else if (this.activeName === "PastQuote") {
        if (!this.quoteTableInit) {
          this.quoteTableInit = true;
        }
        this.Hisofferprice = {
          CustomerName: this.pastBomModel.CustomerName,
          CustomerGUID: this.pastBomModel.CustomerNameID,
        };
        this.$nextTick(() => {
          this.Hisofferprice.CustomerName &&
            this.Hisofferprice.CustomerGUID &&
            this.$refs.bomLine.onHeadSearch(this.Hisofferprice);
        });
      } else if (this.activeName === "PastStkOut") {
        if (!this.stkOutTableInit) {
          this.stkOutTableInit = true;
        }
        this.stkOutSearchByModel = {
          CustomerName: this.pastBomModel.CustomerName,
          CustomerGUID: this.pastBomModel.CustomerNameID,
          MatchOperation: "Equal",
        };
        this.$nextTick(() => {
          this.stkOutSearchByModel.CustomerName &&
            this.stkOutSearchByModel.CustomerGUID &&
            this.$refs.PastStkOut.onHeadSearch(this.stkOutSearchByModel);
        });
      } else if (this.activeName === "custFollowInfo") {
        if (!this.followInfoTableInit) {
          this.followInfoTableInit = true;
        }
        this.$nextTick(() => {
          this.pastBomModel.CustomerName &&
            this.$refs.custFollowInfo.onHeadSearch(
              this.pastBomModel.CustomerName,
              true
            );
        });
      }
    },

    async getTabDataCount() {
      switch (this.activeName) {
        case "custContacts": // 联系人
          this.getBOMCount();
          this.getStkoutCount();
          this.getFollowCount();
          break;
        case "PastQuote": // 报价记录
          this.getContactCount();
          this.getStkoutCount();
          this.getFollowCount();
          break;
        case "PastStkOut": // 出库记录
          this.getContactCount();
          this.getBOMCount();
          this.getFollowCount();
          break;
        case "custFollowInfo": // 跟进记录
          this.getContactCount();
          this.getBOMCount();
          this.getStkoutCount();
          break;
      }
    },

    async oldEdition() {
      await switchToLegacyPage("Customer");
    },

    // 联系人总数
    async getContactCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.contactCount = 0;
        return true;
      }
      let param = { CCompanyID: this.CCompanyID };
      this.$post(CONFIG.getContactCountUrl, param, (data) => {
        this.tabData.contactCount = data || 0;
      });
    },

    // 需求记录总数
    async getBOMCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.bomCount = 0;
        return true;
      }
      let fieldWhereString = JSON.stringify({
        // CustomerGUID: this.pastBomModel.CustomerNameID,
        CustomerName: this.pastBomModel.CustomerName,
      });
      let param = { FieldWhereString: fieldWhereString };
      this.$post(CONFIG.getBOMCountUrl, param, (data) => {
        this.tabData.bomCount = data || 0;
      });
    },

    // 销售出货总数
    async getStkoutCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.stkoutCount = 0;
        return true;
      }
      let param = {
        CustomerGUID: this.pastBomModel.CustomerNameID,
        CustomerName: this.pastBomModel.CustomerName,
        MatchOperation: "Equal",
      };
      this.$post(CONFIG.getStkoutCountUrl, param, (data) => {
        this.tabData.stkoutCount = data || 0;
      });
    },

    // 跟进记录总数
    async getFollowCount() {
      if (!this.hasValue(this.CCompanyID)) {
        this.tabData.followHistoryCount = 0;
        return true;
      }
      let param = {
        CCompanyName: this.pastBomModel.CustomerName,
        CustomerExact: true,
      };
      this.$post(CONFIG.getFollowHistoryCountUrl, param, (data) => {
        this.tabData.followHistoryCount = data || 0;
      });
    },
  },
  created() {
    this.isErpClient = isClient();
    this.getPageData()
      .then((data) => {
        this.pageData = data;
      })
      .catch((e) => {});
  },
};
</script>

<style lang="scss">
</style>

<template>
  <!-- 销售发货记录 -->
  <div class="stk-out-record-box" :style="{ height: height }">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      box-class="stk-out-record-box"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StkOutByModel')"
      checkbox-binding-key="StkOutLineGUID"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
      @select-change="selectChange"
      :showCheck="this.isOperate"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      @on-refresh-click="onSearch"
      @dbclick="
        (e) => {
          $emit('dbl-click', e.rowData);
        }
      "
    >
      <div slot="header" class="v-table-toolbar" v-if="this.isOperate">
        <znl-input
          form-type="input"
          placeholder="出库型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchModel.Model"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="客户名称"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchModel.CustomerName"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="出库单号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchModel.BillNo"
          :border="true"
          type="text"
        ></znl-input>

        <znl-button
          type="multiple"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="74"
          @click="onStkOutadd()"
          v-if="hasRes('OnStkOut')"
        >
          <i class="iconfont icon-sell-wh_btn_ic"></i>
          <span>销售出库</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="74"
          @click="onStkInadd()"
          v-if="hasRes('onStkIn')"
        >
          <i class="iconfont icon-buy-wh_btn_ic"></i>
          <span>采购入库</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="50"
          @click="onStkInquiry()"
          v-if="true"
        >
          <i class="iconfont icon-edit_btn_ic"></i>
          <span>询价</span>
        </znl-button>
        <a
          style="
            text-decoration: underline;
            margin-left: 3px;
            margin-right: 3px;
          "
          title="点击查看"
          @click="onCheckInfo()"
          >已选择 ({{ selectedRows.length }}) 条</a
        >

        <a
          @click="onCleaSelectCount()"
          style="text-decoration: underline; margin-left: 5px"
          title="清除选中条数"
          >清除</a
        >
      </div>
    </znl-table>
    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      edit-type="edit"
      height="500px"
      @confirm="stkOutEditVisible = false"
      @close="stkOutEditVisible = false"
    ></stk-out-edit>
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      edit-type="edit"
      height="500px"
      @confirm="stkInEditVisible = false"
      @close="stkInEditVisible = false"
    ></stk-in-edit>
    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>
  </div>
</template>
<script>
import EnquiryView from "@c_modules/Enquiry/index";
import { isClient } from "~/lib/runtime";

const StkInEdit = () => import("@c_modules/Stk/StkInEdit");
const StkOutEdit = () => import("@c_modules/Stk/StkOutEdit");
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";
import data from "../../common/print/data";

const CONFIG = {
  PK: "StkOutLineID",
  configURL: "StkOutByModel/GetConfig",
  searchURL: "StkOutByModel/Search",
  getViewPartnerResourceURL: "UserResource/GetCurrentUserResourceByCode",
};

export default {
  name: "CStkOutByModel",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit,
    EnquiryView,
  },
  data() {
    return {
      stkOutEditVisible: false,
      stkInEditVisible: false,
      Status: true,
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      selectedRows: [], // 选中的数据
      stkInquiry: {
        // 询价参数传递
        SupplierName: "", // 供应商名称
        PartNo: "", // Model
        Brand: "",
        Packaging: "",
        MakeYear: "",
        Quality: "",
        DeliveryDate: "",
        InvQty: 0,
        Qty: 0,
        MPQ: "",
        Price: 0,
      },
      isShowFiedsSearch: false,
      ParamJosn: [],
      currencyList: [],
      fieldsFilter: {},
      resourceList: [],
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isOperate: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: () => {
        return "100%";
      },
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function,
    },
  },
  computed: {
    boxClass() {
      if (this.height === "100%") {
        return "module-wrapper";
      }
      return "stk-out-record-box";
    },
  },
  async created() {
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage("StkOutByModel", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList,
        });
      }

      if (this.isOperate) {
        let tempConfig = [];
        if (this.hasRes("OnStkOut")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "出",
            btnTxt: "出",
            visible: true,
            isDeal: false,
            click: (row) => {
              let rows = [];
              rows.push(row);
              this.onStkOutadd(rows);
            },
          });
        }
        if (this.hasRes("onStkIn")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "入",
            btnTxt: "入",
            visible: true,
            isDeal: false,
            click: (row) => {
              this.onStkInadd(row);
            },
          });
        }
        columns = tempConfig.concat(columns);
      }

      //获取看合伙人权限
      await this.onGetViewPartnerResource();

      if (!this.IsMainAccount) {
        this.settingColumns(columns);
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration();

      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      // this.itemSource = []
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },

    // 列的初始化
    settingColumns(columns) {
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");
      let noSeeCustomer = this.getSpecialResourceByCode("NoSeeCustomer");
      let hideCols = ",CustomerName,Contact,Telephone,Email,Address,QQ,Mobile,";
      _.remove(columns, (item) => {
        if (noSeeCustomer && hideCols.indexOf(item.BindField) > 0) {
          return true;
        }
        if (noSeeSupplier && item.BindField === "SupplierInfo") {
          return true;
        }
        //看合伙人权限
        if (!this.hasRes("ViewPartner") && item.BindField === "Partner") {
          return true;
        }
      });
    },
    getSearchWhere(pageIndex) {
      let filterLast = _.extend({}, this.fieldsFilter);
      filterLast.BillNo = this.searchModel.BillNo;
      const { Keywords, KeywordsField } = this.searchModel;
      if (Keywords && KeywordsField) {
        filterLast[KeywordsField] = Keywords;
        delete filterLast.Keywords;
        delete filterLast.KeywordsField;
        delete filterLast.Model;
      }
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          InputModel: this.searchModel.Model || "",
          CustomerName: this.searchModel.CustomerName,
          CustomerGUID: this.searchModel.CustomerGUID,
          MatchOperation: this.searchModel.MatchOperation,
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          IsAnalyzer: false,
        }
      );
      return data;
    },
    async onBindData(pageIndex = 1) {
      let param = this.getSearchWhere(pageIndex);
      this.loading = true;
      let { totalCount, itemSource } = await this.$post(
        CONFIG.searchURL,
        param,
        (data) => {
          this.$emit("page-loading", false);
          this.$emit("set-total-count", data.TotalCount);
          return {
            pageIndex,
            totalCount: data.TotalCount,
            itemSource: data.ResultList,
          };
        }
      );
      this.itemSource = itemSource;
      this.pageIndex = pageIndex;
      this.totalCount = totalCount;
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },
    //获取当前用户合伙人权限
    async onGetViewPartnerResource() {
      await this.$post(
        CONFIG.getViewPartnerResourceURL,
        { Code: "ViewPartner" },
        (data) => {
          if (data) {
            let mapData = data.map((item) => item.Code);
            this.resourceList = this.resourceList.concat(mapData);
          }
        }
      );
    },
    async onHeadSearch(param = {}) {
      this.fieldsFilter.MakeYear = param.MakeYear || "";
      this.fieldsFilter.Brand = param.Brand || "";
      this.fieldsFilter.Packaging = param.Packaging || "";
      this.fieldsFilter.Model = param.Model;
      this.fieldsFilter.CustomerName = param.CustomerName;
      return this.onBindData(1);
    },
    async onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      await this.onBindData(1);
    },
    // 勾选选择框事件
    selectChange(rows) {
      _.each(rows, (a) => {
        a.pageIndex = this.pageIndex;
      });
      if (this.selectedRows.length <= 0) this.selectedRows = rows;
      else {
        //过滤当前页数据
        this.selectedRows = _.filter(this.selectedRows, (a) => {
          return a.pageIndex != this.pageIndex;
        });
        //添加当前页数据
        this.selectedRows = this.selectedRows.concat(rows);
        //从小到大 排序
        this.selectedRows.sort(function (a, b) {
          return a.pageIndex - b.pageIndex;
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },

    hasRes(code) {
      return _.some(this.resourceList, (page) => page === code);
    },

    onStkOutadd(rows) {
      if (!rows) {
        rows = this.selectedRows;
      }
      // 销售出库传值方法
      let order = { stockLine: rows };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function () {
        this.stkOutEditVisible = true; // 显示编辑页面
      });
    },

    onStkInadd(row) {
      var rows = [];
      if (row) {
        rows.push(row);
      } else {
        rows = this.selectedRows;
      }
      // 入库
      let order = { StkInRecord: rows };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function () {
        this.stkInEditVisible = true; // 显示编辑页面
      });
    },
    async onStkInquiry() {
      let rows = this.selectedRows;
      // 询价
      let data = "";
      if (rows.length > 0) {
        data = rows[0];
      }
      if (isClient()) {
        await this.onInquiry(data);
      } else {
        const mapData = [
          "Model",
          "Brand",
          "Packaging",
          "MakeYear",
          "Quality",
          "InvQty",
          "Qty",
        ];
        let editItem = {};
        if (data) {
          mapData.forEach((key) => (editItem[key] = data[key]));
        }
        this.$refs.enquiryView.onOpen(editItem);
      }
    },
    onConfirm(data) {
      console.log(data);
    },

    onInquiry: async function (data) {
      // 询价
      //this.stkInquiry.SupplierName = data.SupplierName
      this.stkInquiry.Model = data.Model;
      this.stkInquiry.Brand = data.Brand;
      this.stkInquiry.Packaging = data.Packaging;
      this.stkInquiry.MakeYear = data.MakeYear;
      //this.stkInquiry.Quality = data.Quality
      this.stkInquiry.InvQty = data.Qty;
      //this.stkInquiry.MPQ = data.MPQ
      this.stkInquiry.Qty = data.Qty;
      this.addInquire(this.stkInquiry, function (msg) {
        if (msg === "不能向自己询价") {
          this.$message({ message: msg, type: "warning" });
        }
      });
      this.onCleaSelectCount();
    }, // 获取参数配置
    paramConfiguration() {},
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkOutByModel", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkOutByModel", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkOutByModel", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkOutByModel");
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "app/assets/styles/_variables.scss";
</style>


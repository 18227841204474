<template>
    
  <div>
    <CrmTerminalCustomerListing v-if='hasRight("CrmTerminalCustomerListing")'
                                :searchModel="crmTerminalCustomerListingSearchModel"
                                @selection-changed="customersSelectionChange" height="97%"></CrmTerminalCustomerListing>
  </div>

    
</template>

<script>
import { Button, Input } from 'element-ui'
import CrmTerminalCustomerListing from '@/components/modules/Crm/CrmTerminalCustomerListing'
import Icon from 'vue-awesome/components/Icon.vue'

export default {
  name: 'VCrmTerminalCustomerListing',
  components: {
    elButton: Button,
    elInput: Input,
    icon: Icon,
    CrmTerminalCustomerListing
  },
  data () {
    return {
      crmTerminalCustomerListingSearchModel: {},
      CCompanyID: '',
      crmCompanyContactsSearchModel: {},
      crmCompanyFollowInfoSearchModel: {},
      crmCompanyFollowInfoCustomerModel: {},
      crmBomSearchModel: {},
      crmBomCustomerModel: {},
      stkOutSearchModel: {},
      stkOutCustomerModel: {},
      pageData: []
    }
  },
  methods: {
    customersSelectionChange (item) {

    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VCrmTerminalCustomerListing'})
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
     // console.log(data)
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

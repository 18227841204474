<template>
  <div>
    <znl-edit-grid-dlg type="edit" v-model="p_dialogVisible"
                     ref="flexGrid"
                     title="添加到我的客户"
                     :height="height"
                     :columns="columns"
                     :item-source="PubCompanyModel.Contacts"
                     :hasDefaultBeforeNewRow="true"
                     :edit-row-index="editRowIndex"
                     :column-colors="columnColors"
                     :row-colors="rowColors"
                     :summary-columns="summaryColumns"
                     :is-fields-search="false"
                     :is-multi-rows-check="true"
                     :page-size="pageSize"
                     :page-index="pageIndex"
                     :total-count="totalCount"
                     :on-init="onInit"
                     @selection-changed="d=>{$emit('selection-changed',d)}">
      <template slot="action-form">
      <el-row type="flex" :gutter="20" align="center">
        <el-col :xs="8" :sm="8">
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">公司名称：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.CompanyName"
                          :class="{'required':!isEffective(PubCompanyModel.CompanyName)}">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">公司简称：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.ShortName">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">公司编码：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.CompanyCode">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">所属行业：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Industry">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">主营产品：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Products">
                </el-input>
              </el-col>
            </el-row>
        </el-col>
        <el-col :xs="8" :sm="8">
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">法人代表：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.LegalPerson">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">营业执照编号：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.BLNo">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">税务登记号：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.TRCNo">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">邮政编码：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.ZipCode">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">手机：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Mobile">
                </el-input>
              </el-col>
            </el-row>
        </el-col>
        <el-col :xs="8" :sm="8">
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">电话：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Telephone">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">地址：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Address">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">邮箱：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Email">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">传真：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Fax">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">网址：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.WebSite">
                </el-input>
              </el-col>
            </el-row>
        </el-col>
        <el-col :xs="8" :sm="8">
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">QQ号：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.QQ">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">联系人：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Contact">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">备注：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.Remark">
                </el-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100">
              <el-col class="form_subtitle">简介：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="PubCompanyModel.BriefIntroduction">
                </el-input>
              </el-col>
            </el-row>
        </el-col>
      </el-row>
      </template>
    </znl-edit-grid-dlg>
    <el-row
          class="dialog-footer">
      <el-col style="display: flex;justify-content: flex-end;align-items: center">

        <el-button type="primary-rev"
                  :disabled="loading"
                  size="mini"
                  @click="onCancel"
                    >取消</el-button>
        <el-button type="primary"
                  size="mini"
                  :loading="loading"
                  @click="onSubmit">{{loading?'保存中':'确定'}}</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'CrmCopyCustomerEditDialog',
  mixins: [propSyncMixin],
  components: {
  },
  data () {
    return {
      areasize: { minRows: 3, maxRows: 3 },
      config: {
        PK: 'CompanyID',
        copyCustomerURL: 'CrmCopyCustomerEditDialog/CopyCustomer',
        searchContactsURL: 'CrmCopyCustomerEditDialog/GetContacts'
      },
      columns: [{ binding: 'Contact', name: 'Contact', header: '联系人', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'QQ', name: 'QQ', header: 'QQ', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Telephone', name: 'Telephone', header: '电话', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Birthday', name: 'Birthday', header: '出生日期', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Mobile', name: 'Mobile', header: '手机', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'WW', name: 'WW', header: '阿里旺旺', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Email', name: 'Email', header: '邮箱', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'DataSource', name: 'DataSource', header: '数据来源', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Gender', name: 'Gender', header: '性别', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'SkyNo', name: 'SkyNo', header: 'Sky号', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Remark', name: 'Remark', header: '备注', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Position', name: 'Position', header: '职务', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Birthplace', name: 'Birthplace', header: '籍贯', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 }
      ],
      columnColors: [],
      rowColors: [],
      pageSize: 1000,
      pageIndex: 1,
      totalCount: 0,
      PubCompanyModel: {
        CompanyID: '',
        TrustyRate: 0,
        HitCount: 0,
        CompanyName: '',
        ShortName: '',
        CompanyCode: '',
        Products: '',
        Industry: '',
        Telephone: '',
        Mobile: '',
        Fax: '',
        Email: '',
        ZipCode: '',
        WebSite: '',
        QQ: '',
        Address: '',
        Contact: '',
        BLNo: '',
        BLPath: '',
        TRCNo: '',
        TRCPath: '',
        LegalPerson: '',
        Remark: '',
        BriefIntroduction: '',
        DataFrom: '',
        CreatedTime: '',
        IsSupplier: false,
        IsCustomer: false,
        OrderNumber: '',
        Contacts: []
      },
      defaultPubCompanyModel: {
        CompanyID: '',
        TrustyRate: 0,
        HitCount: 0,
        CompanyName: '',
        ShortName: '',
        CompanyCode: '',
        Products: '',
        Industry: '',
        Telephone: '',
        Mobile: '',
        Fax: '',
        Email: '',
        ZipCode: '',
        WebSite: '',
        QQ: '',
        Address: '',
        Contact: '',
        BLNo: '',
        BLPath: '',
        TRCNo: '',
        TRCPath: '',
        LegalPerson: '',
        Remark: '',
        BriefIntroduction: '',
        DataFrom: '',
        CreatedTime: '',
        IsSupplier: false,
        IsCustomer: false,
        OrderNumber: '',
        Contacts: []
      },
      columnConfig: [{ ID: 1, property: 'Contact', width: 80, label: '联系人' },
      { ID: 2, property: 'QQ', width: 130, label: 'QQ' },
      { ID: 3, property: 'Telephone', width: 175, label: '电话' },
      { ID: 4, property: 'Birthday', width: 120, label: '出生日期' },
      { ID: 5, property: 'Mobile', width: 160, label: '手机' },
      { ID: 6, property: 'WW', width: 160, label: '阿里旺旺' },
      { ID: 7, property: 'Email', width: 290, label: '邮箱' },
      { ID: 8, property: 'DataSource', width: 110, label: '数据来源' },
      { ID: 9, property: 'Gender', width: 65, label: '性别' },
      { ID: 10, property: 'SkyNo', width: 160, label: 'Sky号' },
      { ID: 11, property: 'Remark', width: 80, label: '备注' },
      { ID: 12, property: 'Position', width: 140, label: '职务' },
      { ID: 13, property: 'Birthplace', width: 140, label: '籍贯' }],
      FollowTypes: ['电话', 'QQ', 'Email', '上门拜访'],
      usableUser: [],
      contacts: [],
      editRowIndex: -1,
      summaryColumns: []
    }
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    dataModel: {
      type: Object,
      default: () => {
        return {
          CompanyID: '',
          TrustyRate: 0,
          HitCount: 0,
          CompanyName: '',
          ShortName: '',
          CompanyCode: '',
          Products: '',
          Industry: '',
          Telephone: '',
          Mobile: '',
          Fax: '',
          Email: '',
          ZipCode: '',
          WebSite: '',
          QQ: '',
          Address: '',
          Contact: '',
          BLNo: '',
          BLPath: '',
          TRCNo: '',
          TRCPath: '',
          LegalPerson: '',
          Remark: '',
          BriefIntroduction: '',
          DataFrom: '',
          CreatedTime: '',
          IsSupplier: false,
          IsCustomer: false,
          OrderNumber: '',
          Contacts: []
        }
      }
    }
  },
  watch: {
    dataModel (item) {
      _.extend(this.PubCompanyModel, this.defaultPubCompanyModel, item)
      // let vm = this
      // vm.$post(vm.config.companyUserURL, {}, (companyUserData) => {
      //   vm.usableUser = []
      //   _.each(companyUserData.Users, function (user) {
      //     vm.usableUser.push(_.clone(user))
      //   })
      // })
      // vm.$post(vm.config.companyContactURL, this.PubCompanyModel, (contacts) => {
      //   vm.contacts = []
      //   _.each(contacts, function (contacter) {
      //     vm.contacts.push(_.clone(contacter))
      //   })
      //   if (vm.contacts.length > 0) {
      //     let defaultContact = _.find(vm.contacts, function (item, index) { return item.Telephone.length > 0 || item.Mobile.length > 0 })
      //     if (_.isUndefined(defaultContact)) {
      //       defaultContact = vm.contacts[0]
      //     }
      //     this.PubCompanyModel.Contact = defaultContact.ContactName
      //     this.PubCompanyModel.TelPhone = this.isNull(defaultContact.Telephone) ? defaultContact.Mobile : defaultContact.Telephone
      //   }
      // })
      this.$post(this.config.searchContactsURL, [this.PubCompanyModel.CompanyID], (data) => {
        this.PubCompanyModel.Contacts = data
      })
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    onInit: async function () {
      this.columns = [{ binding: 'Contact', name: 'Contact', header: '联系人', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'QQ', name: 'QQ', header: 'QQ', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 130 },
      { binding: 'Telephone', name: 'Telephone', header: '电话', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 175 },
      { binding: 'Birthday', name: 'Birthday', header: '出生日期', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 120 },
      { binding: 'Mobile', name: 'Mobile', header: '手机', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 160 },
      { binding: 'WW', name: 'WW', header: '阿里旺旺', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 160 },
      { binding: 'Email', name: 'Email', header: '邮箱', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 290 },
      { binding: 'DataSource', name: 'DataSource', header: '数据来源', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 110 },
      { binding: 'Gender', name: 'Gender', header: '性别', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 65 },
      { binding: 'SkyNo', name: 'SkyNo', header: 'Sky号', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 160 },
      { binding: 'Remark', name: 'Remark', header: '备注', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 80 },
      { binding: 'Position', name: 'Position', header: '职务', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 140 },
      { binding: 'Birthplace', name: 'Birthplace', header: '籍贯', authUsers: [], dataType: 1, isTag: false, isSystem: false, editable: false, isReadOnly: false, isRequired: false, extraConfig: {}, isFieldSearch: false, sort: 1, sortMemberPath: 'sort', visible: true, width: 140 }]
    },
    initData () {
    },
    isNull (val) {
      if (val === null || val === undefined || val === '') {
        return true
      } else {
        return false
      }
    },
    onSubmit () {
      let vm = this
      vm.$post(vm.config.copyCustomerURL, [vm.PubCompanyModel], (data) => {
        vm.p_dialogVisible = false
        vm.$message({ message: '保存成功', type: 'success' })
        vm.$emit('confirm', true)
        // vm.$nextTick(function () {
        //   this.$emit('confirm', data)
        // })
      })
    },
    isEffective (val) {
      return !_.isNull(val) && !_.isUndefined(val) && val.length > 0
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('confirm', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/variables';
.el-dialog {
  min-width: 1000px;
  background-color: darken($white, 5%);
}
.taglabel{
  text-align:right;
}
</style>

